import { Injectable } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';
import { ReputationAPI, ReputationItem } from '@imunify360-api/reputation';
import { AgentResponse, ListResponseData } from '@imunify360-api/misc';
import { Observable, tap } from 'rxjs';
import { RequestsState } from 'app/services/requests-state';


@Injectable()
export class ReputationService {
    _infectedDomains = this.xhr.rx(ReputationAPI.infectedDomains);
    infectedDomains = this.xhr.wrap(this._infectedDomains, ReputationItem);
    infectedDomainsCount: number;

    constructor(
        public xhr: XHR,
        private requestsState: RequestsState,
    ) {}

    load = (params = {}): Observable<AgentResponse<ListResponseData<ReputationItem>>> => {
        return this.infectedDomains(params).pipe(
            tap(result => result.data.items.sort((a, b) => a.username < b.username ? -1 : 1)),
            tap(res => this.requestsState.infectedDomainsCount.push.next(res.data.max_count || 0)),
        );
    }
}
