import { Injectable } from '@angular/core';

declare const I360_PANEL: any;
declare const i360lang: any;

export const LANGUAGE_KEY = 'lang';
export const PANEL_PLESK = 'Plesk';
export const PANEL_DA = 'DirectAdmin';
export const PANEL_CPANEL = 'cPanel';
export const PANEL_NOPANEL = 'noPanel';


const linksToLve = {
    cPanel: window.location.href.substring(
        window.location.href.search('/cpsess'),
        window.location.href.search('cgi')) + `cgi/CloudLinux.cgi`,
    Plesk: '/modules/plesk-lvemanager',
    // iframe=no needed to prevent double nested iframe
    DirectAdmin: `/CMD_PLUGINS_ADMIN/lvemanager_spa?iframe=no`,
};

@Injectable()
export class Panel {
    name: string;
    isPlesk: boolean;
    isCpanel: boolean;
    isDirectAdmin: boolean;
    isNoPanel: boolean;
    defaultScanFolder: string;
    csfInterfaceUrl: string;
    linkToLvePhpSelector: string;
    private readonly defaultScanFolders = {
        cPanel: '/home*/*',
        Plesk: '/var/www/vhosts/*',
        DirectAdmin: '/home*/*/',
        noPanel: '/home',
    };
    private readonly csfInterfaces = {
        cPanel: '../../../cgi/configserver/csf.cgi',
        Plesk: null,
        DirectAdmin: null,
        noPanel: null,
    };

    constructor() {
        this.name = I360_PANEL;
        this.isPlesk = this.name === PANEL_PLESK;
        this.isCpanel = this.name === PANEL_CPANEL;
        this.isDirectAdmin = this.name === PANEL_DA;
        this.isNoPanel = this.name === PANEL_NOPANEL;
        this.defaultScanFolder = this.defaultScanFolders[this.name];
        this.csfInterfaceUrl = this.csfInterfaces[this.name];
        this.linkToLvePhpSelector = `${linksToLve[this.name]}#/selector`;
    }

    getLang(document: Document): string {
        switch (I360_PANEL) {
            case PANEL_PLESK:
            case PANEL_CPANEL:
                return document.querySelector('html')!.lang;
            case PANEL_DA:
                return i360lang;
            case PANEL_NOPANEL:
                return localStorage.getItem(LANGUAGE_KEY) || '';
            default:
                return '';
        }
    }
}
