import { DEFAULT_DATA } from './misc';
import { AccountStatus } from '../emails-settings';
import {
    EmailActivityAccount,
    EmailActivityAccountDomain,
    EmailActivityAccountDomainEmail,
    EmailActivityAccountDomainScript
} from '../emails-activity-monitor';

export const activityMonitorEmail = (i) => ({
    id: 'email_620527c04a84ecd9ac78f622' + i,
    name: i + '_some@email.test',
    messages: i + 100,
    quarantined: i + 200,
    limit: 0+i,
    exclusion: i % 3 === 0,
    rateLimited: i % 3 === 0,
    whitelisted: i % 3 === 0,
}) as EmailActivityAccountDomainEmail;

export const activityMonitorScript = (i) => ({
    id: 'script_620527c04a84ecd9ac78f622' + i,
    name: 'script_name_' + i,
    messages: i + 100,
    quarantined: i + 200,
    limit: i * 2000,
    exclusion: i % 3 === 0,
    rateLimited: i % 3 === 0,
    whitelisted: i % 3 === 0,
}) as EmailActivityAccountDomainScript;

export const activityMonitorDomain = (i) => ({
    id: 'domain_3fa85f64-5717-4562-b3fc-2c963f66afa6 ' + i,
    name: 'domain_' + i,
    limit: 1000 * i + i,
    exclusion: i % 3 === 0,
    whitelisted: i % 3 === 0,
    rateLimited: i % 3 === 0,
    messages: i + 100,
    quarantined: i + 200,
    sender_emails: [...Array(...Array(5)).map((_, idx) => activityMonitorEmail(idx))],
}) as EmailActivityAccountDomain;

export const activityMonitorAccount = (i) => ({
    id: 'account_3fa85f64-5717-4562-b3fc-2c963f66afa6 ' + i,
    name: 'account_name_' + i,
    messages: i + 100,
    quarantined: i + 200,    limit: 5000 * i + i,
    exclusion: i % 5 === 0,
    rateLimited: i % 5 === 0,
    whitelisted: i % 5 === 0,
    domains: [...Array(...Array(5)).map((_, idx) => activityMonitorDomain(idx))],
    scripts: [...Array(...Array(5)).map((_, idx) => activityMonitorScript(idx))]
}) as EmailActivityAccount;

const activityMonitorItems = [...Array(...Array(35)).map((_, i) => activityMonitorAccount(i))];

const accountItem = {
    name: 'account ',
    limit_bytes: 15,
    releases_limit: 15,
    used_bytes: 5,
    state: AccountStatus.active,
    description: 'deleted 1300 messages since 10 Aug',
};

const emailItems = [
     ...Array(...Array(15)).map((_, i) => ({
        email_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6 ' + i,
        account_name: 'account ' + i,
        sender: `Subject 703032ae41831eeeb01a1e2e3c56278d178f14af56bc4d020222d0d39c2c6c77`,
        recipients: ['rcp@gmail.com', 'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com'],
        subject: 'Subject 703032ae41831eeeb01a1e2e3c56278d178f14af56bc4d020222d0d39c2c6c77',
        body: 'Subject 703032ae41831eeeb01a1e2e3c56278d178f14af56bc4d020222d0d39c2c6c77',
        reasons: ["email reasons1", "email reasons2", "email reasons3"],
        received_date: 1637136742,
        script_header: {
            raw: "X-PHP-whateverheader",
            domain: "test-domain.null",
            path: "/root/email.php"
        }
    })),
];


const usedBytes = [
    0.2,
    1.234,
    2.30003,
];

const accountsItems = [
     ...Array(...Array(15)).map((_, i) => ({
        name: 'account ' + i,
        releases_limit: (i > (usedBytes.length - 1) ? i : usedBytes[i]) * 1_048_576,
        limit_bytes: (i > (usedBytes.length - 1) ? i : usedBytes[i]) * 1_048_576,
        used_bytes: (i > (usedBytes.length - 1) ? i : usedBytes[i]) * 1_048_576,
        state: AccountStatus.active,
        description: 'deleted 1300 messages since 10 Aug',
    })),
];

const accountsLists = [
     ...Array(...Array(15)).map((_, i) => (
         'Account ' + i
     )),
];

export const responses = {
    activityMonitor: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: activityMonitorItems,
        }
    },
    emails: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: emailItems,
        },
    },
    releaseWarning: {
        result: 'error',
        data: {
            ...DEFAULT_DATA,
            items: '',
        },
        messages: [
            'Something went wrong please try again'
        ],
        status: 429,
    },
    honestResultWarning: {
        result: 'error',
        data: {
            ...DEFAULT_DATA,
            items: {
                successItemCount: 1,
                failItemCount: 2,
                total: 3
            }

        },
        status: 207,
    },
    version: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: {
                version: "1.1",
                components: [
                    {
                        release: "Whatever that will",
                        name: "original name, e.g. imunifyemail",
                        version: "0.5-0.el8.noarch"
                    },
                    {
                        release: "Whatever that will",
                        name: "original name, e.g. imunifyemail-rspamd",
                        version: "3.1-5.el8.1642765025.x86_64"
                    },
                    {
                        release: "Whatever that will",
                        name: "original name, e.g. imunifyemail-rspamd",
                        version: "3.1-5.el8.1642765025.x86_64"
                    },
                ]
            },
        },
    },
    accounts: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: accountsItems,
        },
    },
    accountList: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: accountsLists,
        },
    },
    account: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: accountItem,
        },
    },
    spaces: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: {
                total_space: 22,
                account_space: 833,
            },
        },
    },
    defaultLimits: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: {
                limit_mode: 1,
                account: 50000,
                domain: 25000,
                sender_email: 5,
                script: 500
            },
        },
    },
    accountSettingDefaults: {
        data: {
            ...DEFAULT_DATA,
            items: {
                settings: [
                    {
                       setting: "limit_bytes",
                       int_value: 1000000024
                    },
                    {
                       setting: "releases_limit",
                       int_value: 10
                    }
                ]
            },
        },
    },
    null: {
        data: {
            ...DEFAULT_DATA,
            items: null,
        },
    },
    details: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: {
                email_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                account_name: 'account1',
                reasons: ['account1'],
                domain: 'domain.com',
                sender: 'from@gmail.com',
                recipients: ['rcp@gmail.com',
                    'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com',
                    'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com',
                    'test1@gmail.com', 'test2@gmail.com', 'test1@gmail.com', 'test2@gmail.com'],
                subject: 'Hey, Long time no see',
                body: 'PGh0bWw+CjxoZWFkPgo8dGl0bGU+Q2hyaXN0aWFuIEhlYWx0aCBQbGFuIGNoYXJzZXQ9aXNvLTg4NTktMSI+L2JvZHk+CjwvaHRtbD4KCgoK',
                header: { header: 'test'},
                received_date: 1637136742,
                size_bytes: 1637136742,
                raw: 'RGVsaXZlcmVkLVRvOiBhc2VtZW5pa2hpbkBjbG91ZGxpbnV4LmNvbQpSZWNlaXZlZDogYnkgMjAwMjphNmI6N2Q0YzowOjA6MDowOjAgd2l0aCBTTVRQIGlkIHAyOTU4OTBpb3E7CiAgICAgICAgV2VkLCAxMCBOb3YgMjAyMTA'

            },
        },
    },
};
