<div mat-dialog-title>
    <div class="title-text">
        <ng-content select="[modal-title]"></ng-content>
        <div class="i360-subtitle">
            <ng-content select="[modal-subtitle]"></ng-content>
        </div>
    </div>
    <button *ngIf="showClose"
            mat-icon-button
            mat-dialog-close>
    <i class="material-icons">close</i>
    </button>
</div>
<div mat-dialog-content>
    <ng-content select="[modal-body]"></ng-content>
</div>
<div mat-dialog-actions>
    <ng-content select="[modal-actions]"></ng-content>
    <button class="cancel-button"
            [class.uppercase]="uppercaseButtons"
            mat-button
            *ngIf="showCancel"
            mat-dialog-close
            color="primary">
        {{ cancelText | translate }}
    </button>
    <button class="confirm-button"
            *ngIf="showConfirm"
            color="primary"
            type="button"
            (click)="close()"
            mat-button
            cdkFocusInitial
            [class.uppercase]="uppercaseButtons"
            [disabled]="confirmDisabled || confirmDisabledAlways">
        <span [matTooltip]="confirmNote">{{ confirmText | translate }}</span>
    </button>
</div>
