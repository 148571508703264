import { Injectable, Injector } from '@angular/core';
import { zoned } from 'app/utils/rxjs-zoned';
import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { LicenseState } from 'app/services/license-state';
import {
    BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, merge, identity,
    distinctUntilChanged, filter, map, switchMap, take,
} from 'rxjs';
import { AgentStatus } from '@imunify360-api/misc';
import { ReputationService } from './reputation';
import { SettingsService } from './settings';
import { cache2 } from 'app/utils/cache';
import { AdminConfigType } from 'app/utils/config/admin-config';
import { I360Conflicts } from '@imunify360-api/settings';
import { BoolFeature, ClientFeatures, ReportableFeature } from '@imunify360-api/features-management';
import { UserFeaturesStatusService } from './user-features-status';
import { I360Role } from './auth';

class CachedRequest<T> {
    // Use this Subject to forcefully put a new value into the cache.
    // Emitting it will:
    // * Set the cached value to emitted value
    // * Notify all subscribers
    // * Restart `ttl` timer
    push: Subject<T> = new Subject<T>();
    // Emitting it will force re-subscribe on the source. (i.e. it will trigger the request)
    refresh: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    // Emitting it will drop the current cached value.
    // Next subscription to cache will trigger a subscription on the source. (i.e. a request)
    reset: Subject<any> = new Subject<any>();
    // The actual cached observable
    cache: Observable<T>;
    constructor({ttl, request, push, refresh, reset}: {
        ttl: number,
        request: Observable<T>,
        push?: Observable<T>,
        refresh?: Observable<any>,
        reset?: Observable<any>,
    }) {
        push = push ? merge(this.push, push) : this.push;
        refresh = refresh ? merge(this.refresh, refresh) : this.refresh;
        reset = reset ? merge(this.reset, reset) : this.reset;
        this.cache = request.pipe(cache2(ttl, push, refresh, reset), zoned);
    }
}

@Injectable()
export class RequestsState {
    navRootInjector = new ReplaySubject<Injector>(1);

    private _acceptableStateForConfig = combineLatest(
        [this.authState.role, this.appState.imunifyStatus]
    ).pipe(
        // User must be logged in, Agent must be running
        filter(([role, status]) => role !== I360Role.none && status === AgentStatus.OK),
    );
    config = new CachedRequest<AdminConfigType>({
        ttl: 300,
        request: combineLatest([this.navRootInjector, this._acceptableStateForConfig]).pipe(
            take(1),
            switchMap(([navRootInjector, ...rest]) => navRootInjector.get(SettingsService).show()),
            map(res => res.data.items as AdminConfigType),
        ),
        refresh: this._acceptableStateForConfig,
        reset: this.authState.role,
    });

    private _acceptableStateForConflicts = this._acceptableStateForConfig.pipe(
        filter(([role, ...rest]) => role === I360Role.admin),
    );
    conflicts = new CachedRequest<I360Conflicts>({
        ttl: 300,
        request: combineLatest([this.navRootInjector, this._acceptableStateForConflicts]).pipe(
            take(1),
            switchMap(([navRootInjector, ...rest]) => navRootInjector.get(SettingsService).conflicts()),
            map(res => res.data.items as I360Conflicts),
        ),
        refresh: this._acceptableStateForConflicts,
        reset: this.authState.role,
    });

    private _acceptableStateForInfectedDomains = combineLatest([
        this.licenseState.license.pipe(
            map(license => license.isFreeVersion),
            distinctUntilChanged(),
        ),
        this.authState.role,
        this.appState.imunifyStatus,
    ]).pipe(
        // User must be logged in, Agent must be running, license must allow Reputation Management
        filter(([isFreeVersion, role, status]) => {
            return !isFreeVersion && role === I360Role.admin && status === AgentStatus.OK;
        }),
    );
    infectedDomainsCount = new CachedRequest<number>({
        ttl: 300,
        request: combineLatest([
            this.navRootInjector,
            this._acceptableStateForInfectedDomains,
        ]).pipe(
            take(1),
            switchMap(([navRootInjector, ...rest]) => navRootInjector.get(ReputationService).infectedDomains({limit: 1})),
            map(res => res.data.max_count || 0),
        ),
        refresh: this._acceptableStateForInfectedDomains,
        reset: combineLatest([
            this.licenseState.license.pipe(  // reset if license is lost (e.g. becomes invalid)
                map(license => license.isFreeVersion),
                distinctUntilChanged(),
                filter(identity),
            ),
            this.authState.role,  // reset on any auth change
        ]),
    });

    private _acceptableStateForClientFeatures = this._acceptableStateForConfig.pipe(
        // User must be logged in not as admin
        filter(([role, status]) => {
            return role === I360Role.client;
        }),
    );
    clientFeatures = new CachedRequest<ClientFeatures>({
        ttl: Infinity,
        request: combineLatest([
            this.navRootInjector,
            this._acceptableStateForClientFeatures,
        ]).pipe(
            take(1),
            switchMap(([navRootInjector, ...rest]) => navRootInjector.get(UserFeaturesStatusService).getClientFeatures()),
            map(res => res.data.items),
        ),
        refresh: this._acceptableStateForClientFeatures,
        reset: this.authState.role,  // reset on any auth change
    });
    // TODO: replace with stuff from `permissions list` endpoint.
    hasEnabledFeatures = this.clientFeatures.cache.pipe(
        map(f => f == null ? null : (f.av !== BoolFeature.na || f.proactive !== BoolFeature.na)),
    );
    hasAv = this.clientFeatures.cache.pipe(
        map(f => f == null ? null : f.av !== BoolFeature.na),
    );
    hasAvFull = this.clientFeatures.cache.pipe(
        map(f => f == null ? null : f.av === ReportableFeature.full),
    );
    hasProactive = this.clientFeatures.cache.pipe(
        map(f => f == null ? null : f.proactive !== BoolFeature.na),
    );

    constructor(
        private appState: AppState,
        private authState: AuthState,
        private licenseState: LicenseState,
    ) {}
}
