import { AgentResponse, DataFrom, RequestParams, ResponseDataExceptItems } from './misc';

interface MalwareReadAgentResponseData extends ResponseDataExceptItems {
    data: {
        size: number;
        chunk: string;
        limit: number;
        offset: number;
        eof: boolean;
    };
}

type MalwareReadAgentResponse = AgentResponse<MalwareReadAgentResponseData>;

export class FileAPI {
    static CHUNK_LENGTH = 100 * 1024; // File chunk length (Bytes)
    static load({path, limit, offset}: FileInterface) {
        return new RequestParams<DataFrom<MalwareReadAgentResponse>>(
            ['malware', 'read'],
            {
                path,
                limit: limit || FileAPI.CHUNK_LENGTH,
                offset: offset || 0,
            },
        );
    }
}

interface FileInterface {
    path: string;
    limit?: number;
    offset?: number;
}
