import { Injectable } from '@angular/core';
import { AgentStatus } from '@imunify360-api/misc';
import { ZonedBehaviorSubject } from 'app/utils/rxjs-zoned';

@Injectable()
export class AppState {
    version = VERSION;
    csf: boolean;
    imunifyStatus: ZonedBehaviorSubject<AgentStatus | null> = new ZonedBehaviorSubject(AgentStatus.OK);
    responseStatusCode: ZonedBehaviorSubject<number> = new ZonedBehaviorSubject(200);
    refresherEnabled = false;
    mousePositionY: number = 150; // initial value for DA, while no other mousemove events
    rpcReady$ = new ZonedBehaviorSubject(false); // true, when at least one RPC request is answered
    demo: boolean = false; // Server is running in demo mode: dashboard requests are mocked etc
    lang: ZonedBehaviorSubject<string|null> = new ZonedBehaviorSubject(null);
    notifications: ZonedBehaviorSubject<Notification[]> = new ZonedBehaviorSubject([]);
    lowResourcesMode$ = new ZonedBehaviorSubject(false);
    overlayContainer: HTMLElement;
}

export class Notification {
    type: string;
    title: string;
    content: string;
    counter: number = 0;
    ticks: number = 0;

    constructor(item: {type: string, title: string, content: string}) {
        this.type = item.type;
        this.title = item.title;
        this.content = item.content;
    }
}
