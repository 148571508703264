import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ModalEntry } from 'app/utils/modals/entry';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ModalDetailsComponentParams {
    details: string;
}

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent extends ModalEntry<ModalDetailsComponentParams, false> {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDetailsComponentParams) {
        super();
    }
}
