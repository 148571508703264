import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';

export enum Permission {
    MS_VIEW = 'malware_scanner.view',
    MS_CLEAN = 'malware_scanner.clean',
    MS_CLEAN_REQUIRES_MYIMUNIFY_PROTECTION = 'malware_scanner.clean_requires_myimunify_protection',
    MS_ON_DEMAND_SCAN = 'malware_scanner.on_demand.scan',
    MS_ON_DEMAND_SCAN_WITHOUT_RATE_LIMIT = 'malware_scanner.on_demand.scan_without_rate_limit',
    MS_IGNORE_LIST_EDIT = 'malware_scanner.ignore_list.edit',
    MS_CONFIG_DEFAULT_ACTION_EDIT = 'malware_scanner.config.default_action.edit',
    PD_VIEW = 'proactive_defense.view',
    PD_CONFIG_MODE_EDIT = 'proactive_defense.config.mode.edit',
}

type PermissionsListAgentResponse
    = DataFrom<AgentResponse<AgentResponseData<Array<Permission>>>>;

export class PermissionsAPI {
    static list = () => new RequestParams<PermissionsListAgentResponse>(['permissions', 'list']);
}
