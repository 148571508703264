declare let Zone;  // window.Zone provided by externally loaded ZoneJS module

export const wrapLifecycleMethod = (method, appName) => {
    const patched = (...args) => {
        // console.log(`${appName}`, method, args);
        return new Promise((resolve, reject) => {
            if ((Zone.current as any)._properties[`single-spa-angular:${appName}`] && Zone.current.parent) {
                // console.log(`${appName}: not out of Angular zone yet, let's try parent`);
                Zone.current.parent.run(() => { resolve(patched(...args)); })
            } else {
                // console.log(`${appName}: safe out of Angular zone`);
                resolve(method(...args))
            }
        });
    }
    return patched;
}
