import {
    AgentResponse, AgentResponseData, DataFrom, ListResponseData,
    NullAgentResponse, RequestParams, SucceededFailedAgentResponse,
} from './misc';
import { MalwareScanStatus } from './user-list';

export enum ResourceType {
    file = 'file',
    db = 'db',
}

type MdsResourceTypeRelatedData = {
    resource_type: ResourceType.db;
    app_name: string | null;
    db_host: string | null;
    db_port: string | null;
    db_name: string | null;
};

type FileResourceTypeRelatedData = {
    resource_type: ResourceType.file;
};

export type ResourceTypeLocationRelatedData
    = MdsResourceTypeRelatedData | FileResourceTypeRelatedData;

export enum ScanState {
    Stopped = 'stopped',
    Running = 'running',
}

export enum MalwareScanType {
    ondemand = 'on-demand',
    background = 'background',
    user = 'user',
}

type OnDemandRunningStatus = {
    status: ScanState.Running;
    scan_type: MalwareScanType;
    created: number;
    path: string;
    phase: string;
    progress: number;
    file_patterns?: string[] | null;
    exclude_patterns?: string[] | null;
    follow_symlinks?: boolean;
    intensity_io: number;
    intensity_cpu: number;
    intensity_ram: number;
    // unused:
    [k: string]: any;
};

type OnDemandStoppedStatus = {
    status: ScanState.Stopped;

    // unused
    queued: number;
};

type OnDemandStatus = OnDemandRunningStatus | OnDemandStoppedStatus;
type OnDemandUserStatus = {
    progress?: number;
    status: MalwareScanStatus;
};

interface StartParams {
    path: string;
    file_mask?: string;
    ignore_mask?: string;
    follow_symlinks?: boolean;
    intensity_cpu?: number;
    intensity_io?: number;
}

interface CleanupParams {
    user: string[];
}

interface QueueAddParams {
    paths: string[];
    detect_elf: boolean;
}

interface QueueRemoveParams {
    scan_ids: string[];
}

interface RestoreParams extends CleanupParams {}

interface OnDemandScanItemInBackend {
    path: string;
    scan_type?: MalwareScanType;
    scan_status: MalwareScanStatus;
    scanid: string;
    started: number;
    total_resources: number;
    resource_type: ResourceType;
    total_malicious: number;
    error: string | null;
    // unused
    [k: string]: any;
}

export class OnDemandScanItem {
    total_malicious: number; // tslint:disable-line:variable-name
    scan_type?: MalwareScanType; // tslint:disable-line:variable-name
    scan_status: MalwareScanStatus; // tslint:disable-line:variable-name
    total_resources: number; // tslint:disable-line:variable-name
    resource_type: ResourceType; // tslint:disable-line:variable-name
    path: string;
    started: number;
    scanid: string;
    error: string | null;
    constructor(data: OnDemandScanItemInBackend) {
        this.total_malicious = data.total_malicious;
        this.scan_status = data.scan_status;
        this.total_resources = data.total_resources;
        this.resource_type = data.resource_type;
        this.path = data.path;
        this.started = data.started;
        this.scanid = data.scanid;
        this.scan_type = data.scan_type;
        this.error = data.error;
    }
}

export class MalwareAPI {

    static list = (params = {}) => new RequestParams<DataFrom<OnDemandScanListAgentResponse>>
        (['malware', 'on-demand', 'list'], params)

    static listForUser = (params = {}) => new RequestParams<DataFrom<OnDemandScanListAgentResponse>>
        (['malware', 'on-demand', 'list-user'], params)

    /**
     * TODO: move to user-list
     */
    static cleanup = (params: CleanupParams = { user: [] }) =>
        new RequestParams<DataFrom<NullAgentResponse>>(['malware', 'user', 'cleanup'], params)

    /**
     * TODO: move to user-list
     */
    static restore = (params: RestoreParams = { user: [] }) =>
        new RequestParams<DataFrom<SucceededFailedAgentResponse>>
        (['malware', 'user', 'restore-original'], params)

    /**
     * TODO: move to user-list
     */
    static queueAdd = (params: QueueAddParams = { paths: [], detect_elf: false }) =>
        new RequestParams<DataFrom<NullAgentResponse>>
        (['malware', 'on-demand', 'queue', 'put'], params)

    /**
     * TODO: move to user-list
     */
    static queueRemove = (params: QueueRemoveParams = { scan_ids: [] }) =>
        new RequestParams<DataFrom<NullAgentResponse>>
        (['malware', 'on-demand', 'queue', 'remove'], params)
    static start = (params: StartParams = {path: ''}) =>
        new RequestParams<DataFrom<NullAgentResponse>>(['malware', 'on-demand', 'start'], params)
    static stop = (params = {}) =>
        new RequestParams<DataFrom<NullAgentResponse>>(['malware', 'on-demand', 'stop'], params)
    static status = () =>
        new RequestParams<DataFrom<OnDemandScanStatusAgentResponse>>
        (['malware', 'on-demand', 'status'])
    static userStatus = () =>
        new RequestParams<DataFrom<OnDemandUserScanStatusAgentResponse>>
        (['malware', 'on-demand', 'status-user'])
    static  userStart = () =>
        new RequestParams<DataFrom<NullAgentResponse>>
        (['malware', 'on-demand', 'start-user'])
    static userStop = () =>
        new RequestParams<DataFrom<NullAgentResponse>>
        (['malware', 'on-demand', 'stop-user'])
}

type OnDemandScanListAgentResponse = AgentResponse<ListResponseData<OnDemandScanItemInBackend>>;

type OnDemandScanStatusAgentResponse = AgentResponse<AgentResponseData<OnDemandStatus>>;
type OnDemandUserScanStatusAgentResponse = AgentResponse<AgentResponseData<OnDemandUserStatus>>;
