import { Injectable } from '@angular/core';
import { identity, filter, take } from 'rxjs';
import { AdminContactsOpenerService } from './admin-contacts-opener.service';


@Injectable()
export class AdminContactsOpenerSequentialService extends AdminContactsOpenerService {
    openIfNeeded(): void {
        this.newsAdminContactsComponent.isCountable.pipe(
            take(1),
            filter(identity),
        ).subscribe(() => {
            this.newsAdminContactsComponent.openOverlay.next(true);
            this.adminContactsHistoryService.addHistoryRecord({ timestamp: Date.now() });
        });
    }
}
