import { baseEndpoint } from '@imunify360-api/server';

const exceptions = [
    `${ baseEndpoint }ab-view`,
    `${ baseEndpoint }ab-event`,
];

export const postOnly = (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    let originalMethod = descriptor.value;
    descriptor.value = function (req, next) {
        const shouldApply = req.method === 'POST' && !exceptions.includes(req.url);
        if (shouldApply) {
            return originalMethod.apply(this, [req, next]);
        } else {
            return next.handle(req);
        }
    };

    return descriptor;
};
