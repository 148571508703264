import { InstallationAPI } from '@imunify360-api/installation';
import { Injectable } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';

@Injectable({
    providedIn: 'root',
})
export class InstallationBackendService {
    constructor(public xhr: XHR) {}

    status = this.xhr.rx(InstallationAPI.status);
    start = this.xhr.request(InstallationAPI.start);
}
