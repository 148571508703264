import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, map, switchMap } from 'rxjs';
import { baseEndpoint } from '@imunify360-api/server';
import { LicenseState } from 'app/services/license-state';
import { cache } from 'app/utils/cache';
import { AuthCloudService } from 'app/services/auth-cloud';

export enum ABEvent {
    VIEW = 'upgrade_link_view',
    CLICK = 'upgrade_link_click',
}

export interface UpgradeButtonText {
    tests: {
        upgrade_link_text: string;
    }
}

@Injectable()
export class UpgradeButtonService {

    private serverId: string;
    private view = of(null).pipe(
        switchMap(() => this.http.post<UpgradeButtonText>(
            `${ baseEndpoint }ab-view`,
            {data: {server_id: this.serverId}},
        )),
        cache(Infinity),
    );

    constructor(
        private http: HttpClient,
        private licenseState: LicenseState,
        private authCloudService: AuthCloudService,
    ) {}

    getABText(): Observable<string> {
        this.serverId = this.licenseState.license.value.id;
        return this.view.pipe(
            map((response: UpgradeButtonText) => {
                return response.tests?.upgrade_link_text;
            })
        );
    }

    sendABEventView(upgradeLinkText: string): Observable<void> {
        return this.sendABEvent(upgradeLinkText, ABEvent.VIEW);
    }

    sendABEventClick(upgradeLinkText: string): Observable<void> {
        return this.sendABEvent(upgradeLinkText, ABEvent.CLICK);
    }

    private sendABEvent(upgradeLinkText: string, event: ABEvent): Observable<void> {
        return this.authCloudService.getIaidToken().pipe(switchMap(iaid => {
            return this.sendEvent(upgradeLinkText, event, iaid || '');
        }));
    }

    private sendEvent(upgradeLinkText: string, event: ABEvent, token: string): Observable<void> {
        const eventData = {
            event,
            variant_id: upgradeLinkText,
            params: {},
            user_ids: {
                server_id: this.serverId,
            }
        };

        return this.http.post<void>(
            `${ baseEndpoint }customer-event`,
            eventData,
            {
                headers: { 'X-Auth': token }
            }
        )
    }
}
