import { AgentResponse, AgentResponseData, DataFrom,
    RequestParams, StringAgentResponse } from './misc';


export enum FeatureStatus {
    error = 'error',
    installed = 'installed',
    installing = 'installing',
    removing = 'removing',
    not_supported_by_cl_solo = 'not-supported-by-cl-solo',
    not_installed = 'not_installed',
    managed_by_lve = 'managed_by_lve',
    detecting = 'detecting',
}

export type FeatureStatusDict = ({
    message?: string,
} & ({
    lve_installed: boolean,
    status: FeatureStatus.managed_by_lve,
} | {
    status: FeatureStatus.installed,
    // for kernelcare only, it can not be validated for now
    // there is a way, for example: to put 'name' (kernelcare, hardened-php) into response, create
    // type for each response, and join them as union type. But setStatus call
    // in the bottom of the feature.component.ts can not be validated due to bug,
    // which is not reproduced only in 3.5.1 (+?) version of typescript
    // demo of bug:
    // tslint:disable-next-line:max-line-length
    // https://www.typescriptlang.org/play/#code/C4TwDgpgBAglC8UoG8CwAoJSwC4oHIBDfAGgwF8BuDDUSKAIQRQyylwICNSLr0MAZgFcAdgGNgASwD2IqAIAUEPEXxQAPl3wBKFpiwAbCMChC8cTU0Rp9bbHghlbUKhQxA
    edf_supported: boolean,
} | {
    status: Exclude<FeatureStatus, FeatureStatus.managed_by_lve>,
}));

type FeatureStatusAgentResponse = AgentResponse<AgentResponseData<FeatureStatusDict>>;

export class FeaturesAPI {
    static status(name: string) {
        return new RequestParams<DataFrom<FeatureStatusAgentResponse>>(
            ['features', 'status'], {name},
        );
    }
    static action({action, name}: {action: 'install' | 'remove', name: string}) {
        return new RequestParams<DataFrom<StringAgentResponse>>(['features', action], {name});
    }
}
