import {
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'i360-modal-content',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ModalContentComponent {
    @Input() confirmText: string = 'utils.modals.content.confirm';
    @Input() cancelText: string = 'utils.modals.content.cancel';
    @Input() confirmDisabled: boolean = false;
    @Input() confirmDisabledAlways: boolean = false; // some else reason to disable except invalid form
    @Input() confirmNote: string = '';
    @Input() uppercaseButtons: boolean = true;
    @Input() showCancel = true;
    @Input() showConfirm = true;
    @Input() showClose = true;
    dialogResult = true;

    constructor(private dialogRef: MatDialogRef<any>) {}

    close() {
        this.dialogRef.close(this.dialogResult);
    }
}
