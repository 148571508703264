import {
    BackupSystem,
    BackupSystemState,
    ClPaymentStatus, LocalBackupSystem,
    Schedule,
} from '../backup-systems';
import { DEFAULT_DATA } from './misc';

const LOG_FILE = '/path/to/log/file';
const listResponse = (backend: LocalBackupSystem) => ({
    data: {
        ...DEFAULT_DATA,
        items: [
            backend,
            BackupSystem.acronis,
            BackupSystem.cloudlinux,
            BackupSystem.cloudlinux_on_premise,
            BackupSystem.clusterlogics,
        ],
    },
});
export const responses = {
    null: {
        data: {
            ...DEFAULT_DATA,
            items: null,
        },
    },
    init: {
        data: {
            ...DEFAULT_DATA,
            items: 'Backup initialization process is in progress',
        },
    },
    list: {
        cpanel: listResponse(BackupSystem.cpanel),
        plesk: listResponse(BackupSystem.plesk),
        da: listResponse(BackupSystem.directadmin),
    },
    statusDisabled: {
        data: {
            ...DEFAULT_DATA,
            items: {
                enabled: false as false,
                backup_system: null,
                state: BackupSystemState.not_running as BackupSystemState.not_running,
                error: null,
                log_path: null,
            },
        },
    },
    narrowStatus: {
        data: {
            ...DEFAULT_DATA,
            items: {
                enabled: false,
                backup_system: null,
            },
        },
    },
    acronis: {
        status: {
            init: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: null,
                        log_path: LOG_FILE,
                        backup_system: BackupSystem.acronis as BackupSystem.acronis,
                        state: BackupSystemState.init as BackupSystemState.init,
                    },
                },
            },
            done: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        backup_system: <BackupSystem.acronis>BackupSystem.acronis,
                        state: <BackupSystemState.done>BackupSystemState.done,
                        username: 'username',
                        timestamp: 0,
                        log_path: 'unused',
                    },
                },
            },
            error: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: 'error',
                        log_path: '',
                        backup_system: <BackupSystem.acronis>BackupSystem.acronis,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
        },
    },
    local: {
        done: {
            data: {
                ...DEFAULT_DATA,
                items: {
                    enabled: true,
                    error: null,
                    state: <BackupSystemState.done>BackupSystemState.done,
                    backup_system: <BackupSystem.cpanel>BackupSystem.cpanel,
                    log_path: null,
                },
            },
        },
    },
    cl: {
        status: {
            notRunningLikeDoneErrorAllDataPresent: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        username: 'AB-1124',
                        timestamp: 1531711741,
                        log_path: '/var/log/imunify360/acronis-installer.log',
                        region: 'eu',
                        login_url: 'https://cloud.acronis.com?jwt=',
                        error: 'No backups found!',
                        purchased_backup_gb: 200,
                        backup_system: BackupSystem.cloudlinux as BackupSystem.cloudlinux,
                        schedule: {
                            startAt: {hour: 5, second: 0, minute: 0},
                            effectiveDates: {
                                from: {month: 0, day: 0, year: 0},
                                to: {month: 0, day: 0, year: 0},
                            },
                            daysOfWeek: ['sunday', 'monday', 'tuesday',
                                'wednesday', 'thursday', 'friday', 'saturday'],
                            type: 'weekly',
                        } as Schedule,
                        resize_url: 'https://cln.cloudlinux.com/clweb/account/ab_resize.xhtml?id=',
                        backup_space_used_bytes: 111104831488,
                        state: BackupSystemState.not_running as BackupSystemState.not_running,
                        enabled: true,
                    },
                },
            },
            doneNoSchedule: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        backup_space_used_bytes: 658712047616,
                        timestamp: 1530525957,
                        state: <BackupSystemState.done>BackupSystemState.done,
                        username: 'username',
                        log_path: '/var/log/imunify360/acronis-installer.log',
                        login_url: 'https://cloud.acronis.com?jwt=',
                        resize_url: 'https://cln.cloudlinux.com/clweb/account/ab_resize.xhtml?id=',
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        schedule: null,
                        enabled: true,
                        purchased_backup_gb: 810,
                        error: null,
                        region: 'eu',
                    },
                },
            },
            backupNoSchedule: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        state: <BackupSystemState.backup>BackupSystemState.backup,
                        enabled: true,
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        error: 'Backup is unpaid!',
                    },
                },
            },
            init: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: null,
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        log_path: LOG_FILE,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
            error: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: 'error',
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        log_path: LOG_FILE,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
            done: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        state: <BackupSystemState.done>BackupSystemState.done,
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        region: 'eu',
                        purchased_backup_gb: 10,
                        timestamp: 1523948175,
                        schedule: {
                            startAt: {hour: 18, minute: 0, second: 0},
                            effectiveDates: {
                                to: {year: 0, month: 0, day: 0},
                                from: {year: 0, month: 0, day: 0},
                            },
                            daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                            type: 'weekly',
                        } as Schedule,
                        // tslint:disable-next-line:max-line-length
                        login_url: 'https://cloud.acronis.com?jwt=jwt',
                        resize_url: 'https://cln.cloudlinux.com/clweb/account/ab_resize.xhtml?id=i',
                        username: 'AB-123456-7890',
                        backup_space_used_bytes: 0,
                    },
                },
            },
            backup: (progress: number|null) => ({
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        // type validation not working
                        state: <BackupSystemState.done>BackupSystemState.backup,
                        progress,
                        backup_system: <BackupSystem.cloudlinux>BackupSystem.cloudlinux,
                        region: 'eu',
                        purchased_backup_gb: 10,
                        timestamp: 1523948175,
                        schedule: {
                            startAt: {hour: 18, minute: 0, second: 0},
                            effectiveDates: {
                                to: {year: 0, month: 0, day: 0},
                                from: {year: 0, month: 0, day: 0},
                            },
                            daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                            type: 'weekly',
                        } as Schedule,
                        // tslint:disable-next-line:max-line-length
                        login_url: 'https://cloud.acronis.com?jwt=jvt',
                        resize_url: 'https://cln.cloudlinux.com/clweb/account/ab_resize.xhtml?id=i',
                        username: 'AB-123456-7890',
                        backup_space_used_bytes: 0,
                    },
                },
            }),
        },
        check: {
            unpaid: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        status: <ClPaymentStatus.unpaid>ClPaymentStatus.unpaid,
                        url: 'https://cln.cloudlinux.com/clweb/cb/',
                    },
                },
            },
            paid: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        status: <ClPaymentStatus.paid>ClPaymentStatus.paid,
                    },
                },
            },
        },
    },
    clOnPremise: {
        status: {
            init: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: null,
                        backup_system: <BackupSystem.cloudlinux_on_premise>
                            BackupSystem.cloudlinux_on_premise,
                        log_path: LOG_FILE,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
            error: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: 'error',
                        backup_system: <BackupSystem.cloudlinux_on_premise>
                            BackupSystem.cloudlinux_on_premise,
                        log_path: LOG_FILE,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
            done: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        state: <BackupSystemState.done>BackupSystemState.done,
                        backup_system: <BackupSystem.cloudlinux_on_premise>
                            BackupSystem.cloudlinux_on_premise,
                        schedule: {
                            startAt: {hour: 18, minute: 0, second: 0},
                            effectiveDates: {
                                to: {year: 0, month: 0, day: 0},
                                from: {year: 0, month: 0, day: 0},
                            },
                            daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                            type: 'weekly',
                        } as Schedule,
                        // tslint:disable-next-line:max-line-length
                        login_url: 'https://cloud.acronis.com?jwt=jwt',
                        username: 'AB-123123123',
                    },
                },
            },
            backup: (progress: number|null) => ({
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        state: <BackupSystemState.backup>BackupSystemState.backup,
                        progress,
                        backup_system: <BackupSystem.cloudlinux_on_premise>
                            BackupSystem.cloudlinux_on_premise,
                        schedule: {
                            startAt: {hour: 18, minute: 0, second: 0},
                            effectiveDates: {
                                to: {year: 0, month: 0, day: 0},
                                from: {year: 0, month: 0, day: 0},
                            },
                            daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                            type: 'weekly',
                        } as Schedule,
                        // tslint:disable-next-line:max-line-length
                        login_url: 'https://cloud.acronis.com?jwt=jwt',
                        username: 'AB-123123123',
                    },
                },
            }),

        },
    },
    clusterlogics: {
        status: {
            done: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: true,
                        error: null,
                        state: <BackupSystemState.done>BackupSystemState.done,
                        backup_system: <BackupSystem.clusterlogics>
                            BackupSystem.clusterlogics,
                        username: 'username',
                        apikey: 'apikey',
                        url: 'url',
                        log_path: null,
                    },
                },
            },
            error: {
                data: {
                    ...DEFAULT_DATA,
                    items: {
                        enabled: false,
                        error: 'error',
                        backup_system: <BackupSystem.clusterlogics>
                            BackupSystem.clusterlogics,
                        log_path: LOG_FILE,
                        state: <BackupSystemState.init>BackupSystemState.init,
                    },
                },
            },
        },
    },
};
