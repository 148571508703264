import { Component, HostListener, ViewChild } from '@angular/core';
import { Package } from '@imunify360-api/license';
import { NewsItem } from '@imunify360-api/news';
import { Observable } from 'rxjs';
import { NewsNotificationsComponent } from '../news-notifications/news-notifications.component';
import { NewsService } from '../news.service';
import { AppState } from '@imunify/nav-root';


@Component({
    selector: 'i360-news',
    template: `
        <i360-news-notifications>
            <i360-news-license></i360-news-license>
            <i360-news-admin-contacts *ngIf="is360Package"></i360-news-admin-contacts>
            <i360-news-message *ngFor="let message of news$ | async" [message]="message">
            </i360-news-message>
        </i360-news-notifications>
    `,
    host: {
        '[class.i360-news-active]': 'newsNotificationsComponent.overlayVisible && !appState.lowResourcesMode$.getValue()'
    },
    styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
    @ViewChild(NewsNotificationsComponent, {static: true})
    newsNotificationsComponent: NewsNotificationsComponent;
    news$: Observable<NewsItem[]>;
    is360Package = IMUNIFY_PACKAGE === Package.imunify360;

    constructor(newsService: NewsService, public appState: AppState) {
        this.news$ = newsService.getNews$();
    }

    @HostListener('click')
    handleHostClick() {
        this.newsNotificationsComponent.toggleOverlay();
    }
}
