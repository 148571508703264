import { DEFAULT_DATA } from './misc';
import { RulePlugin } from '../blocked-rules';

export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            max_count: 100,
            items: [
                {
                    id: '123',
                    plugin: RulePlugin.modsec,
                    name: 'SQL Inejctions for Joomla super advanced detection',
                    domains: [],
                },
                {
                    id: '124',
                    plugin: RulePlugin.modsec,
                    name: 'bill1',
                    domains: null,
                },
                {
                    id: '125',
                    plugin: RulePlugin.modsec,
                    name: 'bill2',
                    domains: [
                        'mycoolsite.com',
                        'website.ru',
                        'exclude.com',
                    ],
                },
                {
                    id: '126',
                    plugin: RulePlugin.modsec,
                    name: 'bill3',
                    domains: [
                        'zzz.com',
                    ],
                },
                {
                    id: '127',
                    plugin: RulePlugin.modsec,
                    name: 'Some wordpress',
                    domains: [
                        'example.com',
                        'example1.com',
                    ],
                },
            ],
        },
    },
};
