import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AgentResponse } from '@imunify360-api/misc';
import { postOnly } from 'app/interceptors/utils';
import { MaliciousService } from 'app/services/malicious';

@Injectable()
export class MaliciousCountInterceptor implements HttpInterceptor {

    constructor(
        private maliciousService: MaliciousService,
    ) {}

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    try {
                        const res = event.body as AgentResponse;
                        const data = res.data;
                        if ('malicious_count' in data) {
                            this.maliciousService.maliciousCount$.next(data.malicious_count);
                        }
                    } catch (e) {
                        // responses are not standardized...
                    }
                }
            }),
        );
    }
}
