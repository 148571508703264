import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeButtonDirective } from 'app/directives/upgrade-button/upgrade-button.directive';
import { LicenseService } from 'app/services/license';
import { UpgradeButtonService } from 'app/directives/upgrade-button/upgrade-button.service';


@NgModule({
    declarations: [
        UpgradeButtonDirective
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        LicenseService,
        UpgradeButtonService,
    ],
    exports: [
        UpgradeButtonDirective
    ]
})
export class DirectivesModule {
}
