import { AgentResponse, AgentResponseData, DataFrom,
    NullAgentResponse, RequestParams } from './misc';

export enum BackupSystem {
    cpanel = 'cpanel',
    plesk = 'plesk',
    directadmin = 'directadmin',
    acronis = 'acronis',
    sample = 'sample',
    cloudlinux = 'cloudlinux',
    cloudlinux_on_premise = 'cloudlinux_on_premise',
    r1soft = 'r1soft',
    clusterlogics = 'clusterlogics',
}
export type LocalBackupSystem = BackupSystem.cpanel | BackupSystem.plesk | BackupSystem.directadmin
    | BackupSystem.sample;
export type BackupSystemWithCredentials = BackupSystem.cloudlinux_on_premise | BackupSystem.acronis;

export enum ScheduleType {
    monthlyByDaysOfMonth = 'monthlyByDaysOfMonth',
    monthlyByDaysOfWeek = 'monthlyByDaysOfWeek',
    weekly = 'weekly',
    daily = 'daily',
    hourly = 'hourly',
    timeSinceBackup = 'timeSinceBackup',
    onStartup = 'onStartup',
    onShutdown = 'onShutdown',
}
export type ScheduleCommon = {
    preventFromSleeping: boolean,
    runLater: boolean,
    machineWake: boolean,
};
type ScheduleDayNumbers = Array<number | string>;
type ScheduleDayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday'
    | 'thursday' | 'friday' | 'saturday';
export type ScheduleTime = { minute: number, second: number, hour: number };
type ScheduleDate = { year: number, day: number, month: number };
type ScheduleEffectiveDates = {
    to: ScheduleDate;
    from: ScheduleDate;
};
type ScheduleIntervalSince = {
    value: number,
    type: 'days'|'hours',
};
interface ScheduleMonthlyByDaysOfMonth extends ScheduleCommon {
    type: ScheduleType.monthlyByDaysOfMonth;
    startAt: ScheduleTime;
    effectiveDates: ScheduleEffectiveDates;
    multipleDaysAllowed: true;
    dayNumbers: ScheduleDayNumbers;
}
interface ScheduleMonthlyByDaysOfWeek extends ScheduleCommon {
    type: ScheduleType.monthlyByDaysOfWeek;
    startAt: ScheduleTime;
    daysOfWeek: ScheduleDayOfWeek[];
    effectiveDates: ScheduleEffectiveDates;
    multipleDaysAllowed: true;
    occurence: ['second', 'last'];
}
interface ScheduleWeekly extends ScheduleCommon {
    type: ScheduleType.weekly;
    daysOfWeek: ScheduleDayOfWeek[];
    startAt: ScheduleTime;
    effectiveDates: ScheduleEffectiveDates;
}
interface ScheduleDaily extends ScheduleCommon {
    type: ScheduleType.daily;
    effectiveDates: ScheduleEffectiveDates;
    startAt: ScheduleTime;
    daysOfWeek: ScheduleDayOfWeek[];
}
interface ScheduleHourly extends ScheduleCommon {
    type: ScheduleType.hourly;
    from: ScheduleTime;
    effectiveDates: ScheduleEffectiveDates;
    daysOfWeek: ScheduleDayOfWeek[];
    to: ScheduleTime;
    repeatInterval: { type: 'hours'|'minutes', value: number };
}
interface ScheduleTimeSinceBackup extends ScheduleCommon {
    type: ScheduleType.timeSinceBackup;
    intervalSinceLastBackup: ScheduleIntervalSince;
}
interface ScheduleOnStartup {
    type: ScheduleType.onStartup;
}
interface ScheduleOnShutdown {
    type: ScheduleType.onShutdown;
}

export type Schedule = ScheduleMonthlyByDaysOfMonth
    | ScheduleMonthlyByDaysOfWeek
    | ScheduleWeekly
    | ScheduleDaily
    | ScheduleHourly
    | ScheduleTimeSinceBackup
    | ScheduleOnStartup
    | ScheduleOnShutdown;

export enum BackupSystemState {
    done = 'done',
    init = 'init',
    backup = 'backup',
    not_running = 'not_running',
    activation = 'activation',
}

interface CloudlinuxBackupCheckPaidResponseDataItems {
    status: ClPaymentStatus.paid;
    size?: number,
}

interface CloudlinuxBackupCheckUnpaidResponseDataItems {
    status: ClPaymentStatus.unpaid;
    size?: number,
    url: string;
}

type BackupSystemCloudlinuxCheckAgentResponse = AgentResponse<AgentResponseData<
    CloudlinuxBackupCheckPaidResponseDataItems | CloudlinuxBackupCheckUnpaidResponseDataItems>>;

export enum ClPaymentStatus {
    paid = 'paid',
    unpaid = 'unpaid',
}

export type ExtendedBackupSystemStatusAgentResponse = AgentResponse<AgentResponseData<
    CloudlinuxStatusBackupData
    | ClusterLogicsStatusDoneData
    | AcronisStatusDoneData
    | BackupSystemStatusDisabledData
    | StatusInitData
    | BackupSystemLocalStatusData
    | CloudlinuxStatusDoneData
    | CloudlinuxOnPremiseStatusBackupData
    | CloudlinuxOnPremiseStatusDoneData
    | CloudlinuxStatusNotRunningLikeDoneWithErrorData>>;

export type NarrowBackupSystemStatusAgentResponse = AgentResponse<AgentResponseData<
    NarrowBackupSystemStatusData>>;

interface NarrowBackupSystemStatusData {
    backup_system: BackupSystem | null;
    enabled: boolean;
}

interface BackupSystemLocalStatusData {
    backup_system: LocalBackupSystem;
    state: BackupSystemState;
    error: any;
    enabled: boolean;
    log_path: null;
}

interface BackupSystemStatusDisabledData {
    backup_system: null;
    enabled: false;
    state: BackupSystemState.not_running;
    error?: string | null;
    log_path: null;
}

interface StatusInitData {
    backup_system: BackupSystem.cloudlinux | BackupSystem.cloudlinux_on_premise
        | BackupSystem.acronis | BackupSystem.clusterlogics;
    state: BackupSystemState.init;
    log_path: string;
    error: any;
    enabled: boolean;
}

interface CloudlinuxStatusExtendedData {
    backup_system: BackupSystem.cloudlinux;
    enabled: boolean;
    error: null|string;
    purchased_backup_gb: number;
    backup_space_used_bytes: number;
    login_url: string;
    timestamp: number;
    username: string;
    region: string;
    schedule: Schedule | null;
    resize_url: string;
}
interface CloudlinuxOnPremiseStatusExtendedData {
    backup_system: BackupSystem.cloudlinux_on_premise;
    enabled: boolean;
    error: null|string;
    login_url: string;
    username: string;
    schedule: Schedule | null;
    log_path?: string,
}
interface CloudlinuxOnPremiseStatusDoneData
    extends CloudlinuxOnPremiseStatusExtendedData {
    state: BackupSystemState.done;
}

interface CloudlinuxOnPremiseStatusBackupData
    extends CloudlinuxOnPremiseStatusExtendedData {
    progress: null | number;
    state: BackupSystemState.backup;
}
interface CloudlinuxStatusDoneData
    extends CloudlinuxStatusExtendedData {
    state: BackupSystemState.done;
}
export interface CloudlinuxStatusNotRunningLikeDoneWithErrorData
    extends CloudlinuxStatusExtendedData {
    state: BackupSystemState.not_running;
    error: string;
}
interface CloudlinuxStatusBackupData
    extends CloudlinuxStatusExtendedData {
    progress: null | number;
    state: BackupSystemState.backup;
}

type BackupSystemListAgentResponse = AgentResponse<AgentResponseData<BackupSystem[]>>;

export type InitArgs = {
    backend: BackupSystem.cloudlinux | LocalBackupSystem,
} | {
    backend: BackupSystemWithCredentials,
    username: string,
    password: string,
} | {
    backend: BackupSystem.clusterlogics,
    username: string,
    apikey: string,
    url?: string,
};

interface AcronisStatusDoneData {
    backup_system: BackupSystem.acronis;
    state: BackupSystemState.done;
    error: null | string;
    enabled: boolean;
    username: string;

    // not used
    timestamp: number;
    log_path: string;
}

interface ClusterLogicsStatusDoneData {
    backup_system: BackupSystem.clusterlogics;
    state: BackupSystemState.done;
    error: null | string;
    enabled: boolean;
    username: string;
    log_path: null;
    url: string;
    apikey: string;
}

type BackupSystemInitAgentResponse = AgentResponse<AgentResponseData<string>>;

export class BackupSystemsAPI {
    static status = () =>
        new RequestParams<DataFrom<ExtendedBackupSystemStatusAgentResponse>>
        (['backup-systems', 'extended-status'])

    static narrowStatus = () =>
        new RequestParams<DataFrom<NarrowBackupSystemStatusAgentResponse>>
        (['backup-systems', 'status'])

    static list = () =>
        new RequestParams<DataFrom<BackupSystemListAgentResponse>>(['backup-systems', 'list'])

    static disable(backend: Exclude<BackupSystem, BackupSystem.cloudlinux>) {
        return new RequestParams<DataFrom<NullAgentResponse>>(
            ['backup-systems', 'disable'],
            {backend},
        );
    }

    static disableCloudlinux(destroyBackups: boolean) {
        return new RequestParams<DataFrom<NullAgentResponse>>(
            ['backup-systems', 'disable'], {
                backend: BackupSystem.cloudlinux,
                delete_backups: destroyBackups,
            },
        );
    }

    static checkCloudlinux() {
        return new RequestParams<DataFrom<BackupSystemCloudlinuxCheckAgentResponse>>(
            ['backup-systems', 'check'], {backend: BackupSystem.cloudlinux});
    }

    static init(args: InitArgs) {
        return new RequestParams<DataFrom<BackupSystemInitAgentResponse>>(
            ['backup-systems', 'init'], {
            ...args,
            force: true,
        });
    }
}
