import { AgentResponseData, RequestParams } from './misc';


export class LicenseAPI {
    static register = (licenseKey: string) => new RequestParams(['register'], {regkey: licenseKey});
    static unregister = () => new RequestParams<AgentResponseData<null>>(['unregister']);
    static version = () => new RequestParams(['version']);
    static rstatus = () => new RequestParams(['rstatus']);
}

export enum Product {
    imunifyAV = 0,
    imunifyAVPlus = 1,
    imunify360 = 2,
}

export enum LicenseType {
    imunifyAV = 'imunifyAV',
    imunifyAVPlus = 'imunifyAVPlus',
    imunify360Trial = 'imunify360Trial',
    imunify360 = 'imunify360',
}

/**
 * Used to distinguish which plugin and RPM/DEB package is currently installed.
 */
export enum Package {
    imunifyAV = 'AV',
    imunify360 = '360',
}
declare const IMUNIFY_PACKAGE: Package;
const productIdToReadableName = {  // ugh
    [Product.imunifyAV]: 'ImunifyAV',
    [Product.imunifyAVPlus]: 'ImunifyAV+',
    [Product.imunify360]: 'Imunify360',
};

// FIXME: this should be reviewed during next bug fixing
type ValidLicenseInBackend = {
    id: string;
    license_type: LicenseType | null;
    message: string | null;
    redirect_url?: string | null;
    status: boolean;
    user_count: null | number;
    user_limit: number;
    ip_license?: boolean;
    pricing?: LicensePricing;
    expiration?: number | null;
    demo?: boolean | null;
    upgrade_url_360?: string | null;
    upgrade_url?: string | null;
} | {
    // https://sentry.cloudlinux.com/sentry/imunify360/issues/8223306
    // it sends random data...
    license_type?: LicenseType | null,
    status: boolean,
};

export type LicenseInBackend = ValidLicenseInBackend | {
    status: false,
    redirect_url: null,
};

export type LicensePrice = {
    standard: string,
    bulk?: string,
};

export const LicensePriceCounts = [1, 30, 250];

export type LicensePricing = {
    im_av?: LicensePrice,
    im_360_1?: LicensePrice,
    im_360_30?: LicensePrice,
    im_360_250?: LicensePrice,
    im_360_unlimited?: LicensePrice,
};

export class License {
    /* Effective product (e.g. what logo to use). Depends on IMUNIFY_PACKAGE and license_type. */
    product: Product = Product.imunifyAV;
    productName: string;
    pricing?: LicensePricing;

    /**
     * The type of used license
     */
    license_type: LicenseType | undefined;  // tslint:disable-line:variable-name

    user_limit: number;  // tslint:disable-line:variable-name
    user_count: number | null;  // tslint:disable-line:variable-name

    /**
     * Message from CLN
     */
    message: string = '';

    /**
     * In case of upgrade do not propose to enter license key
     */
    ip_license?: boolean = false;  // tslint:disable-line:variable-name

    /**
     * Where to redirect when user clicks "Upgrade" for AV plus
     */
    upgrade_url: string;  // tslint:disable-line:variable-name

    /**
     * Where to redirect when user clicks "Upgrade" for I360
     */
    upgrade_url_360: string;  // tslint:disable-line:variable-name

    /**
     * Where to redirect if user has troubles with license in Imunify360
     * FIXME: naming
     */
    redirect_url: string;  // tslint:disable-line:variable-name

    /**
     * Whether the license is appropriate for the installed package or not
     */
    status: boolean;
    id: string;
    demo?: boolean | null;
    public constructor(data: any,
                       /*Can not set to LicenseInBackend, because we do not know its type*/) {
        Object.assign(this, data);
        this.redirect_url = data.redirect_url || '';
        this.product = this.getProduct();
        this.productName = productIdToReadableName[this.product];
    }

    public isSame(otherLicense: License): boolean {  // moved from NavigationComponent
        const keys1 = Object.keys(this);
        const keys2 = Object.keys(otherLicense);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (this[key] !== otherLicense[key]) {
                return false;
            }
        }
        return true;
    }

    public get isFreeVersion() {
        return this.product === Product.imunifyAV;
    }

    public get upgradeAvailable() {
        return this.product < Product.imunify360 && this.license_type !== LicenseType.imunify360;
    }

    /**
     * Applicable only to Package.imunify360 - we block the whole UI there
     * if the license is not sufficient.
     * While in ImunifyAV we are just forbidding cleanup actions.
     */
    public isFailed(): boolean {
        return IMUNIFY_PACKAGE === Package.imunify360 && !this.status;
    }

    public hasMessage(): boolean {
        return !!this.message;
    }

    public isLimitExceeded() {
        return this.user_count != null && this.user_count > this.user_limit;
    }

    private getProduct() {
        switch (IMUNIFY_PACKAGE) {
            case Package.imunify360:
                return Product.imunify360;
            case Package.imunifyAV:
                return !this.license_type || this.license_type === LicenseType.imunifyAV
                    ? Product.imunifyAV
                    : Product.imunifyAVPlus;
            default:
                throw new Error(`Unknown package ${IMUNIFY_PACKAGE}`);
        }
    }
}
