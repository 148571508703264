<div class="flexer flex-nowrap">
    <input #input
           placeholder="{{ placeholder | translate }}"
           attr.aria-label="{{ placeholder | translate }}"
           class="form-control"
           (input)="onChange($any($event))"
           [(ngModel)]="ttl">
    <mat-form-field appearance="outline">
        <mat-select [(value)]="type" (valueChange)="onChange($event)"
                    aria-label="{{ placeholder | translate }}">
            <mat-option value="{{datepickerUnit.Days}}">
                {{ 'utils.datepicker.days' | translate }}
            </mat-option>
            <mat-option value="{{datepickerUnit.Hours}}">
                {{ 'utils.datepicker.hours' | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
