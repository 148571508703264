import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalEntry } from 'app/utils/modals/entry';
import { Notification } from 'app/core/app.service';

interface ModalDetailsComponentParams {
    details: Notification;
}

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './modal-details.component.html',
    styleUrls: ['./modal-details.component.scss'],
})
export class ModalDetailsComponent extends ModalEntry<ModalDetailsComponentParams, false> {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDetailsComponentParams) {
        super();
    }
}
