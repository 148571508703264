import { PANEL_DEFAULT_LANGUAGE, TranslateService } from 'app/utils/translate/translate.service';
import { AuthState } from 'app/services/auth-state';
import { AuthService } from 'app/services/auth';
import { Component } from '@angular/core';
import { Panel } from 'app/utils/panel';

@Component({
    templateUrl: './menu-profile.component.html',
    styleUrls: ['./menu-profile.component.scss'],
    selector: 'i360-menu-profile',
})
export class MenuProfileComponent {

    panelLang = PANEL_DEFAULT_LANGUAGE;

    constructor(
        public translate: TranslateService,
        public authState: AuthState,
        public authService: AuthService,
        public panel: Panel,
    ) {}
}
