import { Injectable, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AppState } from 'app/core/app.service';

@Injectable()
export class I360OverlayContainer extends OverlayContainer implements OnDestroy {
    constructor(
        private platform: Platform,
        private appState: AppState,
    ) {
        super(document, platform);
    }
    protected _createContainer() {
        if (this.appState.overlayContainer) {  // use one overlay for the whole app
            this._containerElement = this.appState.overlayContainer;
        } else {
            super._createContainer();
            const app = document.querySelector('.i360-app-outer');
            if (app) {
                app.appendChild(this._containerElement);
            }
            this.appState.overlayContainer = this._containerElement;
        }
    }

    // no-op, do not remove the container, because it is used by other SPAs too
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy() {}
}
