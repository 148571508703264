import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Subject, filter, takeUntil } from 'rxjs';
import { NavigationComponent } from 'app/utils/navigation/navigation.component';
import { NewsNotificationsComponent } from '../news-notifications/news-notifications.component';


@Component({
    selector: 'i360-news-overlay',
    template: `
        <div class="notification-events-container" *cdkPortal>
            <div class="notification-events-wrapper">
                <div class="notification-events">
                    <h3 translate>news.takeALook</h3>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./news-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NewsOverlayComponent implements AfterViewInit, OnDestroy {
    @ViewChild(CdkPortal, {static: false}) portal: CdkPortal;
    private overlayRef: OverlayRef;
    private destroyed = new Subject<void>();

    constructor(
        private overlay: Overlay,
        private newsNotificationsComponent: NewsNotificationsComponent,
        private navigationComponent: NavigationComponent,
        private router: Router,
    ) {}

    ngAfterViewInit(): void {
        const config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(this.navigationComponent.elementRef)
                .withLockedPosition()
                .withPositions([{
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                }]),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(this.portal);
        merge(
            this.overlayRef.backdropClick(),
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
        ).pipe(
            takeUntil(this.destroyed),
        ).subscribe(() => {
            this.newsNotificationsComponent.toggleOverlay();
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
        this.overlayRef.detach();
    }
}
