import { AgentStatus, Result } from '../misc';
import { license } from './misc';

export const responses = {
    serviceInstalling: {
        result: Result.SUCCESS,
        status: AgentStatus.INSTALLING,
        messages: [],
        data: undefined,
    },
    serviceFailedToInstall: {
        result: Result.SUCCESS,
        status: AgentStatus.FAILED_TO_INSTALL,
        messages: [],
        data: undefined,
    },
    serviceNotInstalled: {
        result: Result.SUCCESS,
        status: AgentStatus.NOT_INSTALLED,
        messages: [],
        data: undefined,
    },
    pieceOfLog: {
        result: Result.SUCCESS,
        status: AgentStatus.FAILED_TO_INSTALL,
        messages: [],
        data: {
            items: {
                offset: 0,
                log: 'log row #1\nlog row #2',
            },
        },
    },
};
