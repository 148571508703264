import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';


export interface NewsItem {
    title: string;
    pubDate: string;
    guid: string;
    link: string;
}

type GetNewsAgentResponse = AgentResponse<AgentResponseData<NewsItem[]>>;

export class NewsAPI {
    static getNews = () => new RequestParams<DataFrom<GetNewsAgentResponse>>(['get-news']);
}
