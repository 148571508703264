import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';

export enum RuleSet {
    FULL = 'FULL',
    MINIMAL = 'MINIMAL',
}

export type I360Conflicts = {
    [key: string]: boolean,
};

export enum ScheduleInterval {
    none = 'none',
    day = 'day',
    week = 'week',
    month = 'month',
}

export enum PortBlockingMode {
    allow = 'ALLOW',
    deny = 'DENY',
}

export class SettingsAPI {

    static conflicts = () => new RequestParams<AgentResponseData<I360Conflicts>>(['3rdparty', 'conflicts'])

    static show() {
        return new RequestParams<DataFrom<ConfigAgentResponse>>(['config', 'show']);
    }

    static save(params: Object) {
        return new RequestParams<DataFrom<ConfigAgentResponse>>(['config', 'patch'], {
            data: params,
            });
    }
}

export type I360ConfigDataItems = {
    [S: string]: {
        [K: string]: any;
    };
};

export type ConfigAgentResponse = AgentResponse<AgentResponseData<I360ConfigDataItems>>;

