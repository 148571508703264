<div class="i360-version">
        <ng-container *ngIf="(settingsService.packageVersions | async) as versionData; else appVersionBlock">
            <div
                 class="i360-package-versions"
                 matTooltip="{{ versionData?.packages }}"
                 matTooltipClass="version-tooltip">
                    <ng-container *ngTemplateOutlet="appVersionBlock"></ng-container> {{ 'menu.packageVersions' | translate: { count: versionData?.count - 1 } }}
            </div>
        </ng-container>
        <ng-template #appVersionBlock>v{{appVersion}}</ng-template>

</div>
