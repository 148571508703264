import { NgModule } from '@angular/core';
import { MenuProfileComponent } from 'app/components/menu-profile/menu-profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { HelpLinkComponent } from 'app/components/menu-profile/help-video/help-link';
import { I360ModalModule } from 'app/utils/modals/modal.module';
import { I360LoaderModule } from 'app/utils/loader/loader.module';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        TranslateModule,
        MatIconModule,
        I360ModalModule,
        I360LoaderModule,
    ],
    declarations: [
        HelpLinkComponent,
        MenuProfileComponent,
    ],
    exports: [
        HelpLinkComponent,
        MenuProfileComponent,
    ],
})
export class I360MenuProfileModule {

}
