import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

let counter = 0;
type ExtendedState<E> = {
    value: E,
    allowedValues?: any[],
    disabled?: boolean,
};
export type FormControlState<E> = ExtendedState<E>|E;
export class I360FormControl<E> extends UntypedFormControl {
    value: E;
    valueChanges: Observable<E>;
    i360DefaultValue: E;
    /**
     * cases:
     * 1. custom input disabling with ngIf (on-demand-scan => start => trigger advanced)
     * 2. ONLY disable validation if input hidden with ngIf (
     * lists => let dirty ip input => add country submit,
     * lists => let dirty ip input => trigger tabs => ip input has same value
     * disabled-rules => let dirty domains input => change plugin => submit (should not send
     * domains)
     */
    i360DisabledByUser;
    allowedValues: E[];
    readonly i360Id;
    constructor(state: FormControlState<E> | null, validators?, asyncValidators?) {
        let allowedValues;
        if (state && (state as any).allowedValues) {
            state = state as ExtendedState<E>;
            allowedValues = state.allowedValues;
            delete state.allowedValues;
            if (!state.disabled) {
                state = state.value;
            }
        }
        super(state, validators, asyncValidators);
        this.allowedValues = allowedValues;
        this.i360Id = counter++;
        this.i360DefaultValue = JSON.parse(JSON.stringify(state));
        this.disable({i360: true});
    }
    reset(value?: Partial<E>, options?) {
        super.reset(value || this.i360DefaultValue, options);
        this.setErrors(null);
    }
    disable(opts?) {
        if (!opts || !opts.i360) {
            this.i360DisabledByUser = true;
        }
        super.disable(opts);
    }
    enable(opts?) {
        if (!opts || !opts.i360) {
            this.i360DisabledByUser = false;
        }
        if (!this.i360DisabledByUser) {
            super.enable(opts);
        }
    }
}
