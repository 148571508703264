import { Injectable } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';
import { AuthCloudAPI } from '@imunify360-api/auth-cloud';
import { Observable, of, Subject, catchError, take, finalize } from 'rxjs';

@Injectable()
export class AuthCloudService {
    private iaid: string | undefined;
    private tokenRequest$: Observable<string | undefined> | null;

    constructor(
        private xhr: XHR,
    ) {}

    getIaidToken(): Observable<string | undefined> {
        if (this.iaid) {
            return of(this.iaid);
        }

        if (this.tokenRequest$) {
            return this.tokenRequest$;
        }

        const tokenRequest$ = new Subject<string | undefined>();
        this.tokenRequest$ = tokenRequest$;

        this.xhr.rx(AuthCloudAPI.getIaidToken)()
            .pipe(
                take(1),
                catchError(() => of(undefined)),
                finalize(() => {
                    this.tokenRequest$ = null;
                })
            ).subscribe(result => {
                this.iaid = result?.data?.items;
                tokenRequest$.next(this.iaid);
        });

        return tokenRequest$.asObservable();
    }
}
