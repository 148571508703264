import { DEFAULT_DATA } from './misc';
import { Vendor } from '../reputation';

export const responses = {
    infectedDomains: {
        data: {
            ...DEFAULT_DATA,
            max_count: 99,
            limit: 50,
            offset: 0,
            items: [
                {
                    username: 'bill',
                    domain: 'yyy_strange_domain_name.com',
                    threats: [{
                        type: 'MALWARE',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }],
                },
                {
                    username: 'john',
                    domain: 'john.snow',
                    threats: [{
                        type: 'MALWARE',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }, {
                        type: 'SOCIAL_ENGINEERING',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }, {
                        type: 'UNWANTED_SOFTWARE',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }, {
                        type: 'THREAT_TYPE_UNSPECIFIED',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }, {
                        type: 'POTENTIALLY_HARMFUL_APPLICATION',
                        vendor: Vendor.google,
                        timestamp: 1469692074,
                    }, {
                        type: 'MALWARE',
                        vendor: Vendor.yandex,
                        timestamp: 1469692074,
                    }, {
                        type: 'SOCIAL_ENGINEERING',
                        vendor: Vendor.yandex,
                        timestamp: 1469692074,
                    }, {
                        type: 'UNWANTED_SOFTWARE',
                        vendor: Vendor.yandex,
                        timestamp: 1469692074,
                    }, {
                        type: 'spam domain',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'phish domain',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'malware domain',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'botnet C&C domain',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'abused legit spam',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'abused spammed redirector domain',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'abused legit phish',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'abused legit malware',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'abused legit botnet C&C',
                        vendor: Vendor.spamhaus,
                        timestamp: 1469692074,
                    }, {
                        type: 'phish domain',
                        vendor: Vendor.phishtank,
                        timestamp: 1469692074,
                    }, {
                        type: 'phish domain',
                        vendor: Vendor.openphish,
                        timestamp: 1469692074,
                    }, {
                        type: null,
                        vendor: Vendor.openphish,
                        timestamp: 1469692074,
                    }, {
                        type: 'ANY',
                        vendor: Vendor.yandex,
                        timestamp: 1469692074,
                    }, {
                        type: 'ANY',
                        vendor: Vendor.openphish,
                        timestamp: 1469692074,
                    }, {
                        type: 'ANY',
                        vendor: Vendor.mitchellkrogza,
                        timestamp: 1469692074,
                    }, {
                        type: 'ANY',
                        vendor: 'new vendor',
                        timestamp: 1469692074,
                    }
                    ],
                },
            ],
        },
    } as any,
};
