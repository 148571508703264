import {
    AfterContentInit, Component, ContentChild,
    ElementRef, Input, OnChanges, Renderer2, ViewEncapsulation,
} from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: '[i360-button]',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="i360-button-root">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./button.component.scss'],
    host: { /* eslint-disable */
        '[attr.disabled]': 'disabled || null',
    },
})
export class I360ButtonComponent implements AfterContentInit, OnChanges {
    @ContentChild(MatPrefix, {static: true}) prefix: MatPrefix;
    @ContentChild(MatSuffix, {static: true}) suffix: MatSuffix;
    @ContentChild(MatIcon, {static: true}) icon: MatIcon;
    @Input() primary: boolean;
    @Input() round: boolean;
    @Input() disabled: boolean;
    constructor(focusMonitor: FocusMonitor,
                private renderer: Renderer2,
                private element: ElementRef) {
        focusMonitor.monitor(element, true);
    }
    ngAfterContentInit() {
        const el = this.element.nativeElement;
        if (this.prefix) {
            this.renderer.addClass(el, 'i360-prefixed-button');
        }
        if (this.suffix) {
            this.renderer.addClass(el, 'i360-suffixed-button');
        }
        if (this.icon && !this.prefix && !this.suffix) {
            this.renderer.addClass(el, 'i360-icon-button');
        }
        if (!this.icon && !this.prefix && !this.suffix) {
            this.renderer.addClass(el, 'i360-basic-button');
        }
        this.ngOnChanges();
    }

    ngOnChanges(): void {
        const el = this.element.nativeElement;
        if (this.primary) {
            this.renderer.addClass(el, 'i360-primary-color');
            this.renderer.removeClass(el, 'i360-basic-color');
        } else {
            this.renderer.addClass(el, 'i360-basic-color');
            this.renderer.removeClass(el, 'i360-primary-color');
        }
        if (this.round) {
            this.renderer.addClass(el, 'i360-round');
        } else {
            this.renderer.removeClass(el, 'i360-round');
        }
    }
}
