import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'app/utils/loader/loader.component';
import { LoaderDirective } from 'app/utils/loader/loader.directive';
import { SpinnerComponent } from 'app/utils/spinner/spinner.component';
import { SpinnerLoaderDirective } from 'app/utils/loader/spinner-loader.directive';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
    ],
    declarations: [
        LoaderComponent,
        LoaderDirective,
        SpinnerComponent,
        SpinnerLoaderDirective,
    ],
    exports: [
        LoaderDirective,
        SpinnerLoaderDirective,
        SpinnerComponent,
    ]
})
export class I360LoaderModule {}
