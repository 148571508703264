import {
    AgentResponse,
    DataFrom,
    ListResponseData,
    RequestParams,
    SucceededFailedAgentResponse,
} from './misc';
import { ResourceType } from './malware';


export class MaliciousAPI {

    static list = (params = {}) =>
        new RequestParams<DataFrom<MaliciousListAgentResponse>>
        (['malware', 'malicious', 'list'], params)

    static cleanupAll() {
        return new RequestParams(['malware',  'malicious', 'cleanup-all']);
    }

    static action({action, items, params = {}}: MaliciousActionInterface) {
        return new RequestParams<DataFrom<SucceededFailedAgentResponse>>(
            ['malware', 'malicious', action], {
            items: items.map(i => i.id),
            ...params,
        });
    }
}

export enum MaliciousFileStatus {
    cleanup_started = 'cleanup_started',
    cleanup_done = 'cleanup_done',
    cleanup_removed = 'cleanup_removed',
    cleanup_requires_myimunify_protection = 'cleanup_requires_myimunify_protection',
    quarantined = 'quarantined',
    restore_from_backup_started = 'restore_from_backup_started',
    cleanup_restore_started = 'cleanup_restore_started',
    cleanup_pending = 'cleanup_pending',
    found = 'found', // =infected
}
export type MdsResourceTypeMaliciousList = {
    resource_type: ResourceType;
    app_name?: string | null;
    db_host?: string | null;
    db_port?: string | null;
    db_name?: string | null;
    snippet?: string | null;
};

export type MaliciousItemInBackend = {
    id: number;
    username: string;
    file: string;
    created: number;
    type: string;
    status: MaliciousFileStatus;
    scan_type: string;
    cleaned_at: number | null;
    // unused
    size?: any;
    hash?: any;
    extra_data?: any;
    malicious?: any;
    scan_id?: any;
} & MdsResourceTypeMaliciousList;

// tslint:disable:variable-name
export class MaliciousItem {
    public id: number;
    public username: string;
    public resource_type: ResourceType;
    public file: string;
    public created: number;
    public type: string;
    public status: MaliciousFileStatus;

    // class can not implement type(
    // at least response validation will work
    public app_name?: string | null;
    public db_host?: string | null;
    public db_port?: string | null;
    public db_name?: string | null;
    public snippet?: string | null;
    // extra data
    public scan_type: string;
    public cleaned_at: number | null;

    // misc
    public readonly canExpand = true;

    constructor(data: MaliciousItemInBackend) {
        this.id = data.id;
        this.username = data.username;
        this.resource_type = data.resource_type;
        if (data.resource_type === ResourceType.db) {
            this.app_name = data.app_name;
            this.db_host = data.db_host;
            this.db_port = data.db_port;
            this.db_name = data.db_name;
            this.snippet = data.snippet;
        }
        this.file = data.file;
        this.created = data.created;
        this.type = data.type;
        this.status = data.status;
        this.scan_type = data.scan_type;
        this.cleaned_at = data.cleaned_at;
    }
}
// tslint:enable:variable-name


export interface MaliciousActionInterface {
    action: string;
    items: MaliciousItem[];
    params?: {};
}


interface MaliciousListResponseData extends ListResponseData<MaliciousItemInBackend> {
    malicious_count: number;
}

type MaliciousListAgentResponse = AgentResponse<MaliciousListResponseData>;
