import { Component, Input } from '@angular/core';

@Component({
    selector: 'i360-modal-items',
    template: `
        <div *ngFor="let item of items" class="item rtl-except">{{item}}</div>
    `,
    styleUrls: ['./items.component.scss'],
})
export class ModalItemsComponent {
    @Input() items: string[];
}
