<div class="notification {{ item.type }}"
     [@enterLeave]="state"
     (mouseenter)="onEnter()"
     (mouseleave)="onLeave()">

    <div class="title">{{item.title}}</div>
    <div class="content">{{item.content.slice(0, messageMaxLength)}}<span *ngIf="item.content.length > 98">...</span>
        <a class="i360-notifications-details-button"
           *ngIf="item.content.length > 98"
           translate
           (click)="showErrorDetails(item)">notifications.details</a>
    </div>
    <div class="icon"><i class="material-icons icon-link">{{ this.icons[item.type] }}</i></div>
    <div class="counter" *ngIf="item.counter!==1">{{ item.counter }}</div>
    <div class="close" (click)="close()">
        <button mat-icon-button><mat-icon>close</mat-icon></button>
    </div>
</div>