import { AdminContactsAPI } from '@imunify360-api/admin-contacts';
import { Injectable } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';


@Injectable()
export class AdminContactsService {
    constructor(public xhr: XHR) {}

    getEmails = this.xhr.rx(AdminContactsAPI.getEmails, false, false);
}
