import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { ZonedBehaviorSubject } from 'app/utils/rxjs-zoned';
import { I360Role } from './auth';

@Injectable()
export class AuthState {
    // Role related subjects
    loginChange: ZonedBehaviorSubject<boolean> = new ZonedBehaviorSubject(false);
    role: ZonedBehaviorSubject<I360Role> = new ZonedBehaviorSubject<I360Role>(I360Role.none);
    username: ZonedBehaviorSubject<string|undefined> = new ZonedBehaviorSubject<string|undefined>(undefined);
    // helpers, mostly for old code
    isAdmin: ZonedBehaviorSubject<boolean> = new ZonedBehaviorSubject<boolean>(false);
    isClient: ZonedBehaviorSubject<boolean> = new ZonedBehaviorSubject<boolean>(false);
    // can be used in templates as `authState.roles.none` to work with `role` subject directly, instead of `isAdmin`
    roles = I360Role;

    updateRole(role: I360Role, username?: string) {
        if (this.role.value === role && this.username.value === username) {
            return;
        }
        this.username.next(username);
        this.role.next(role);
        this.isAdmin.next(role === I360Role.admin);
        this.isClient.next(role === I360Role.client);
        this.loginChange.next(this.role.value !== I360Role.none);
    }

    get featuresDisabledCommands(): string[] {
        return ['/', IMUNIFY_PACKAGE, I360Role.client, 'features-disabled'];
    }

    getDisabledRouteCommands(availableRoute: Route): string[] {
        return ['/', IMUNIFY_PACKAGE, I360Role.client, availableRoute.path!];
    }

    get featuresDisabledRedirectCommands() {
        return ['/', IMUNIFY_PACKAGE, I360Role.client];
    }
}
