import { I360Validators } from '../validator/validators';
import { Mode } from '@imunify360-api/proactive-defense';
import { getAdminEnduserMalwareCommonConfig } from './malware-config';
import { ConfigTypeExtractingHelper, PermissionsType } from './misc';
import { I360FormBuilder } from 'app/utils/validator/form-builder';
import { Validators } from '@angular/forms';


export const getSchema = (formBuilder: I360FormBuilder) => ({
    CONTROL_PANEL: formBuilder.group({
        generic_user_notifications: formBuilder.control(true),
    }),
    MALWARE_SCANNING: formBuilder.group(getAdminEnduserMalwareCommonConfig(formBuilder)),
    BACKUP_RESTORE: formBuilder.group({
        max_days_in_backup: formBuilder.control(0,
            [I360Validators.integer(true), Validators.min(1)]),
    }),
    PROACTIVE_DEFENCE: formBuilder.group({
        mode: formBuilder.control<Mode>(null),
        blamer: formBuilder.control(false),
    }),
    ERROR_REPORTING: formBuilder.group({
        enable: formBuilder.control(true),
    }),
    MALWARE_CLEANUP: formBuilder.group({
        trim_file_instead_of_removal: formBuilder.control(true),
        keep_original_files_days: formBuilder.control(14,
            [I360Validators.integer(true), Validators.min(1)]),
    }),
    MY_IMUNIFY: formBuilder.group({
        enable: formBuilder.control(false),
        purchase_page_url: formBuilder.control<string>(''),
    }),
});


const a = () => (null as any as I360FormBuilder).group(getSchema(null as any));
export type UserConfigType = ConfigTypeExtractingHelper<typeof a> & PermissionsType;
