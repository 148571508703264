import { Injectable, Injector } from '@angular/core';
import { NotificationsService } from 'app/services/notifications';
import { throwError as _throw } from 'rxjs';

/**
 * @returns {string} JSON-like string with a lot of escaping when converting circular values,
 * but it is short
 */
function toString(this, deepness = 0) {
    return JSON.stringify(this, function replacer(key, value) {
        try {
            JSON.stringify(value);
            return value;
        } catch (e) {
            if (deepness >= 3) {
                return 'Circular';
            }
            let res = {};
            Object.keys(value).forEach(key => {
                res[key] = toString.call(value[key], deepness + 1);
            });
            return res;
        }
    });
}

function addToString(error) {
    Object.defineProperty(error, 'toString', {
        value: toString,
        enumerable: false,
    });
}

@Injectable({
    providedIn: 'root',
})
export class ErrorHandler {

    constructor(
        private injector: Injector,
        private notifications: NotificationsService,
    ) {}

    // for mock in test
    public _throw(error) {
        addToString(error);
        return _throw(error);
    }
}
