import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { throwError as _throw, Observable, catchError, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';

const truncateLongValues = data => {
    if (data && typeof data === 'object') {
        Object.entries(data).forEach(([key, value]: any[]) => {
            if (typeof value === 'string' && value.length > 100) {
                data[key] = value.slice(0, 100) + '*****';
            }
            if (Array.isArray(value) && value.length > 5) {
                value.length = 5;
                value.push('*****');
            }
            truncateLongValues(value);
        });
    }
    return data;
};
const captureRequest = data => {
    Raven.captureBreadcrumb({
        message: 'XHR started',
        category: 'action',
        data,
    });
};

const captureResponse = response => {
    const data = truncateLongValues(JSON.parse(JSON.stringify(response)));
    Raven.captureBreadcrumb({
        message: 'XHR finished',
        category: 'action',
        data,
    });
};

@Injectable()
export class SentryInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        captureRequest({
            requestBody: req.body,
            url: req.urlWithParams,
        });
        return next.handle(req).pipe(
            tap(res => {
                if (res instanceof HttpResponse) {
                    captureResponse({
                        responseBody: res.body,
                        requestBody: req.body,
                        url: req.urlWithParams,
                    });
                }
            }),
            catchError(e => {
                captureResponse(e);
                Raven.captureException(e);
                return _throw(e);
            }),
        );
    }
}
