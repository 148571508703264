<i360-navigation #navigation
                 *ngIf="(userRole !== authState.roles.none) && (appState.imunifyStatus|async) === AgentStatus.OK">
    <ng-container *ngIf="imunifyPackage === '360'">
        <a (click)="navigateToTab('')"
           attr.aria-label="{{ 'menu.logo' | translate}}"
           *i360Template="'logo'"
           data-element="test-imunify360-logo"
           class="i360-logo">
            <!-- Show logo only if the myimunify state was loaded -->
            <ng-container *ngIf="myImunifyService.myImunifyStateLoaded$ | async">
                <mat-icon svgIcon="logo-myimunify"
                          *ngIf="isClient && myImunifyState?.enabled; else standardLogo">
                </mat-icon>
                <ng-template  #standardLogo>
                    <mat-icon svgIcon="logo-imunify"></mat-icon>
                </ng-template>
            </ng-container>
        </a>
        <ng-container *ngIf="isClient && myImunifyState?.enabled && !myImunifyState.protection">
            <button i360-button
                    [primary]="true"
                    class="upgrade-button"
                    (click)="openPromoModal()"
                    *i360Template="'button'">
                {{ 'menu.cleanUp' | translate }}
            </button>
        </ng-container>
        <ng-container *ngIf="!((licenseState.license|async)?.isFailed()) && !(licenseState.eulaFailed|async) && (appState.rpcReady$|async)">
            <ng-container *ngIf="isAdmin">
                <a (click)="navigateToTab( 'dashboard')"
                   [ngClass]="{ 'active': isActive( 'dashboard') }"
                   data-element="dashboard-tab-link"
                   *i360Template>
                    {{ 'menu.dashboard' | translate }}
                </a>
                <a (click)="navigateToTab('incidents')"
                   [ngClass]="{ 'active': isActive('incidents') }"
                   data-element="incidents-tab-link"
                   *i360Template>
                    {{ 'menu.incidents' | translate }}
                </a>
                <ng-container *ngIf="firewallService.isFirewallEnabled()|async">
                    <a (click)="navigateToTab('firewall')"
                       [ngClass]="{ 'active': isActive('firewall') }"
                       data-element="firewall-tab-link"
                       *i360Template>
                        {{ 'menu.firewall' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="emailsService.imunifyEmailExist()">
                    <a (click)="navigateToTab('email')"
                       [ngClass]="{ 'active': isActive('email') }"
                       data-element="email-tab-link"
                       *i360Template>
                        {{ 'menu.email' | translate }}
                    </a>
                </ng-container>
                <a (click)="navigateToTab('malware')"
                   [ngClass]="{ 'active': isActive('malware') }"
                   data-element="malware-tab-link"
                   *i360Template>
                    {{ 'menu.malwareScanner' | translate }}
                </a>
                <a (click)="navigateToTab('proactive-defense')"
                   [ngClass]="{ 'active': isActive('proactive-defense') }"
                   data-element="proactive-defense-tab-link"
                   *i360Template>
                    {{ 'menu.proactiveDefense' | translate }}
                </a>
                <a (click)="navigateToTab('reputation-management')"
                   [ngClass]="{ 'active': isActive('reputation-management') }"
                   data-element="reputation-management-tab-link"
                   *i360Template>
                    {{ 'menu.reputationManagement' | translate }}
                    <span class="i360-nav-badge"
                          (cdkObserveContent)="navigation.forceNormalize.next()"
                          *ngIf="requestsState.infectedDomainsCount.cache|async">
                        {{ requestsState.infectedDomainsCount.cache|async }}
                    </span>
                </a>
                <a (click)="navigateToTab('kernelcare')"
                   [ngClass]="{ 'active': isActive('kernelcare') }"
                   data-element="kernelcare-tab-link"
                   *i360Template>
                    {{ 'menu.kernelCare' | translate }}
                </a>
                <ng-template i360Template="right">
                    <i360-news></i360-news>
                </ng-template>
                <ng-container *ngIf="support|async">
                    <ng-template i360Template="right">
                        <a attr.aria-label="{{ 'menu.support' | translate }}"
                           [ngClass]="{ 'active': isActive('support') }"
                           (click)="navigateToTab('support')"
                           data-element="support-tab-link">
                            <mat-icon class="support" svgIcon="support"></mat-icon>
                        </a>
                    </ng-template>
                </ng-container>
                <a class="settings-button"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   *i360Template="'right'"
                   [ngClass]="{ 'active': isActive('settings') }"
                   (click)="navigateToTab('settings')"
                   data-element="settings-tab-link">
                    <i class="material-icons hidden-xs">settings</i>
                </a>
            </ng-container>
            <ng-container *ngIf="isClient">
                <ng-container *ngIf="requestsState.hasAv|async">
                    <a [ngClass]="{ 'active': isActive('malware') }"
                       (click)="navigateToTab('malware')"
                       data-element="malware-tab-link"
                       *i360Template>
                        {{ 'menu.malwareScanner' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="emailsService.imunifyEmailExist()">
                    <a (click)="navigateToTab('email')"
                       [ngClass]="{ 'active': isActive('email') }"
                       data-element="email-tab-link"
                       *i360Template>
                        {{ 'menu.email' | translate }}
                    </a>
                </ng-container>
                <ng-container *ngIf="requestsState.hasProactive|async">
                    <a (click)="navigateToTab('proactive-defense')"
                       [ngClass]="{ 'active': isActive('proactive-defense') }"
                       data-element="proactive-defense-tab-link"
                       *i360Template>
                        {{ 'menu.proactiveDefense' | translate }}
                    </a>
                </ng-container>
                <a class="settings-button"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   *i360Template="'right'"
                   [ngClass]="{ 'active': isActive('settings') }"
                   (click)="navigateToTab('settings')"
                   data-element="settings-tab-link">
                    <i class="material-icons hidden-xs">settings</i>
                </a>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="imunifyPackage === 'AV'">
        <a (click)="navigateToTab('')"
           attr.aria-label="{{ 'menu.logo' | translate }}"
           *i360Template="'logo'"
           class="i360-logo"
           data-element="test-imunifyAV-logo"
           [class.imunifyav-logo]="(licenseState.license|async)?.product === Product.imunifyAV"
           [class.imunifyavplus-logo]="(licenseState.license|async)?.product === Product.imunifyAVPlus">
            <mat-icon svgIcon="logo-imunifyAV{{(licenseState.license|async)?.product === Product.imunifyAVPlus ? 'Plus' : ''}}">
            </mat-icon>
        </a>

        <ng-container *ngIf="!licenseState.eula.value.isFailed() && (appState.rpcReady$|async)">
            <a [ngClass]="{ 'active': isActive('malware') }"
               (click)="navigateToTab('malware')"
               data-element="malware-tab-link"
               *i360Template>
                {{ 'menu.malwareScanner' | translate }}
            </a>
            <ng-container *ngIf="isAdmin">
                <a [ngClass]="{ 'active': isActive( 'reputation-management') }"
                   (click)="navigateToTab( 'reputation-management')"
                   data-element="reputation-management-tab-link"
                   *i360Template>
                    {{ 'menu.reputationManagement' | translate }}
                    <ng-container *ngIf="!(licenseState.license|async)?.isFreeVersion">
                        <span class="i360-nav-badge"
                              *ngIf="requestsState.infectedDomainsCount.cache|async"
                              (cdkObserveContent)="navigation.forceNormalize.next()">
                            {{ requestsState.infectedDomainsCount.cache|async }}
                        </span>
                    </ng-container>
                </a>

                <ng-container *ngIf="firewallService.isFirewallEnabled()|async">
                    <a [ngClass]="{ 'active': isActive( 'firewall') }"
                       (click)="navigateToTab( 'firewall')"
                       data-element="firewall-tab-link"
                       *i360Template>
                        {{ 'menu.firewall' | translate }}
                    </a>
                </ng-container>

                <a (click)="navigateToTab('proactive-defense')"
                   [ngClass]="{ 'active': isActive('proactive-defense') }"
                   data-element="proactive-defense-tab-link"
                   *i360Template>
                    {{ 'menu.proactiveDefense' | translate }}
                </a>

                <a (click)="navigateToTab('kernelcare')"
                   [ngClass]="{ 'active': isActive('kernelcare') }"
                   data-element="kernelcare-tab-link"
                   *i360Template>
                    {{ 'menu.kernelCare' | translate }}
                </a>

                <i360-news *i360Template="'right'"></i360-news>
                <a attr.aria-label="{{ 'menu.support' | translate }}"
                   *i360Template="'right'"
                   [ngClass]="{ 'active': isActive('support') }"
                   (click)="navigateToTab('support')"
                   data-element="support-tab-link">
                    <mat-icon class="support" svgIcon="support"></mat-icon>
                </a>

                <a class="settings-button"
                   [ngClass]="{ 'active': isActive('settings') }"
                   (click)="navigateToTab('settings')"
                   attr.aria-label="{{ 'menu.settings' | translate }}"
                   data-element="settings-tab-link"
                   *i360Template="'right'">
                    <i class="material-icons hidden-xs">settings</i>
                </a>

                <ng-container *ngIf="(licenseState.license|async)?.upgradeAvailable && (config.changes|async)?.PERMISSIONS.upgrade_button">
                    <button i360-button
                            i360UpgradeButton="menu.upgrade"
                            [round]="true"
                            [primary]="true"
                            class="upgrade-button"
                            [ngClass]="{ 'active': isActive('upgrade') }"
                            (click)="navigateToTab('upgrade')"
                            *i360Template="'button'">
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container>
        <i360-menu-profile *i360Template="'right'"></i360-menu-profile>
    </ng-container>
</i360-navigation>

<notifications></notifications>
