import { Injectable } from '@angular/core';
import { HistoryAPI, MalwareHistoryItem } from '@imunify360-api/history';
import { of, timer, Observable, Subject, debounceTime, map, switchMap, tap } from 'rxjs';
import { XHR } from 'app/utils/xhr/xhr';

export const HISTORY_COUNTER_KEY = 'history-counter';
const HISTORY_COUNT_REQUEST_INTERVAL = 20000;

@Injectable()
export class HistoryService {
    constructor(public xhr: XHR) {}

    test = this.xhr.rx(HistoryAPI.list);
    list = this.xhr.wrap(this.xhr.rx(HistoryAPI.list), MalwareHistoryItem);
    public updateHistoryCount = new Subject<void>();
    public historyCount?: Observable<number | undefined> = this.updateHistoryCount.pipe(
        debounceTime(100),
        switchMap(ev => {
            const time = HISTORY_COUNT_REQUEST_INTERVAL + this.last - Date.now();
            if (time > 0) {
                return timer(time);
            } else {
                return of(null);
            }
        }),
        tap(() => {
            this.last = Date.now();
        }),
        switchMap(() => {
            return this.list({limit: 1, since: this._getHistorySinceFromStorage() + 1});
        }),
        map(response => {
            return response.data.max_count;
        }),
    );
    private last: number = 0;

    getHistory = (arg) => {
        return this.list(arg).pipe(
            tap((response) => {
                let max = Math.max(this._getHistorySinceFromStorage(),
                    ...response.data.items.map(item => item.ctime));
                if (max > this._getHistorySinceFromStorage()) {
                    localStorage.setItem(HISTORY_COUNTER_KEY, String(max));
                    this.last = 0;
                    this.updateHistoryCount.next();
                }
            }),
        );
    }

    updateCounter() {
        this.updateHistoryCount.next();
    }

    _getHistorySinceFromStorage() {
        return Number(localStorage.getItem(HISTORY_COUNTER_KEY) || '0');
    }
}
