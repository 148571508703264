import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Notification } from 'app/core/app.service';
import { NotificationsService } from 'app/services/notifications';
import { useLoader } from 'app/utils/loader/decorators';
import { ModalService } from 'app/utils/modals/modal.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModalDetailsComponent } from 'app/utils/modals/modal-details/modal-details.component';

const ANIMATE_TIME = 200;
const STATE_ACTIVE = 'active';
const STATE_INACTIVE = 'inactive';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [
        trigger('enterLeave', [
            state(STATE_ACTIVE, style({opacity: 1, transform: 'translateX(0)'})),
            transition('* => ' + STATE_ACTIVE, [
                style({opacity: 0, transform: 'translateX(-5%)'}),
                animate(ANIMATE_TIME),
            ]),
            state(STATE_INACTIVE, style({opacity: 0, transform: 'translateX(5%)'})),
            transition('* => ' + STATE_INACTIVE, [
                style({opacity: 1, transform: 'translateX(0)'}),
                animate(ANIMATE_TIME),
            ]),
        ]),
    ],
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() item: Notification;

    icons = {
        error: 'info_outline',
        success: 'done',
    };

    state: string;
    stopTime: boolean = false;
    timer: number;
    speed: number = 100;
    timeOut: number = 5000;
    messageMaxLength: number = 98;

    constructor(
        private service: NotificationsService,
        public modalService: ModalService,
    ) {}

    @HostListener('mouseup', ['$event']) onClick(event) {
        event.stopPropagation();
    }

    ngOnInit(): void {
        this.state = STATE_ACTIVE;
        if (this.timeOut !== 0 && this.itemHasTimeout()) {
            this.item.ticks = 0;
            this.setTimer();
        }
    }

    itemHasTimeout() {
        return !['error'].includes(this.item.type);
    }

    onEnter(): void {
        if (this.itemHasTimeout()) {
            this.stopTime = true;
        }
    }

    onLeave(): void {
        if (this.itemHasTimeout()) {
            this.stopTime = false;
            this.setTimer();
        }
    }

    ngOnDestroy(): void {
        clearTimeout(this.timer);
    }

    setTimer() {
        const tick = () => {
            if (this.item.ticks++ > this.timeOut / this.speed) {
                this.close();
            } else if (!this.stopTime) {
                this.setTimer();
            }
        };

        this.timer = window.setTimeout(tick, this.speed);
    }

    close() {
        this.state = STATE_INACTIVE;
        setTimeout(() => this.service.remove(this.item), ANIMATE_TIME);
    }

    @useLoader
    public async showErrorDetails(details: Notification) {
        this.close();
        const panelClass = 'i360-details-panel-' + details.type;
        let options: MatDialogConfig = {
            data: {details},
            panelClass: panelClass};
        return this.modalService.open(ModalDetailsComponent, options);
    }
}
