import { Component } from '@angular/core';
import { AppState } from 'app/core/app.service';

@Component({
    selector: 'notifications',
    styleUrls: ['./notifications.component.scss'],
    template: `
        <div class="abs">
            <div class="fix">
                <notification *ngFor="let i of (appState.notifications|async);" [item]="i"></notification>
            </div>
        </div>
    `,
})
export class NotificationsComponent {
    constructor(public appState: AppState) {}
}
