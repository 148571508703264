import {
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { ErrorComponent } from 'app/utils/validator/error.component/error.component';

@Directive({
    selector: '[formControlName]',
    exportAs: 'formControlName',
})
export class ExtendFormControlNameDirective implements OnInit, OnDestroy {
    @Input() i360Errors = true;

    private errorRef: ComponentRef<ErrorComponent> | null;

    get value() {
        return this.control.value;
    }
    set value(value) {
        this.control.control.setValue(value);
    }
    constructor(
        private control: FormControlName,
        private detector: ChangeDetectorRef,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnInit() {
        if (!this.control.value) {
            this.control.control.markAsPristine();
        }
        if (this.control.statusChanges) {
            this.control.statusChanges.pipe(debounceTime(20)).subscribe(() => this.renderError());
        }
        Promise.resolve().then(() => {
            this.control.control.enable(<any>{i360: true});
        });
    }

    renderError() {
        if (this.i360Errors) {
            if (this.control.invalid && !this.errorRef && this.control.dirty) {
                this.errorRef = this.viewContainer.createComponent(ErrorComponent);
                this.errorRef.instance.control = this.control;
                this.detector.markForCheck();
            } else {
                if ((this.control.valid || this.control.pristine) && this.errorRef) {
                    this.errorRef.destroy();
                    this.errorRef = null;
                }
            }
        }
    }
    async ngOnDestroy() {
        if (this.errorRef) {
            this.errorRef.destroy();
        }
        await Promise.resolve();
        if (this.control.control) {
            this.control.control.disable(<any>{i360: true});
        }
    }
}
