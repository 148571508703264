import { I360Validators } from '../validator/validators';
import { I360FormBuilder } from 'app/utils/validator/form-builder';

export enum DefaultAction {
    notify = 'notify',
    cleanup = 'cleanup',
}

export const getAdminEnduserMalwareCommonConfig = (formBuilder: I360FormBuilder) => {
    return {
        try_restore_from_backup_first: formBuilder.control(false),
        default_action: formBuilder.control<DefaultAction | null>({
            value: null,
            allowedValues: [{
                value: DefaultAction.notify,
                title: 'settings.malware.general.defaultAction.options.notify',
                experimental: false,
                removed: false,
            }, {
                value: DefaultAction.cleanup,
                title: 'settings.malware.general.defaultAction.options.cleanup',
                experimental: false,
                removed: false,
            }],
        }, I360Validators.required('default_action')),
    };
};
