import {
    AgentResponse,
    AgentResponseData,
    DataFrom,
    ListResponseData,
    RequestParams,
} from './misc';
import { BlockedRule, RulePlugin } from './blocked-rules';
import { toIpListOrNull, Country, IPList } from './lists';


export class IncidentsAPI {
    static incidents = (params = {}) => new RequestParams<DataFrom<IncidentsAgentResponse>>
    (['get'], params)
}


const severity = {
    [RulePlugin.modsec]: {
        low: [5, 7],
        medium: [4, 4],
        high: [0, 3],
    },
    [RulePlugin.ossec]: {
        low: [0, 3],
        medium: [4, 10],
        high: [11, 15],
    },
};


function getSeverityIconClass(incident: Incident) {
    if (incident.plugin in severity) {
        return Object.keys(<any>severity[<any>incident.plugin]).find(key => {
            let [min, max] = <any>severity[<any>incident.plugin][key];
            if (incident.severity === null) {
                return false;
            }
            return min <= incident.severity && max >= incident.severity;
        }) || 'hidden';
    }
    return 'hidden';
}

interface IncidentInBackend {
    abuser: string | null;
    country: Partial<Country> | null; // why not string | null
    description: string;
    id: number;
    listname: IPList | null;
    name: string;
    plugin: RulePlugin;
    times: number;
    rule: string | null;
    severity: number | null;
    timestamp: number;
    purpose?: IPList | null;
    domain: string | null;
}

export class Incident {
    public id: number;
    public name: string;
    public abuser: string | null;
    public description: string;
    public plugin: RulePlugin;
    public times: number;
    public rule: string | null;
    public severity: number | null;
    public severityIconClass: string;
    public country: Partial<Country>;
    public purpose: IPList | null;
    public listname: IPList | null;
    public timestamp: any;
    public canExpand = true;
    public domain: string | null;

    // additional params
    constructor(data: IncidentInBackend) {
        this.abuser = data.abuser;
        this.country = data.country || {code: ''};
        this.description = data.description;
        this.id = data.id;
        this.listname = toIpListOrNull(data.listname);
        this.name = data.name;
        this.plugin = data.plugin;
        this.times = data.times;
        this.rule = data.rule;
        this.purpose = (data.purpose) ? data.purpose : null;
        this.severity = data.severity;
        this.timestamp = data.timestamp;
        this.severityIconClass = getSeverityIconClass(this);
        this.domain = data.domain;
    }

    getRule(): BlockedRule {
        return new BlockedRule({
            id: this.rule!,
            name: this.name,
            plugin: this.plugin,
            domains: '',
        });
    }
}
type IncidentsAgentResponse = AgentResponse<ListResponseData<IncidentInBackend>>;
