import { AgentResponse,
    DataFrom,
    ListResponseData,
    RequestParams,
    ResponseDataExceptItems } from './misc';
import { OldListCountsResponse } from './blocked-ports';

// FIXME: arguments are a bit inconsistent

export class ListsAPI {

    static getAllList({params = {}}: {params?: object}) {
        let method = ['ip-list', 'local', 'list'];
        return new RequestParams<DataFrom<IPListLocalAgentResponse>>(method, {
            ...params,
        });
    }

    static addOrUpdateItem({list, type, value, comment, scope, expiration, fullAccess}: {
        list: IPList | null;
        type?: ItemType;
        value: string | string[];
        comment?: string;
        scope?: string;
        expiration?: number;
        fullAccess?: boolean;
    }) {
        type = type || 'ip';
        list = type !== "country" && list === IPList.black ? IPList.drop : list;
        // TODO incident part show old list names
        const method = type === "country"  && list
            ?  [IPListMix[list] + 'list', type, 'add']
            : [ 'ip-list', 'local', 'add'];
        return new RequestParams(
            method,
            {
                items: typeof value === 'string' ? [value] : value,
                ...(comment ? {comment} : {}),
                ...(scope ? {scope} : {}),
                ...(expiration ? {expiration} : {}),
                ...(list === IPList.white ? {full_access: fullAccess} : {}),
                ...(type === "ip" && list ? {purpose: list} : {}),
            },
        );
    }

    static deleteItems(
        {items, itemType, list}: {items: string[], itemType: ItemType, list: IPList | null},
    ) {
        list = itemType === "country" && list ? IPListMix[list] : list;
        const method = itemType === "ip"
            ? ['ip-list', 'local', 'delete']
            : [list + 'list', itemType, 'delete'];
        return new RequestParams(method, {
            items,
            ...(itemType === "ip" && list ? {purpose: list} : {}),
        });
    }

    static editItem({items, list, comment, type, scope, expiration, fullAccess}: {
        items: string[];
        list: IPList | null;
        comment?: string;
        type: string;
        scope?: string;
        expiration?: number | null;
        fullAccess?: boolean;
    }) {
        return new RequestParams<ResponseDataExceptItems>(
            [(list ? IPListMix[list] : null) + 'list', type, 'edit'],
            {
                items,
                ...(comment !== undefined ? {comment} : {}),
                ...(expiration !== undefined ? {expiration} : {}),
                ...(scope ? {scope} : {}),
                ...(list === IPList.white ? {full_access: fullAccess} : {}),
            });
    }
}


export type ItemType = 'ip' | 'country';

export interface Country {
    code: string;

    // unused
    name: string;
    id?: string;
}

export enum IPScope {
    local = 'local',
    group = 'group',
}

export enum IPListMix {
    drop = 'black',
    black = 'black',
    white = 'white',
    captcha = 'gray',
    splashscreen = 'gray',
}

export enum IPList {
    drop = 'drop',
    white = 'white',
    captcha = 'captcha',
    splashscreen = 'splashscreen',

    // for incident and proactive defense
    black = 'black',
    gray = 'gray',
}

export const toIPList = (list: string): IPList => {
    list = list.toLowerCase();
    if (list in IPList) {
        return list as IPList;
    } else {
        throw new Error(`Unknown list ${list}`);
    }
};
export const toIpListOrNull = (list: string | null): IPList | null => {
    return list ? toIPList(list) : null;
};

// FIXME: it should be IPListItem and CountryListItem
export class ListItem {
    public ip: any;
    public list: IPList | null;
    public checked: boolean = false;
    public importedFrom: string | null;
    public scope: IPScope | null;
    public comment: string;
    public country: Partial<Country>;
    public fullAccess: boolean = false;
    public autoWhitelisted: boolean | undefined = false;
    public expiration: number | null;
    public isCountry: boolean;
    public type: ItemType;

    constructor(item: ResponseItem) {
        this.ip = item.ip;
        this.scope = item.scope || null;
        this.importedFrom = item.imported_from || null;
        this.list = toIpListOrNull(item.purpose);
        this.comment = item.comment || '';
        this.fullAccess = Boolean(item.full_access);
        this.autoWhitelisted = Boolean(item.auto_whitelisted);
        this.expiration = item.expiration || 0;  // TTL
        this.country = item.country || {code: '', name: ''};
        this.isCountry = !this.ip && Boolean(this.country.code);
        this.type = this.isCountry ? 'country' : 'ip';
    }
}

// FIXME: too many question marks
interface ResponseItem {
    imported_from?: string | null;
    ip?: string;
    scope?: IPScope | null;
    purpose: string | null; // responses are uppercase, but in incidents it is lowercase
    auto_whitelisted?: boolean | null;
    comment: string | null;
    country: Partial<Country> | null;
    full_access?: boolean | null;
    expiration?: number | null;

    // unused
    deep?: number | null;
    ctime?: number;
    network_address?: number;
    netmask?: number;
    version?: number;
    manual?: boolean;
}

interface ResponseOldListItem {
    listname: string | null;
    imported_from?: string | null;
    ip?: string;
    scope?: IPScope | null;
    auto_whitelisted?: boolean | null;
    comment: string | null;
    country: Partial<Country> | null;
    full_access?: boolean | null;
    expiration?: number | null;
    deep?: number | null;
    ctime?: number;
    network_address?: number;
    netmask?: number;
    version?: number;
    manual?: boolean;
}

export interface CountsListResponseData<E> extends ListResponseData<E> {
    counts: {
        cloud: {
            white: number,
            captcha: number,
            drop: number,
            splashscreen: number,
        },
        server: {
            white: number,
            captcha: number,
            drop: number,
            splashscreen: number,
        }
    };
}

export interface ListsResponseData extends CountsListResponseData<ResponseItem> {}
export interface OldListsResponseData extends OldListCountsResponse<ResponseOldListItem> {}

type ListsAgentResponse = AgentResponse<OldListsResponseData>;
type IPListLocalAgentResponse = AgentResponse<ListsResponseData>;

export type GroupServerIdResponseInServer = {
    status: string,
    group_id: string | null,
};
