import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ModalEntry } from 'app/utils/modals/entry';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyImunifyService } from 'app/services/my-imunify';

interface ModalDetailsComponentParams {
    details: string;
}

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './protection-benefits.component.html',
    styleUrls: ['./protection-benefits.component.scss'],
})
export class ProtectionBenefitsComponent extends ModalEntry<ModalDetailsComponentParams, false> {

    // Here all needed items from the translations json
    manualCleanupItems = [
        'utils.modals.myImunifyUpsell.manualCleanup.item_1',
        'utils.modals.myImunifyUpsell.manualCleanup.item_2',
        'utils.modals.myImunifyUpsell.manualCleanup.item_3',
        'utils.modals.myImunifyUpsell.manualCleanup.item_4',
    ];

    risksItems = [
        'utils.modals.myImunifyUpsell.risks.item_1',
        'utils.modals.myImunifyUpsell.risks.item_2',
        'utils.modals.myImunifyUpsell.risks.item_3',
        'utils.modals.myImunifyUpsell.risks.item_4',
        'utils.modals.myImunifyUpsell.risks.item_5',
        'utils.modals.myImunifyUpsell.risks.item_6',
        'utils.modals.myImunifyUpsell.risks.item_7',
        'utils.modals.myImunifyUpsell.risks.item_8',
        'utils.modals.myImunifyUpsell.risks.item_9',
    ];

    autoCleanupItems = [
        'utils.modals.myImunifyUpsell.autoCleanup.item_1',
        'utils.modals.myImunifyUpsell.autoCleanup.item_2',
        'utils.modals.myImunifyUpsell.autoCleanup.item_3',
        'utils.modals.myImunifyUpsell.autoCleanup.item_4',
        'utils.modals.myImunifyUpsell.autoCleanup.item_5',
    ];

    benefitsItems = [
        'utils.modals.myImunifyUpsell.benefits.item_1',
        'utils.modals.myImunifyUpsell.benefits.item_2',
        'utils.modals.myImunifyUpsell.benefits.item_3',
        'utils.modals.myImunifyUpsell.benefits.item_4',
        'utils.modals.myImunifyUpsell.benefits.item_5',
        'utils.modals.myImunifyUpsell.benefits.item_6',
        'utils.modals.myImunifyUpsell.benefits.item_7',
        'utils.modals.myImunifyUpsell.benefits.item_8',
        'utils.modals.myImunifyUpsell.benefits.item_9',
        'utils.modals.myImunifyUpsell.benefits.item_10',
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalDetailsComponentParams,
        public myImunifyService: MyImunifyService
    ) {
        super();
    }
}
