export const noSentryErrorMessage: string = 'noSentryErrorMessage';

export class NoSentryError extends Error {
    noSentry = true;
    constructor() {
        super(noSentryErrorMessage);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NoSentryError.prototype);
    }
}
