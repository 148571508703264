import { WindowInterface } from '@imunify360-api/misc';

declare var window: WindowInterface;

export const testMode = (serviceName: string): boolean => {
    if (!TEST) {
        return false;
    }

    const localStorageMode = JSON.parse(localStorage.getItem('I360_TEST_MODE') || 'false');
    const testMode = localStorageMode || window.i360Test.testMode;

    if (!testMode) {
        return false;
    }

    // enable for specific services
    if (testMode[serviceName]) {
        return true;
    }

    // some services can be in testMode only explicit
    if (['EulaService', 'LicenseService'].includes(serviceName)) {
        return false;
    }

    // global
    return testMode === true;
};
