import { AgentResponse, DataFrom, ListResponseData, RequestParams } from './misc';

export enum RulePlugin {
    ossec = 'ossec',
    modsec = 'modsec',
    lfd = 'lfd',
    cphulk = 'cphulk',
    unknown = 'unknown',
    cl_dos = 'cl_dos',
    enhanced_dos = 'enhanced_dos',
}

export class BlockedRulesAPI {

    static list = (params: Object) =>
        new RequestParams<DataFrom<BlockedRulesAgentResponse>>
        (['rules', 'list-disabled'], params)

    static enable(rule: BlockedRule) {
        return new RequestParams(['rules', 'enable'], {
            id: rule.id,
            plugin: rule.plugin,
        });
    }

    static disable(rule: BlockedRule) {
        return new RequestParams(['rules', 'disable'], {
            id: rule.id,
            plugin: rule.plugin,
            name: rule.name,
            domains: rule.domains,
        });
    }
}

interface BlockedRuleInBackend {
    domains?: string[] | string | null;
    id: string;
    name: string;
    plugin: RulePlugin;
}

export class BlockedRule {
    id: string;
    plugin: RulePlugin;
    name: string;
    domains: string[];

    constructor({id, plugin, name, domains}: BlockedRuleInBackend) {
        this.id = id;
        this.plugin = plugin;
        this.name = name;
        this.setDomains(domains);
    }

    domainsAllowed() {
        return this.plugin === 'modsec';
    }

    setDomains(domains?: string | string[] | null) {
        if (!domains) {
            this.domains = [];
            return;
        }
        if (Array.isArray(domains)) {
            this.domains = domains;
            return;
        }
        if ('string' === typeof domains) {
            this.domains = domains.split(/[,\n\s]+/);
        }
    }
}

type BlockedRulesAgentResponse = AgentResponse<ListResponseData<BlockedRuleInBackend>>;
