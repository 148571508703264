import {
    AgentResponse, AgentResponseData,
    DataFrom, ExtendedRequestParams,
    ListResponseData, NullAgentResponse,
} from './misc';
import { requestCommandIE, requestMethod } from './emails';

export function cutOrderByParam(params) {
    let newParams = JSON.parse(JSON.stringify(params));
    if (newParams && newParams['order_by']) {
        newParams['order_by'] = newParams['order_by'][0];
    }
    return newParams;
}

export enum AccountStatus {
    active = 'active',
    inactive = 'inactive',
}

interface ServerSpace {
    total_space: number;
    account_space: number;
}

interface ServerDefaultsLimits {
    limit_mode: number;
    account: number;
    domain: number;
    sender_email: number;
    script: number;
}

export interface AccountDefaultSettingsUI {
    limit_bytes: number | null;
    releases_limit: number | null;
}

export type AccountSetting = {
    setting: string;
    int_value: number;
};


export interface IEDefaultAccountSettings {
    settings: AccountSetting[]
}

export interface ResponseEmailSettingsItem {
    name: string;
    limit_bytes: number;
    used_bytes: number;
    releases_limit: number;
    state: AccountStatus;
    description: string;
}


export class AccountItem {
    public name: string;
    public limitBytes: number;
    public state: AccountStatus;
    public description: string;
    public usedBytes: number;
    public releasesLimit: number;

    constructor(item: ResponseEmailSettingsItem) {
        this.name = item.name;
        this.limitBytes = item.limit_bytes;
        this.description = item.description;
        this.usedBytes = item.used_bytes;
        this.state = item.state;
        this.releasesLimit = item.releases_limit;
    }
}


export class EmailsSettingsApis {

    static list = (params) =>  <ExtendedRequestParams<DataFrom<AccountListResponseData>>> {
        command: requestCommandIE,
        method: ['accounts'],
        params: {
            ...cutOrderByParam(params),
            request_method: requestMethod.get,
        },
    }

    static updateAccount = ({limit_bytes, userName, account_name, releases_limit}: {
        limit_bytes?: number,
        releases_limit?: number,
        userName: string,
        account_name?: string,
    }) => <ExtendedRequestParams<DataFrom<AccountResponseData>>> {
        command: requestCommandIE,
        method: ['accounts', userName, 'settings'],
        params: {
            request_method: requestMethod.put,
            ...(account_name ? {account_name} : {}),
            ...(limit_bytes ? {limit_bytes} : {}),
            ...(releases_limit ? {releases_limit} : {}),
        },
    }

    static getAccountSettings = () => <ExtendedRequestParams<DataFrom<ServerSpaceResponse>>> {
        command: requestCommandIE,
        method: ['accounts', '{account_name}', 'settings'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static getLimits = () => <ExtendedRequestParams<DataFrom<ServerLimitResponse>>> {
        command: requestCommandIE,
        method: ['so', 'limits', 'defaults'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static updateLimits = (data) =>
        <ExtendedRequestParams<DataFrom<ServerLimitResponse>>> {
        command: requestCommandIE,
        method: ['so', 'limits', 'defaults'],
        params: {
            ...data,
            request_method: requestMethod.post,
        },
    }

    static getAccountSettingDefaults = () => <ExtendedRequestParams<DataFrom<AccountsSettingsResponse>>> {
        command: requestCommandIE,
        method: ['accounts', 'settings', 'default'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static updateAccountSettingDefaults = (data: AccountDefaultSettingsUI) => {
        const settings: AccountSetting[] = Object.entries(data).map(([key, value]) => {
            if (key === 'limit_bytes') {
                value = value * (1024 ** 2);
            }
            return {
                setting: key,
                int_value: value as number
            }
        });
        return <ExtendedRequestParams<DataFrom<AccountsSettingsResponse>>> {
            command: requestCommandIE,
            method: ['accounts', 'settings', 'default'],
            params: {
                settings,
                request_method: requestMethod.post,
            },
        }
    }


    static getAccountList = () =>
        <ExtendedRequestParams<DataFrom<AgentResponse<ListResponseData<string>>>>> {
        command: requestCommandIE,
        method: ['unix-users'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static deleteAccounts = ({userName}: {
        userName: string,
    }) => <ExtendedRequestParams<DataFrom<NullAgentResponse>>> {
        command: requestCommandIE,
        method: ['accounts', userName, 'settings'],
        params: {
            request_method: requestMethod.delete,
        },
    }
}

export type AccountListResponseData = AgentResponse<ListResponseData<ResponseEmailSettingsItem>>;

export type ServerSpaceResponse = AgentResponse<AgentResponseData<ServerSpace>>;

export type ServerLimitResponse = AgentResponse<AgentResponseData<ServerDefaultsLimits>>;

export type AccountsSettingsResponse = AgentResponse<AgentResponseData<IEDefaultAccountSettings>>;


export type IESettingsResponse = {
    accountSettings: IEDefaultAccountSettings,
    limitSettings: ServerLimitResponse
};

export type AccountResponseData = AgentResponse<AgentResponseData<ResponseEmailSettingsItem>>;
