import { BehaviorSubject, Observable } from "rxjs";

// Operator that preserves subscriber's zone for handlers (instead of the zone of whoever called
// `next`).
export function zoned<T>(source: Observable<T>): Observable<T> {
    return new Observable(subscriber => {
        const zone = (window as any).Zone.current;
        source.subscribe({
            next: (...args) => zone.run(() => subscriber.next(...args)),
            complete: (...args) => zone.run(() => subscriber.complete(...args)),
            error:  (...args) => zone.run(() => subscriber.error(...args)),
        });
    });
}

// `BehaviorSubject` that preserves subscriber's zone for handlers (instead of the zone of whoever
// called `next`).
export class ZonedBehaviorSubject<T> extends BehaviorSubject<T> {
    subscribe(...args) {
        // implemented via custom pipe operator to avoid dealing with all possible formats of `args`
        return super.pipe((source: Observable<T>): Observable<T> => {
            return new Observable(subscriber => {
                const zone = (window as any).Zone.current;
                super.subscribe({
                    next: (...args) => zone.run(() => subscriber.next(...args)),
                    complete: (...args) => zone.run(() => subscriber.complete(...args)),
                    error:  (...args) => zone.run(() => subscriber.error(...args)),
                });
            });
        }).subscribe(...args);
    }
}
