import { forwardRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor, FormGroupDirective,
    NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, UntypedFormGroup,
} from '@angular/forms';
import { I360FormBuilder } from 'app/utils/validator/form-builder';
import { I360Validators } from 'app/utils/validator/validators';
import {
    ExtendFormControlNameDirective,
} from 'app/utils/validator/extend-form-control-name.directive';

export const DEFAULT_KEY = 'default';


@Component({
    selector: 'i360-notification-emails',
    template: `
        <!--<form [formGroup]="form">
            <i360-settings-field
                inputTitle="{{ 'settings.notification.fields.adminEmails.label' | translate }}"
                description=
                    "{{ 'settings.notification.fields.adminEmails.description'| translate }}">
                <div class="i360-default-emails">
                    <mat-checkbox formControlName="default" color="primary">
                        {{ 'settings.notification.fields.adminEmails.checkboxTitle' | translate }}
                    </mat-checkbox>
                </div>
                <cl-text-input>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    formControlName="admin_emails"
                    attr.aria-label
                            ="{{ 'settings.notification.fields.adminEmails.label' | translate }}"
                    commaSeparatedInput>
                </textarea>
                </cl-text-input>
            </i360-settings-field>
        </form>-->`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => I360NotificationEmailsComponent),
        multi: true,
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => I360NotificationEmailsComponent),
        multi: true,
    }],
    host: {
        '[class.config_item]': 'true',
        '[class.flexer]': 'true',
    },
})
export class I360NotificationEmailsComponent implements ControlValueAccessor,
    Validator, OnInit {
    public form: UntypedFormGroup = this.formBuilder.group({
        admin_emails: this.formBuilder.control<string[]>([], [
            I360Validators.emails,
        ]),
        default: this.formBuilder.control(false),
    });
    @ViewChild(FormGroupDirective, {static: true}) formGroup: FormGroupDirective;

    constructor(
        private formBuilder: I360FormBuilder,
        private injector: Injector,
    ) {
        this.form.valueChanges.subscribe(() => {
            // this field is not initialized at beginning, do not know exact reason
            if (this.form.value.admin_emails) {
                this.onChangeFn([
                    ...this.form.value.admin_emails,
                    ...(this.form.value.default ? [DEFAULT_KEY] : []),
                ]);
            }
        });
    }

    onChangeFn = val => {};

    writeValue(formValue: string[]) {
        this.form.setValue({
            admin_emails: formValue.filter(value => value !== DEFAULT_KEY),
            default: formValue.includes(DEFAULT_KEY),
        });
    }

    registerOnChange(fn: any) {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any) {}

    validate(control: AbstractControl): ValidationErrors | null {
        return this.form.controls.admin_emails.errors;
    }

    ngOnInit(): void {
        this.injector.get(ExtendFormControlNameDirective).i360Errors = false;
    }
}
