<div class="i360-low-resources-badge-wrapper" *ngIf="appState.lowResourcesMode$ | async">
    <div class="i360-low-resources-badge">
        <a href="https://docs.imunify360.com/features/#low-resource-usage-mode" target="_blank">
            <mat-icon fontSet="i360-icon" fontIcon="i360-icon-leaf"></mat-icon>
            {{ 'menu.lowResourcesUsage' | translate }}
        </a>
    </div>
</div>

<div class="i360-navigation-wrapper" [class.i360-low-resources]="appState.lowResourcesMode$ | async">
    <div class="i360-main-nav" #navWrapper>

        <div class="i360-logo-container">
            <ng-template [ngTemplateOutlet]="logoTemplate"></ng-template>

            <ng-container *ngIf="(authState.isClient|async) && myImunifyState?.enabled; else standardVersion">
                <div class="i360-myimunify-status">{{
                    myImunifyState?.protection ? ('menu.protectionEnabled' | translate) : ('menu.protectionDisabled' | translate)
                    }}</div>
            </ng-container>
            <ng-template  #standardVersion>
                <i360-package-versions [appVersion]="appState.version"></i360-package-versions>
            </ng-template>

            <button mat-icon-button
                    (click)="closed = !closed"
                    aria-haspopup="true"
                    attr.aria-label="{{ 'menu.open' | translate }}"
                    [attr.aria-expanded]="!closed"
                    class="i360-nav-trigger">
                <mat-icon color="primary">{{closed ? 'menu' : 'close'}}</mat-icon>
            </button>
        </div>

        <div *ngFor="let template of leftTemplates" class="i360-main-nav-item">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
            <div class="i360-main-nav-item-border"></div>
        </div>

        <div class="i360-main-nav-item menu-trigger" *ngIf="dropTemplates.length">
            <button #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="dropMenu"
                    title="{{ 'menu.more' | translate }}">
                <mat-icon svgIcon="ellipsis"></mat-icon>
            </button>
            <div class="i360-main-nav-item-border"></div>
        </div>

        <div class="i360-nav-separator" #separator></div>

        <div *ngFor="let template of rightTemplates" class="i360-main-nav-item right-icons">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
            <div class="i360-main-nav-item-border"></div>
            <div class="right-icons-separator"></div>
        </div>

        <div class="button-wrapper" *ngFor="let template of buttonTemplates">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
            <div class="i360-main-nav-item-border"></div>
        </div>

    </div>
</div>

<mat-menu #dropMenu="matMenu">
    <button #menuItem mat-menu-item
            (click)="clickMenuItem(menuItem)"
            *ngFor="let template of copyOfDropTemplates">
        <ng-template [ngTemplateOutlet]="template"></ng-template>
    </button>
</mat-menu>
