import { Injectable } from '@angular/core';
import { AdminContactsHistoryService } from './admin-contacts-history.service';
import { NewsAdminContactsComponent } from './news-admin-contacts.component';


@Injectable()
export abstract class AdminContactsOpenerService {
    protected newsAdminContactsComponent: NewsAdminContactsComponent;

    constructor(
        protected adminContactsHistoryService: AdminContactsHistoryService,
    ) {}

    get blockOverlayAutoOpen() {
        return false;
    }

    init(newsAdminContactsComponent: NewsAdminContactsComponent) {
        this.newsAdminContactsComponent = newsAdminContactsComponent;
    }

    abstract openIfNeeded(): void;
}
