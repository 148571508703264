import { format } from 'd3-format';

export const ciFormatter = format('.3~s');

// http://www.cse.yorku.ca/~oz/hash.html
// djb2
export function hashCode(s: string) {
    if (!s) {
        return 0;
    }
    return s.split('').reduce(function(hash, c) {
        // eslint-disable-next-line no-bitwise
        return (((hash << 5) + hash) + c.charCodeAt(0));
    }, 0);
}

export function getArrayInObject(arr, key) {
    if (arr && arr[key]
        && typeof arr[key] !== 'undefined' && (arr[key] || []).length) {
        return arr[key];
    }
    return null;
}

export function getCaller() {
    return (new Error()).stack?.split("\n")[3].trim().split(" ")[2] || '';
}

export function filterObject(obj, fn) {
    return Object.fromEntries(Object.entries(obj).filter(fn))
}
