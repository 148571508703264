import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';

export const INVALID_TOKEN_MESSAGE = 'INVALID_TOKEN';

export interface LoginCredentials {
    username: string;
    password: string;
}

export interface LoginData {
    username: string;
}

type TokenAgentResponse = AgentResponse<AgentResponseData<string>>;

export class AuthAPI {
    static login(credentials: LoginCredentials) {
        return new RequestParams<DataFrom<TokenAgentResponse>>(['login', 'pam'], credentials);
    }
    static get(loginData: LoginData) {
        return new RequestParams<DataFrom<TokenAgentResponse>>(['login', 'get'], loginData);
    }
}
