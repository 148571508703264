<i360-modal-content
        [showConfirm]="false"
        [showCancel]="false">
    <ng-container modal-title>
        {{ 'utils.modals.myImunifyUpsell.modalTitle' | translate }}
    </ng-container>
    <ng-container modal-body>
        <div class="my-imunify-protection">
            <div class="my-imunify-protection-disabled">
                <div class="my-imunify-protection-disabled__label">
                    {{'utils.modals.myImunifyUpsell.protectionDisabledLabel' | translate}}
                </div>
                <div class="my-imunify-protection-disabled__logo">
                    <mat-icon svgIcon="logo-myimunify" class="my-imunify-icon"></mat-icon>
                </div>
                <div class="my-imunify-protection-disabled__list">
                    <h3 class="my-imunify-protection-disabled__list-title">
                        {{'utils.modals.myImunifyUpsell.manualCleanup.title' | translate}}
                    </h3>
                    <ul>
                        <li *ngFor="let item of manualCleanupItems" class="my-imunify-protection-disabled__list-item">
                            {{item | translate}}
                        </li>
                    </ul>
                </div>

                <hr>

                <div class="my-imunify-protection-disabled__list">
                    <h3 class="my-imunify-protection-disabled__list-title">
                        {{'utils.modals.myImunifyUpsell.risks.title' | translate}}
                    </h3>
                    <ul>
                        <li *ngFor="let item of risksItems" class="my-imunify-protection-disabled__list-item">
                            {{item | translate}}
                        </li>
                    </ul>
                </div>
                <span class="my-imunify-protection-disabled__button">{{'utils.modals.myImunifyUpsell.current' | translate}}</span>
            </div>
            <div class="my-imunify-protection-enabled">
                <div class="my-imunify-protection-enabled__label">
                    {{'utils.modals.myImunifyUpsell.protectionEnabledLabel' | translate}}
                </div>
                <div class="my-imunify-protection-enabled__logo">
                    <mat-icon svgIcon="logo-myimunify" class="my-imunify-icon"></mat-icon>
                </div>
                <div class="my-imunify-protection-enabled__list">
                    <h3 class="my-imunify-protection-enabled__list-title">
                        {{'utils.modals.myImunifyUpsell.autoCleanup.title' | translate}}
                    </h3>
                    <ul>
                        <li *ngFor="let item of autoCleanupItems" class="my-imunify-protection-enabled__list-item">
                            {{item | translate}}
                        </li>
                    </ul>
                </div>

                <hr>

                <div class="my-imunify-protection-enabled__list">
                    <h3 class="my-imunify-protection-enabled__list-title">
                        {{'utils.modals.myImunifyUpsell.benefits.title' | translate}}
                    </h3>
                    <ul class="in-two-columns">
                        <li *ngFor="let item of benefitsItems" class="my-imunify-protection-enabled__list-item">
                            {{item | translate}}
                        </li>
                    </ul>
                </div>
                <span class="my-imunify-protection-enabled__button"
                      (click)="myImunifyService.openUpgradePage()"
                >{{'utils.modals.myImunifyUpsell.buyNow' | translate}}</span>
            </div>
        </div>
    </ng-container>
</i360-modal-content>
