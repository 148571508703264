import { DEFAULT_DATA } from './misc';
import { IPScope } from '../lists';

export const formData = {
    list : '',
    type : 'ip',
    ttlUnits: 'days',
    ttl: '1',
    comment : 'Newly created IP with comment',
    fullAccess : false,
    value :  '223.21.1.1',
    scope: IPScope.group,
};
const listData = {
    no_captcha: false,
    ip: '1.1.210.66',
    ctime: 1522908698,
    deep: 4,
    purpose: 'WHITE',
    manual: true,
    auto_whitelisted: false,
    comment: 'Special for blacklist',
    country: {
        name: 'Thailand',
        code: 'TH',
    },
    version: 4,
    scope: null,
    full_access: null,
    network_address: 16896578,
    netmask: 4294967295,
    imported_from: null,
    expiration: 1523037066,
};
const listItems = [
    {
        ...listData,
        comment: null,
        scope: IPScope.group,
        manual: false,
    },
    {
        ...listData,
        comment: null,
        scope: IPScope.local,
        manual: false,
    },
    {
        ...listData,
        ip: '1234:5678:9abc::',
        country: {},
        purpose: 'WHITE',
        scope: IPScope.local,
    },
    {
        ...listData,
        ip: '1234:5678:9abc:def0::',
        full_access: true,
        country: {},
        purpose: 'WHITE',
    },
    {
         ...listData,
        ip: '10.101.1.14',
        purpose: 'CAPTCHA',
        comment: 'Special for blacklist',
        country: {
            name: 'Thailand',
            code: 'TH',
            id: '1605651',
        },
    },
    {
        ...listData,
        ip: '10.101.1.15',
        country: null,
        purpose: 'CAPTCHA',
        comment: 'Special for blacklist',
    },
    {
        ...listData,
        ip: '10.101.1.58',
        country: {
            name: 'Brazil',
            code: 'BR',
        },
        purpose: 'CAPTCHA',
    },
    {
        ...listData,
        ip: '11.11.11.11',
        country: {
            name: 'United States of America',
            code: 'US',
        },
        purpose: 'CAPTCHA',
    },
    {
        ...listData,
        ip: '11.11.11.11/32',
        country: {
            name: 'Ukraine',
            code: 'UA',
        },
        purpose: 'drop',
    },
];

export const responses = {
    ipAlreadyExists: {
        data: {
            messages: [
                'IP 1.162.36.148 is already in captcha list',
                'Imunify360 protection is running concurrently with cPHulk',
            ],
            result: 'warnings',
        },
    },
    groupId: {
        status: 'ok',
        group_id: 'groupId',
    },
    list: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: listItems,
            counts: {
                'server': {
                    'white': 23,
                    'captcha': 434,
                    'drop': 34,
                    'splashscreen': 34,
                },
                'cloud': {
                    'white': 23,
                    'captcha': 434,
                    'drop': 34,
                    'splashscreen': 34,
                }
            },
        },
    },
    listsPagination: {
        data: {
            ...DEFAULT_DATA,
            max_count: 999,
            items: [
                ...listItems,
                ...listItems,
                ...listItems,
                ...listItems,
            ],
            counts: {
                'server': {
                    'white': 23,
                    'captcha': 434,
                    'drop': 34,
                    'splashscreen': 34,
                },
                'cloud': {
                    'white': 23,
                    'captcha': 434,
                    'drop': 34,
                    'splashscreen': 34,
                }
            },
        },
    },
    blacklist: {
        data: {
            ...DEFAULT_DATA,
            max_count: 11,
            items: [
                {
                    imported_from: null,
                    ip: '10.101.1.58',
                    no_captcha: false,
                    ctime: 1522908698,
                    deep: 4,
                    manual: true,
                    purpose: 'captcha',
                    auto_whitelisted: false,
                    comment: 'Special for blacklist',
                    country: {
                        name: 'Thailand',
                        code: 'TH',
                        id: '1605651',
                    },
                    version: 4,
                    full_access: null,
                    network_address: 16896578,
                    netmask: 4294967295,
                    expiration: 1523037066,
                },
            ],
            counts: {
                'server': {
                    'all': 100,
                    'white': 23,
                    'captcha': 434,
                    'black': 34,
                    'splashscreen': 34,
                },
                'cloud': {
                    'white': 23,
                    'captcha': 434,
                    'black': 34,
                    'splashscreen': 34,
                }
            },
        },
    },
};
