import {
    AgentResponse, AgentResponseData, DataFrom, ListResponseData,
    NullAgentResponse, RequestParams,
} from './misc';

export interface NativeFeaturesManagementStatus {
    supported: boolean;
    enabled: boolean;
}

interface FeaturesManagementResponseItem {
    name: string;
    domains: string[];
    features: FeaturesStatus;
}

export enum BoolFeature {
    full = 'full',
    na = 'na',
}

export enum ReportableFeature {
    full = 'full',
    na = 'na',
    report = 'report',
}

export enum FeatureName {
    av = 'av',
    proactive = 'proactive',
}

export interface FeaturesStatus {
    proactive: boolean;
    av: boolean;
}

export interface ClientFeatures {
    proactive: BoolFeature;
    // why "or"
    // FIXME https://cloudlinux.atlassian.net/browse/DEF-7620
    av: ReportableFeature | BoolFeature;
}

export class FeaturesManagementAPI {
    static allowed = () =>
        new RequestParams<DataFrom<FeaturesManagementListAgentResponse>>
        (['feature-management', 'list'])

    static nativeStatus = () =>
        new RequestParams<DataFrom<FeaturesManagementNativeStatusAgentResponse>>
        (['feature-management', 'native', 'status'])

    static nativeEnable = () =>
        new RequestParams<DataFrom<NullAgentResponse>>(['feature-management', 'native', 'enable'])

    static list = (params = {}) =>
        new RequestParams<DataFrom<FeaturesManagementShowAgentResponse>>
        (['feature-management', 'show'], params)

    static editUserFeatures = ({action, feature, items}:
        {action: string, feature: string, items: string[]}) =>
        new RequestParams<DataFrom<FeaturesManagementEditUsersAgentResponse>>
            // this response is ignored...
        (['feature-management', action], {users: items, feature: feature})

    static editDefaultFeature({action, feature}: {action: 'enable' | 'disable', feature: string}) {
        return new RequestParams<DataFrom<FeaturesManagementEditDefaultsAgentResponse>>(
            ['feature-management', action], {feature: feature});
    }

    static defaults() {
        return new RequestParams<DataFrom<FeaturesManagementDefaultsAgentResponse>>(
            ['feature-management', 'defaults']);
    }

    static clientFeatures() {
        return new RequestParams<DataFrom<FeaturesManagementGetAgentResponse>>(
            ['feature-management', 'get']);
    }
}

export class FeaturesManagementItem {
    name: string;
    domains: string[];
    proactive: boolean;
    av: boolean;

    constructor({name, domains, features}: FeaturesManagementResponseItem) {
        this.name = name;
        this.domains = domains;
        this.proactive = features.proactive;
        this.av = features.av;
    }
}

type FeaturesManagementShowAgentResponse
    = AgentResponse<ListResponseData<FeaturesManagementResponseItem>>;

type FeaturesManagementListAgentResponse
    = AgentResponse<AgentResponseData<Array<keyof FeaturesStatus>>>;

type FeaturesManagementNativeStatusAgentResponse
    = AgentResponse<AgentResponseData<NativeFeaturesManagementStatus>>;

type FeaturesManagementEditDefaultsAgentResponse
    = AgentResponse<AgentResponseData<'succeed'>>;

type FeaturesManagementEditUsersAgentResponse
    = AgentResponse<AgentResponseData<{succeeded: string[], failed: string[]}>>;

type FeaturesManagementDefaultsAgentResponse
    = AgentResponse<AgentResponseData<FeaturesStatus>>;

type FeaturesManagementGetAgentResponse
    = AgentResponse<AgentResponseData<ClientFeatures>>;

type FeaturesManagementEditAgentResponse = FeaturesManagementEditDefaultsAgentResponse
    | FeaturesManagementEditUsersAgentResponse;
