import { Injectable } from '@angular/core';
import { MaliciousAPI, MaliciousItem, MaliciousItemInBackend, MaliciousFileStatus } from '@imunify360-api/malicious';
import { XHR } from 'app/utils/xhr/xhr';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class MaliciousService {
    constructor(public xhr: XHR) {}

    maliciousCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    get = this.xhr.wrap(this.xhr.rx(MaliciousAPI.list), MaliciousItem);

    list = this.xhr.rx(MaliciousAPI.list);

    cleanupAll = this.xhr.rx(MaliciousAPI.cleanupAll);
    action = this.xhr.rx(MaliciousAPI.action);
}
