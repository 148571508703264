import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { NoContentComponent } from './no-content.component';


@NgModule({
    imports: [
        CommonModule,
        I360TranslateModule,
    ],
    declarations: [
        NoContentComponent,
    ],
    exports: [
        NoContentComponent,
    ],
})
export class I360NoContentModule { }
