import { LoaderDirective } from 'app/utils/loader/loader.directive';

const getLoader = (self): LoaderDirective => self.loader
    || (self.table && self.table.loader)
    || (self.request && self.request.loader);
const generateUniqueKey = (propertyKey: string): string => {
    return `${propertyKey}_${Math.random().toString(36).slice(2)}`;
};

export function useLoader(this: any, ...args: any[]) {
    const customLoader = args.length === 2 ? args[1] : null;
    const f = (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
        let originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            const key = generateUniqueKey(propertyKey);
            let loader = customLoader ? customLoader(this) : getLoader(this);
            if (!loader) {
                loader = {show: (e?) => {}, hide: (e?) => {}};
            }
            loader.show(key);
            let result;
            try {
                result = await originalMethod.apply(this, args);
            } catch (e) {
                throw e;
            } finally {
                loader.hide(key);
            }
            return result;
        };

        return descriptor;
    };
    if (args.length === 1) {
        return f;
    } else {
        return f.apply(this, args);
    }
}
