<i360-modal-content
        [showConfirm]="false"
        [showCancel]="false">
    <ng-container modal-body>
        <div class="my-imunify-payment">
            <div class="my-imunify-payment__image">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.1731 36.6415L36.6407 33.1738L27.4762 24.0093V12.6156H22.5225V25.9908L33.1731 36.6415ZM24.9994 49.769C21.573 49.769 18.353 49.1188 15.3395 47.8184C12.3259 46.518 9.70457 44.7533 7.47537 42.5241C5.24617 40.2949 3.48138 37.6735 2.18102 34.6599C0.880652 31.6464 0.230469 28.4264 0.230469 25.0001C0.230469 21.5737 0.880652 18.3538 2.18102 15.3402C3.48138 12.3267 5.24617 9.7053 7.47537 7.4761C9.70457 5.2469 12.3259 3.48212 15.3395 2.18175C18.353 0.881384 21.573 0.231201 24.9994 0.231201C28.4257 0.231201 31.6457 0.881384 34.6592 2.18175C37.6728 3.48212 40.2941 5.2469 42.5233 7.4761C44.7525 9.7053 46.5173 12.3267 47.8177 15.3402C49.118 18.3538 49.7682 21.5737 49.7682 25.0001C49.7682 28.4264 49.118 31.6464 47.8177 34.6599C46.5173 37.6735 44.7525 40.2949 42.5233 42.5241C40.2941 44.7533 37.6728 46.518 34.6592 47.8184C31.6457 49.1188 28.4257 49.769 24.9994 49.769Z" fill="#FFAA5B"/>
                </svg>
            </div>
            <div class="my-imunify-payment__details">{{data.details | translate}}</div>
        </div>
    </ng-container>
</i360-modal-content>
