import { MalwareScanType } from './malware';
import { AgentResponse, DataFrom, ListResponseData,
    NullAgentResponse, RequestParams } from './misc';

export enum MalwareScanStatus { // it the same as ScanState, but with queued....
    running = 'running',
    queued = 'queued',
    stopped = 'stopped',
}

export enum MalwareCleanStatus {
    running = 'running',
    stopped = 'stopped',
}


export interface UserListItemBackend {
    home: string;
    user: string;
    infected: number;
    infected_db: number;
    scan_id: string | null;
    scan_status: MalwareScanStatus | null;
    scan_date: number | null;
    scan_type?: MalwareScanType;
    cleanup_status: MalwareCleanStatus | null;
}

// tslint:disable:variable-name
export class UserListItem implements UserListItemBackend {
    home: string;
    user: string;
    infected: number;
    infected_db: number;
    scan_id: string | null;
    scan_status: MalwareScanStatus | null;
    scan_date: number | null;
    scan_type?: MalwareScanType;
    cleanup_status: MalwareCleanStatus | null;

    scanAvailable: boolean;
    cleanAvailable: boolean;
    restoreAvailable: boolean;
    stopAvailable: boolean;
    reportAvailable: boolean;

    public constructor(data: UserListItemBackend) {
        this.home = data.home;
        this.user = data.user;
        this.infected = data.infected;
        this.scan_id = data.scan_id;
        this.scan_status = data.scan_status;
        this.scan_date = data.scan_date;
        this.cleanup_status = data.cleanup_status;
        this.infected_db = data.infected_db;
        this.scan_type = data.scan_type;
        this.scanAvailable = ![
              MalwareScanStatus.running,
              MalwareScanStatus.queued,
        ].includes(this.scan_status!) && this.cleanup_status !== MalwareCleanStatus.running;
        this.stopAvailable = !this.scanAvailable
              && this.cleanup_status !== MalwareCleanStatus.running;
        this.cleanAvailable = (this.infected > 0 || this.infected_db > 0) &&
              this.cleanup_status !== MalwareCleanStatus.running;
        this.restoreAvailable = this.cleanup_status === MalwareCleanStatus.stopped;
        this.reportAvailable = !!this.cleanup_status || this.infected > 0 || this.infected_db > 0;
    }
}

export class UserListAPI {

    static list = (params = {}) =>
        new RequestParams<DataFrom<MalwareUserListAgentResponse>>
        (['malware', 'user', 'list'], params)

    static scanAll(params: {detect_elf: boolean}) {
        return new RequestParams<DataFrom<NullAgentResponse>>(['malware', 'user', 'scan'], params);
    }
}

type MalwareUserListAgentResponse = AgentResponse<ListResponseData<UserListItemBackend> & {
    malicious_count: number,
}>;
