import { Injectable } from '@angular/core';

interface HistoryRecord {
    timestamp: number;
}

@Injectable()
export class AdminContactsHistoryService {
    private contactsHistory: HistoryRecord[];
    private storageKey: string = 'imunify-admin-contacts-history';

    constructor() {
        this.contactsHistory = TEST && window['i360Test'] && window['i360Test'].disableNews ?
            [0, 0, 0, 0, 0] : this._getHistoryFromStorage();
    }

    addHistoryRecord(record: HistoryRecord) {
        this.contactsHistory.push(record);

        localStorage.setItem(this.storageKey, JSON.stringify(this.contactsHistory));
    }

    getHistory() {
        return this.contactsHistory;
    }

    _getHistoryFromStorage() {
        return JSON.parse(localStorage.getItem(this.storageKey) || '[]');
    }
}
