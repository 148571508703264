import { DEFAULT_DATA } from './misc';
import { Permission } from '../permissions';

const items: Array<Permission> = [
    Permission.MS_VIEW,
    Permission.MS_CLEAN,
    Permission.MS_CLEAN_REQUIRES_MYIMUNIFY_PROTECTION,
    Permission.MS_ON_DEMAND_SCAN,
    Permission.MS_ON_DEMAND_SCAN_WITHOUT_RATE_LIMIT,
    Permission.MS_IGNORE_LIST_EDIT,
    Permission.MS_CONFIG_DEFAULT_ACTION_EDIT,
    Permission.PD_VIEW,
    Permission.PD_CONFIG_MODE_EDIT,
];

export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            items,
        },
    },
    listNotAllowed: {
        data: {
            ...DEFAULT_DATA,
            items: [],
        },
    },
};
