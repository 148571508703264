import { DEFAULT_DATA } from './misc';
import { ResourceType } from './../malware';

export const responses = {
    malware: {
        data: {
            ...DEFAULT_DATA,
            max_count: 44,
            items: [
                ...Array.from(new Array(8), (n, index) => {
                    return {
                        path: `/home/user-a-0${index}/ignore.gzip`,
                        added_date: 1528272138,
                        id: index,
                        resource_type: ResourceType.file,
                    };
                }),
                ...[{
                    path: `/domain.name/public_html/wp-content/${'long/'.repeat(100)}long.jpeg`,
                    added_date: Date.now() / 1000,
                    id: 1,
                    resource_type: ResourceType.db,
                }],
            ],
        },
    },

    userside: {
        data: {
            ...DEFAULT_DATA,
            max_count: 36,
            items: [
                ...Array.from(new Array(8), (n, index) => {
                    return {
                        path: `/domain.name/public_html/theme_name/function_${index}/ignore.gzip`,
                        id: index,
                        added_date: 1528817623,
                        resource_type: ResourceType.file,
                    };
                }),
                ...[{
                    path: `/domain.name/public_html/wp-content/${'long/'.repeat(100)}long.jpeg`,
                    id: 1,
                    added_date: Date.now() / 1000,
                    resource_type: ResourceType.file,
                }],
            ],
        },
    },
};
