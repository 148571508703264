import { isFQDN } from 'validator';

const portRegex = /^(\d+)([:|-](\d+))?$/;
const integerRegex = /^-?\d*$/;
const floatRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
/* eslint-disable max-len */
const ipv4WithCIDRRegex = /^(([2]([0-4][0-9]|[5][0-5])|[0-1]?[0-9]?[0-9])[.]){3}(([2]([0-4][0-9]|[5][0-5])|[0-1]?[0-9]?[0-9]))\b(\/\b([0-9]|[12][0-9]|3[0-2]))?$/;
const ipv6WithCIDRRegex = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?(\/([0-9]|[1-5][0-9]|6[0-4]))\s*$/;
const ipv6RegexArray = [ipv4WithCIDRRegex, ipv6WithCIDRRegex];

const emailRegex = /^.+@(.+\.)+.+$/;
const serverIdRegex = /(^\s*SS[a-zA-Z0-9]{14}\s*$)|(^\s*IP-\d*-\d*-[a-zA-Z0-9]{8}\s*$)/;
/* eslint-enable max-len */

export class I360Validators {
    static integer = required => {
        return ({value}) => {
            if (!value && value !== 0) {
                return required ? {required: {what: 'integer'} } : null;
            }
            return integerRegex.test(value) ? null : {integer: {}};
        };
    }

    static float = required => {
        return ({value}) => {
            if (!value || value === '0') {
                return required ? {required: {what: 'integer'} } : null;
            }
            return floatRegex.test(value) ? null : {integer: {}};
        };
    }

    static floatPerforation = ({value}) => {
        return floatRegex.test(value) || value === -1 ? null : {integer: {}};
    };

    static ipWithCIDR = required => {
        return ({value}) => {
            if (!value) {
                return required ? {required: {what: 'ip'}} : null;
            }
            return ipv6RegexArray.some(regex => regex.test(value))
                ? null
                : {ipWithCIDR: {}};
        };
    }

    static pathToFile = ({value}) => {
        return /\u0000/.test(value) ? {pathToFile: {}} : null;
    }

    static absolutePath = ({value}) => {
        return /^\//.test(value) ? null : {absolutePath: {}};
    }

    static listOfAbsolutePaths = ({ value }: { value: string[] }) => {
        if (!Array.isArray(value)) return null;

        const valid = value.every(
            (path) => I360Validators.absolutePath({ value: path }) === null,
        );

        return valid ? null : { absolutePath: {} };
    }

    static partOfIP = ({value}) => {
        return !value || /^[0-9a-fA-F.:\/]+$/.test(value) ? null : {partOfIP: {}};
    }

    static commaSeparatedDomains = ({value}) => {
        if (!value) return null;
        const check = i => isFQDN(i.trim());
        return value.every(item => {
            return item.includes(',') ? item.split(',').every(i => check(i)) : check(item);
        }) ? null : {domains: {}};
    }

    static listOfIPWithCIDR = ({value}) => {
        if (!value) return null;
        const ipWithCIDRValidator = I360Validators.ipWithCIDR(false);
        const anyFails = value.split(',').reduce((res, ip) => ipWithCIDRValidator({value: ip.trim()}), true);
        return anyFails ? {listOfIP: {}} : null;
    }

    static email = ({value}) => {
        return !value || emailRegex.test(value) ? null : {email: {}};
    }

    static emails = ({ value }: { value: string[] }) => {
        if (!Array.isArray(value)) return null;

        const valid = value.every(
            (email) => I360Validators.email({ value: email }) === null,
        );

        return valid ? null : { emails: {} };
    }

    static required = what => {
        return ({value}) => {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    return null;
                }
            } else if (value || Number.isFinite(value)) {
                return null;
            }
            return {required: {what}};
        };
    }

    static fileSize = (size: number) => {
        return ({value}) => {
            if (!value || Array.isArray(value) && !value.length) {
                return null;
            }

            let sizeExceeded: string[] = [];
            for (let file of (value as File[])) {
                if (file.size > size) {
                    sizeExceeded.push(file.name);
                }
            }
            if (sizeExceeded.length) {
                return {fileSize: {
                        files: sizeExceeded.join(', '),
                        count: sizeExceeded.length,
                        maxSize: String(Math.round(size / (1024 * 1024))),
                    }};
            }
            return null;
        };
    }

    static maxFiles = (size: number) => {
        return ({value}) => {
            if (value && value.length > size) {
                return {maxFiles: {size}};
            }
            return null;
        };
    }

    static listOfServerId({value}: {value: string[]}) {
        if (value.length === 0) {
            return {serverId: {}};
        }
        const results = value.map(v => serverIdRegex.test(v));
        if (results.every(v => v)) {
            return null;
        } else {
            const invalid = value.filter((id, i) => !results[i]);
            if (value.length === 1) {
                return {serverId: {}};
            }
            return {
                listOfServerId: {
                    ids: invalid,
                },
            };
        }
    }
    static listPortOrRange = () => {
        return ({value}: {value}) => {
            const invalid = value.filter(val => {
                const ports = val.split(portRegex);
                const notInRange = (port) => {
                    return !integerRegex.test(port) ||  Number(port) < 1 || Number(port) > 65_535;
                };
                return  !ports.length || notInRange(ports[1]) ||
                    (ports[3] !== undefined && notInRange(ports[3]));
            });
            if (invalid.length) {
                return {
                    listOfIntegers: {
                        invalid,
                    },
                };
            } else {
                return null;
            }
        };
    }

    static listOfIntegers = (min: number, max: number) => {
        return ({value}: {value}) => {
            const invalid = value.filter(val => {
                return !integerRegex.test(val) || Number(val) < min || Number(val) > max;
            });
            if (invalid.length) {
                return {
                    listOfIntegers: {
                        invalid,
                    },
                };
            } else {
                return null;
            }
        };
    }
}
