import { NgModule } from '@angular/core';
import { I360ButtonComponent } from 'app/utils/button/button.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        MatFormFieldModule,
    ],
    declarations: [I360ButtonComponent],
    exports: [
        I360ButtonComponent,
        MatFormFieldModule,
    ],
})
export class I360ButtonModule {}
