<i360-modal-content
        [showConfirm]="false">
    <ng-container modal-title>
        <div [class]="data.details.type+'-modal-details'">
            <i class="material-icons error-icon"
               *ngIf="data.details.type === 'error'">info_outline</i> {{ data.details.title}}
        </div>
    </ng-container>
    <ng-container modal-body>
        <textarea id="notification-details" readonly>{{ data.details.content }}</textarea>
    </ng-container>
</i360-modal-content>