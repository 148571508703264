import { Injectable } from '@angular/core';
import { AppState, Notification } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { TranslateParams, TranslateService } from 'app/utils/translate/translate.service';


@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    notifications: Notification[] = [];

    constructor(
        private appState: AppState,
        private authState: AuthState,
        private translate: TranslateService,
    ) {
        // remove all notifications on role change
        this.authState.role.subscribe(() => this.appState.notifications.next([]));
    }

    success(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.success`, content, 'success', params);
    }

    error(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.error`, content, 'error', params);
    }

    warning(content: string, title: string|null = null, params?: TranslateParams) {
        return this.error(content, title || `notifications.warning`, params);
    }

    remove(item: Notification): void {
        this.appState.notifications.next(
            this.appState.notifications.value.filter(i => !this.equal(i, item)),
        );
    }

    private async add(title: string,
                      content: string,
                      type: string,
                      params: TranslateParams|undefined): Promise<void> {
        const item = new Notification({
            title: await this.translate.t(title, params),
            content: await this.translate.t(content, params),
            type,
        });

        for (let i of this.appState.notifications.value) {
            if (this.equal(i, item)) {
                i.counter++;
                i.ticks = 0;
                return;
            }
        }

        item.counter = 1;
        this.appState.notifications.next([...this.appState.notifications.value, item])
    }

    private equal(a: Notification, b: Notification) {
        return a.type === b.type
            && a.title === b.title
            && a.content === b.content;
    }
}
