import { Directive, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
/**
 * TODO: remove this after migrating to @angular/material6
 */
@Directive({
    selector: '[i360-dialog-template]',
    providers: [{
        provide: MatDialogRef,
        useExisting: TempDialogTemplateWrapperDirective,
    }],
})
export class TempDialogTemplateWrapperDirective {
    @Input('i360-dialog-template') id: string; // eslint-disable-line @angular-eslint/no-input-rename
    constructor(private dialogService: MatDialog) {}
    close(result: any) {
        let dialog: MatDialogRef<any> | undefined;
        if (this.id) {
            dialog = this.dialogService.getDialogById(this.id);
        } else {
            dialog = this.dialogService.openDialogs[0];
        }
        if (dialog) {
            dialog.close(result);
        }
    }
}
