<button id="i360-profile"
        attr.aria-label="{{ 'menu.profileMenu' | translate }}"
        [matMenuTriggerFor]="profileMenu">
    <mat-icon class="i360-profile" svgIcon="profile"></mat-icon>
</button>
<mat-menu xPosition="before" #profileMenu="matMenu">
    <div class="username" *ngIf="(authState.username|async) && panel.isNoPanel">{{ authState.username|async }}</div>
    <button mat-menu-item
            [matMenuTriggerFor]="langsMenu"
            class="i360-language">
        <mat-icon>language</mat-icon>
        <span translate>menu.changeLanguage</span>
    </button>
    <button mat-menu-item
            *ngIf="panel.isNoPanel"
            (click)="authService.logout()"
            class="i360-logout">
        <mat-icon>exit_to_app</mat-icon>
        <span translate>menu.logout</span>
    </button>
</mat-menu>
<mat-menu #langsMenu="matMenu" classList="i360-nav-menu-container">
    <button mat-menu-item *ngIf="!panel.isNoPanel"
            [class.mat-selected]="translate.logicalLang === panelLang"
            (click)="translate.use(panelLang)">
        {{ 'menu.panelLanguage' | translate }}
    </button>
    <button mat-menu-item *ngFor="let lang of translate.langs"
            [class.mat-selected]="translate.currentLang === lang.code"
            (click)="translate.use(lang.code)">
        {{lang.name}}
    </button>
</mat-menu>
