import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { ZonedHashLocationStrategy } from './zoned-hash-location-strategy';

export const navRootRoutes: Routes = [
    // Avoid route guards here.
    // It's better if only one mounted SPA can use route guards.
    // Otherwise, there is undetermined behavior, when one navigation may get rejected in 2 different ways, both async.
    // So, we can have guards either in `nav-root` SPA, or in all others (since they never co-exist with each other,
    // but always co-exist with `nav-root`).
    // I think it will be easier to avoid route-guards in `nav-root`, and just duplicate them in others if needed.
    {
        path: '**',
        loadChildren: () => import('app/empty-route/empty-route.module').then(m => m.EmptyRouteModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            navRootRoutes,
            { useHash: true, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'ignore' },
        ),
    ],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: ZonedHashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
    ],
})
export class NavRootRoutingModule { }
