import {
    Component, Input,
} from '@angular/core';
import { SettingsService } from 'app/services/settings';


@Component({
    selector: 'i360-package-versions',
    templateUrl: './package-versions.component.html',
})
export class PackageVersionsComponent {
    @Input() public appVersion:string;

    constructor(
         public settingsService: SettingsService,
    ) {}

}
