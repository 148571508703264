import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { of,  EMPTY, Observable, switchMap } from 'rxjs';
import { EulaService } from 'app/components/eula/eula.service';
import { AuthState } from 'app/services/auth-state';
import { AgentResponse, ResponseDataExceptItems } from '@imunify360-api/misc';
import { postOnly } from 'app/interceptors/utils';


@Injectable()
export class EulaInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private authState: AuthState,
    ) {}

    @postOnly
    intercept(req: HttpRequest<AgentResponse<ResponseDataExceptItems>>, next: HttpHandler):
        Observable<HttpEvent<AgentResponse<ResponseDataExceptItems>>> {
        return next.handle(req).pipe(
            switchMap((event) => {
                if (this.authState.isAdmin.value && event instanceof HttpResponse) {
                    const body: AgentResponse<ResponseDataExceptItems> = event.body;
                    if (body.data) {
                        const eulaStatus = body.data.eula;
                        if (eulaStatus !== undefined
                            && !this.injector.get(EulaService).checkEulaStatus(eulaStatus)) {
                            return EMPTY;
                        }
                    }
                }
                return of(event);
            }),
        );
    }
}
