import { MalwareScanType } from '../malware';
import { MalwareCleanStatus, MalwareScanStatus } from '../user-list';
import { DEFAULT_DATA } from './misc';

function stubItem (scan: MalwareScanStatus|null, clean: MalwareCleanStatus|null, infected: number,
                   infectedDB: number) {
      return {scan, clean, infected, infectedDB};
}

export let statuses = [
    stubItem(null, null, 0, 1),
    stubItem( MalwareScanStatus.running, null, 0, 3),
    stubItem( MalwareScanStatus.queued, null, 0, 2),
    stubItem( MalwareScanStatus.stopped, null, 0, 0),
    stubItem( MalwareScanStatus.stopped, null, 10, 2),

    stubItem( MalwareScanStatus.running, MalwareCleanStatus.stopped, 0, 0),
    stubItem( MalwareScanStatus.running, MalwareCleanStatus.stopped, 77, 44),
    stubItem( MalwareScanStatus.queued, MalwareCleanStatus.stopped, 0, 0),
    stubItem( MalwareScanStatus.queued, MalwareCleanStatus.stopped, 88, 68),
    stubItem( MalwareScanStatus.stopped, MalwareCleanStatus.stopped, 0, 0),
    stubItem( MalwareScanStatus.stopped, MalwareCleanStatus.stopped, 0, 11),
    stubItem( MalwareScanStatus.stopped, MalwareCleanStatus.stopped, 99, 0),

    stubItem( MalwareScanStatus.stopped, MalwareCleanStatus.running, 20, 0),
];

const userListItem = (status, index) => {
    return {
        home: `/home/user${index}`,
        user: `user${index}`,
        infected: status.infected,
        infected_db: status.infectedDB,
        scan_id: index.toString(),
        scan_status: status.scan,
        scan_date: index > 1 ? 1525759321 : null,
        scan_type: MalwareScanType.ondemand,
        cleanup_status: status.clean,
    }
};

export const responses = {
    adminside: {
        data: {
            ...DEFAULT_DATA,
            malicious_count: 100,
            max_count: statuses.length,
            items: [
                ...Array.from(statuses, userListItem),
            ],
        },
    },
    one: {
        data: {
            ...DEFAULT_DATA,
            malicious_count: 100,
            max_count: statuses.length,
            items: [userListItem(statuses[11], 1)],
        },
    }
};
