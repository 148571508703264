import { Mode } from '../proactive-defense';
import { DEFAULT_DATA } from './misc';
import { AdminConfigType } from '../../src/app/utils/config/admin-config';
import { UserConfigType } from '../../src/app/utils/config/user-config';
import { DefaultAction } from '../../src/app/utils/config/malware-config';
import { PortBlockingMode, RuleSet, ScheduleInterval } from '../settings';
import { NotificationRulesType } from '../notification-settings';

const permissions = {
    PERMISSIONS: {
        support_form: true,
        user_ignore_list: true,
        upgrade_button: true,
        allow_malware_scan: false,
        advisor: false,
        user_override_proactive_defense: false,
        user_override_malware_actions: false,
    },
};

const userConfig: UserConfigType = {
    CONTROL_PANEL: {
        generic_user_notifications: true,
    },
    BACKUP_RESTORE: {
        max_days_in_backup: 10,
    },
    MALWARE_CLEANUP: {
        keep_original_files_days: 14,
        trim_file_instead_of_removal: false,
    },
    MALWARE_SCANNING: {
        try_restore_from_backup_first: true,
        default_action: DefaultAction.notify,
    },
    PROACTIVE_DEFENCE: {
        mode: Mode.disabled,
        blamer: false,
    },
    ERROR_REPORTING: {
        enable: false,
    },
    MY_IMUNIFY: {
        enable: false,
        purchase_page_url: '',
    },
    ...permissions,
};

const notificationRules: NotificationRulesType = {
    REALTIME_MALWARE_FOUND: {
        ADMIN: {
            admin_emails: ['admin@gmail.com', 'default'],
            enabled: true,
            period: 60,
        },
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],
            period: 1,

        },
    },
    USER_SCAN_STARTED: {
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    CUSTOM_SCAN_STARTED: {
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    USER_SCAN_FINISHED: {
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    CUSTOM_SCAN_FINISHED: {
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    CUSTOM_SCAN_MALWARE_FOUND: {
        ADMIN: {
            admin_emails: ['admin@gmail.com', 'default'],
            enabled: true,
        },
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    USER_SCAN_MALWARE_FOUND: {
        ADMIN: {
            admin_emails: ['admin@gmail.com', 'default'],
            enabled: true,
        },
        SCRIPT: {
            enabled: true,
            scripts: ['/qwe'],

        },
    },
    SCRIPT_BLOCKED: {
        ADMIN: {
            admin_emails: ['admin@gmail.com', 'default'],
            enabled: true,
            period: 60,
        },
        SCRIPT: {
            enabled: true,
            period: 1,
            scripts: ['/qwe'],
        },
    },
};

const common = {
    DOS: {
        enabled: true,
        default_limit: 250,
        interval: 30,
    },
    AUTO_WHITELIST: {
        timeout: 24,
    },
    INCIDENT_LOGGING: {
        limit: 100000,
        num_days: 100,
        min_log_level: 4,
        ui_autorefresh_timeout: 5,
    },
    MALWARE_CLEANUP: {
        trim_file_instead_of_removal: true,
        keep_original_files_days: 14,
    },
    MALWARE_SCANNING: {
        rapid_scan: false,
        enable_scan_inotify: true,
        optimize_realtime_scan: true,
        enable_scan_modsec: true,
        enable_scan_pure_ftpd: false,
        default_action: null,
        try_restore_from_backup_first: true,
        notify_on_detect: false,
        sends_file_for_analysis: true,
        detect_elf: false,
        hyperscan: false,
        enable_scan_cpanel: true,
        crontabs: false,
    },
    MALWARE_SCAN_INTENSITY: {
        cpu: 4,
        io: 2,
    },
    PROACTIVE_DEFENCE: {
        mode: Mode.disabled,
        blamer: false,
        php_immunity: false,
    },
    MALWARE_SCAN_SCHEDULE: {
        interval: ScheduleInterval.none,
        day_of_month: 1,
        day_of_week: 1,
        hour: 1,
    },
    BACKUP_RESTORE: {
        max_days_in_backup: 90,
    },
    MALWARE_DATABASE_SCAN: {
      enable: true,
    },
    ERROR_REPORTING: {
        enable: false,
    },
    SEND_ADDITIONAL_DATA: {
        enable: false,
    },
    WEBSHIELD: {
        enable: false,
        known_proxies_support: false,
        splash_screen: true,
        panel_protection: true,
    },
    OSSEC: {
        active_response: false,
    },
    ADMIN_CONTACTS: {
        emails: ['admin@panel.com'],
    },
    CSF_INTEGRATION: {
        catch_lfd_events: false,
    },
    PAM: {
        enable: true,
        exim_dovecot_protection: true,
        ftp_protection: false,
    },
    KERNELCARE: {
        edf: true,
    },
    MOD_SEC: {
        app_specific_ruleset: false,
        ruleset: RuleSet.FULL,
        cms_account_compromise_prevention: false,
    },
    SMTP_BLOCKING: {
        enable: false,
        ports: ['1', '2'],
        allow_users: ['u1', 'u2'],
        allow_groups: ['g1', 'g2'],
        allow_local: false,
        redirect: false,
    },
    FIREWALL: {
        port_blocking_mode: PortBlockingMode.allow,
        TCP_IN_IPv4: [],
        TCP_OUT_IPv4: [],
        UDP_IN_IPv4: [],
        UDP_OUT_IPv4: [],
    },
    MY_IMUNIFY: {
        enable: false,
        purchase_page_url: '',
    },
    ...permissions,
};

const adminConfig: AdminConfigType = {
    ...common,
    ADMIN_CONTACTS: {
        emails: ['admin@panel.com'],
    },
};

const noAdminContactsAdminConfig: AdminConfigType = {
    ...common,
    ADMIN_CONTACTS: {
        emails: [],
    },
};

export const responses = {
    malwareHasConflicts: {
        data: {
            ...DEFAULT_DATA,
            items: {
                csf_dos_protector: true,
                scan_inotify_control_panel: true,
                scan_modsec: true,
                scan_pure_ftpd: true,
                webshield_ssl_cache_not_configured: true,
            },
        },
    },
    SMTPBlokingProblems: {
        data: {
            ...DEFAULT_DATA,
            items: {
                smtp_blocking_kernel_support: true,
                smtp_blocking_ip6table_nat: true,
                pam_ftp_not_available: true,
            },
        },
    },
    SMTPWarningProblems: {
        data: {
            ...DEFAULT_DATA,
            items: {
                smtp_blocking_ip6table_nat: true,
            },
        },
    },
    LVEOrcGroupsWarningProblems: {
        data: {
            ...DEFAULT_DATA,
            items: {
                scan_intensity_io_cfq: true,
            },
        },
    },
    malwareHasNoConflicts: {
        data: {
            ...DEFAULT_DATA,
            items: {},
        },
    },
    // not to mix mocks for unit and e2e tests
    showUI: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                PERMISSIONS: {
                    ...permissions,
                    advisor: true,
                },
            },
        },
    },
    show: {
        data: {
            ...DEFAULT_DATA,
            items: adminConfig,
        },
    },
    showWithNotify: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                MALWARE_SCANNING: {
                    enable: true,
                    ports: ['4', '5'],
                    allow_users: ['user1', 'user2'],
                    allow_groups: ['group1', 'group2'],
                    allow_local: true,
                    redirect: true,
                },
            },
        },
    },
    enableSmtp: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                SMTP_BLOCKING: {
                    enable: true,
                    ports: ['4', '5'],
                    allow_users: ['user1', 'user2'],
                    allow_groups: ['group1', 'group2'],
                    allow_local: true,
                    redirect: true,
                },
            },
        },
    },
    showRestoreFromBackupFirstTrue: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                MALWARE_SCANNING: {
                    try_restore_from_backup_first: true,
                    default_action: DefaultAction.notify,
                },
            },
        },
    },
    notification: {
        data: {
            ...DEFAULT_DATA,
            items: {
                admin: {
                    default_emails: ['admin@panel.com'],
                    notify_from_email: 'admin@panel.com',
                    locale: 'en',
                },
                rules: notificationRules,
            },
        },
    },
    noLocaleNotification: {
        data: {
            ...DEFAULT_DATA,
            items: {
                admin: {
                    default_emails: ['admin@panel.com'],
                    notify_from_email: 'admin@panel.com',
                },
                rules: notificationRules,
            },
        },
    },
    notificationForAv: {
        data: {
            ...DEFAULT_DATA,
            items: {
                rules: {
                    USER_SCAN_STARTED: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                    CUSTOM_SCAN_STARTED: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                    USER_SCAN_FINISHED: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                    CUSTOM_SCAN_FINISHED: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                    CUSTOM_SCAN_MALWARE_FOUND: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                    USER_SCAN_MALWARE_FOUND: {
                        SCRIPT: {
                            enabled: true,
                            scripts: ['/qwe'],
                        },
                    },
                },
            },
        },
    },
    showNoAdminContacts: {
        data: {
            ...DEFAULT_DATA,
            items: noAdminContactsAdminConfig,
        },
    },
    webshieldEnabledAndMinimalModSec: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                WEBSHIELD: {
                    enable: true,
                    known_proxies_support: false,
                    splash_screen: true,
                    panel_protection: true,
                },
                MOD_SEC: {
                    ruleset: RuleSet.MINIMAL,
                },
            },
        },
    },

    minimalModSec: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                MOD_SEC: {
                    ruleset: RuleSet.MINIMAL,
                },
            },
        },
    },
    rapidScanEnabled: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                MALWARE_SCANNING: {
                    rapid_scan: true,
                },
            },
        },
    },

    webshieldEnabled: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                WEBSHIELD: {
                    enable: true,
                    known_proxies_support: false,
                    splash_screen: true,
                    panel_protection: true,
                },
            },
        },
    },

    firewallBlockingModeDeny: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                FIREWALL: {
                    port_blocking_mode: PortBlockingMode.deny,
                },
            },
        },
    },

    firewallBlockingModeDenywithPorts: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...adminConfig,
                FIREWALL: {
                    port_blocking_mode: PortBlockingMode.deny,
                    TCP_IN_IPv4: [ '1234-43', '12313-123' ],
                    TCP_OUT_IPv4: [ '1234-43', '12313-123' ],
                    UDP_IN_IPv4: [ '1234-43', '12313-123' ],
                    UDP_OUT_IPv4: [ '1234-43', '12313-123' ],
                },
            },
        },
    },

    userside: {
        data: {
            ...DEFAULT_DATA,
            items: userConfig,
        },
    },

    av: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ERROR_REPORTING: {
                    enable: true,
                },
                MALWARE_SCAN_SCHEDULE: {
                    interval: ScheduleInterval.none,
                },
                MALWARE_CLEANUP: {
                    trim_file_instead_of_removal: true,
                    keep_original_files_days: 14,
                },
                MALWARE_SCANNING: {
                    rapid_scan: false,
                    sends_file_for_analysis: true,
                },
                MALWARE_SCAN_INTENSITY: {
                    cpu: 7,
                    io: 7,
                },
                MY_IMUNIFY: {
                    enable: false,
                    purchase_page_url: '',
                },
            },
        },
    },

};
