import {
    forwardRef, AfterContentInit, ContentChildren, Directive, QueryList,
} from '@angular/core';

import { MatCheckbox } from '@angular/material/checkbox';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Directive({
    selector: 'i360-checkbox-group',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxGroupDirective),
        multi: true,
    }],
})
export class CheckboxGroupDirective implements AfterContentInit, ControlValueAccessor {
    values: string[];
    @ContentChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
    forEachQueue: any[] = [];
    onChangeFn = val => {};

    writeValue(obj: string[]): void {
        if (!Array.isArray(obj)) {
            obj = [];
        }
        this.values = obj;
        this.forEachCheckbox(checkbox => {
            checkbox.checked = this.values.includes(checkbox.name);
        });
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.forEachCheckbox(checkbox => checkbox.registerOnTouched(fn));
    }
    setDisabledState(isDisabled: boolean): void {
        this.forEachCheckbox(checkbox => checkbox.setDisabledState(isDisabled));
    }
    ngAfterContentInit() {
        this.forEachCheckbox(checkbox => {
            checkbox.checked = this.values.includes(checkbox.name);
            checkbox.change.subscribe(({checked}) => {
                if (checked) {
                    this.values = [...this.values, checkbox.name];
                } else {
                    this.values = this.values.filter(value => value !== checkbox.name);
                }
                this.onChangeFn(this.values);
            });
        });
    }

    /**
     * can be called before content initialized. anyway, function will be executed after
     * ngAfterContentInit
     */
    forEachCheckbox(fn: any) {
        this.forEachQueue.push(fn);
        if (this.checkboxes) {
            this.checkboxes.forEach((...args) => this.forEachQueue.forEach(fn => fn(...args)));
            this.forEachQueue = [];
        }
    }
}
