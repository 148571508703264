import {
    AgentResponse,
    AgentResponseData,
    RequestParams,
    DataFrom,
} from './misc';

export interface MyImunifyResult extends AgentResponseData<MyImunifyItem[]> {
    myimunify_enabled: boolean;
    is_freemium: boolean;
    purchase_page_url: string;
}


interface MyImunifyItem {
    username: string;
    protection: boolean;
}

type MyImunifyStatusAgentResponse = AgentResponse<MyImunifyResult>;

export class MyImunifyAPI {

    static status(params =  {}) {
        return new RequestParams<DataFrom<MyImunifyStatusAgentResponse>>(['myimunify', 'status'], params);
    }

    static update(params) {
        return new RequestParams<DataFrom<void>>(['myimunify', 'update'], params);
    }

    static enableAll() {
        return new RequestParams<DataFrom<void>>(['myimunify', 'enable-all']);
    }
}
