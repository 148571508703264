import { NgModule } from '@angular/core';
import { FormsModule, UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from 'app/utils/validator/error.component/error.component';
import { ErrorPipe } from 'app/utils/validator/error.pipe';
import { I360FormBuilder } from 'app/utils/validator/form-builder';
import { ExtendFormControlNameDirective }
from 'app/utils/validator/extend-form-control-name.directive';
import { ExtendFormGroupDirective } from 'app/utils/validator/extend-form-group.directive';
import { CommonModule } from '@angular/common';
import { SelectFormControlNameDirective } from 'app/utils/validator/select-form-control-name';


const publicDirectives = [
    ExtendFormControlNameDirective,
    ExtendFormGroupDirective,
    SelectFormControlNameDirective,
    ErrorComponent,
];
const privateDirectives = [
    ErrorPipe,
];



@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        ...publicDirectives,
    ],
    declarations: [
        ...publicDirectives, ...privateDirectives,
    ],
    providers: [
        { provide: UntypedFormBuilder, useExisting: I360FormBuilder },
        I360FormBuilder,
    ]
})
export class I360ValidationModule {}
