import { of, timer, Observable, catchError, map, retryWhen, scan, switchMap, firstValueFrom } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
    AgentResponse, ApiMethod, ExtendedRequestParams, ListResponseData, NormalizedAgentResponse,
    Result,
} from '@imunify360-api/misc';
import { XhrReporterService } from 'app/services/xhr-reporter';
import { XhrNotificator } from 'app/utils/xhr/notificator';
import { ErrorHandler } from 'app/utils/xhr/error-handler';
import { getCaller } from 'app/utils/helper';
import { MockProvider } from 'app/utils/xhr/mock-provider';


declare let ENV: string;

@Injectable({
    providedIn: 'root',
})
export class XHR {
    loaded = new EventEmitter();

    constructor(
        public http: HttpClient,
        public errorHandler: ErrorHandler,
        public xhrNotificator: XhrNotificator,
    ) {}

    post<R = any>(
        params: ExtendedRequestParams<R> | FormData,
        mock: () => Observable<R> | null = () => null,
        notifyOnWarning = true,
        notifyOnError = true,
    ): Observable<NormalizedAgentResponse<R>> {
        let startDate: number = 0;
        let post = of(null).pipe(
            switchMap(() => {
                startDate = Date.now();
                // fast attempt to call interceptors when testMode=true
                // can be broken with async interceptors and parallel requests
                // but for now all interceptors are sync, so request should not be called
                // when mock is applied
                const mocked = mock();
                if (mocked) {
                    const prevHandle = this.http['handler'].backend.handle;
                    this.http['handler'].backend.handle = () => {
                        this.http['handler'].backend.handle = prevHandle;
                        return mocked.pipe(
                            switchMap(res => {
                                return of(new HttpResponse({
                                    body: res,
                                }));
                            }),
                        );
                    };
                }
                return this.http.post('', params);
            }),
        );
        if (ENV === 'development') {
            post = post.pipe(retryWhen(error => {
                return error.pipe(
                    scan((acc, res: any | null) => {
                        acc.res = res;
                        acc.acc += 1;
                        return acc;
                    }, {acc: 0, res: null}),
                    switchMap((acc: any) => {
                        if (acc.acc < 3) {
                            return timer(2000);
                        } else {
                            throw acc.res;
                        }
                    }),
                );
            }));
        }

        return post.pipe(
            catchError(async err => {
                this.xhrNotificator.showResponseErrors(err);
                throw err;
            }),
            map((rawResponse: AgentResponse<R>) => {
                const messages = rawResponse.messages;
                const response: ExtendedAgentResponse<R> = {
                    data: rawResponse.data,
                    result: rawResponse.result,
                    messages: messages
                        ? typeof messages === 'string' ? [messages] : messages
                        : [],
                };

                if (TEST) {
                    XhrReporterService.report(params, response, startDate);
                }
                this.loaded.emit();
                switch (response.result) {
                    case Result.SUCCESS:
                        return response;
                    case Result.WARNING:
                        response.noSentry = true;
                        if (notifyOnWarning) {
                            this.xhrNotificator.showResponseErrors(response);
                        }
                        throw response;
                    case Result.ERROR:
                        response.noSentry = true;
                        if (notifyOnError) {
                            this.xhrNotificator.showResponseErrors(response);
                        }
                        throw response;
                    default:
                        // For success cli may not return result key
                        return response;
                }
            }),
            catchError(error => {
                return this.errorHandler._throw(error);
            }),
        );
    }

    /**
     * Wraps API method to create a service method.
     */
    rx<T, R>(
        api: ApiMethod<T, R>,
        notifyOnWarningDefault: boolean = true,
        notifyOnErrorDefault: boolean = true,
    ) {
        const className = getCaller();
        return (
            params?: T,
            notifyOnWarning: boolean = notifyOnWarningDefault,
            notifyOnError: boolean = notifyOnErrorDefault,
        ) => {
            const requestParams = api(params);
            return this.post<R>(
                requestParams,
                () => MockProvider.getMock(requestParams, params, className),
                notifyOnWarning,
                notifyOnError,
            );
        };
    }

    /**
     * @deprecated Use rx instead
     */
    request<T, R>(
        api: ApiMethod<T, R>,
        notifyOnWarningDefault: boolean = true,
        notifyOnErrorDefault: boolean = true,
    ) {
        const className = getCaller();
        // NOTE: we cannot use "...args" instead of "parameters", because of this:
        // https://github.com/Microsoft/TypeScript/issues/5453
        // So, we won't be able to pass types unless we do smth like this:
        // https://github.com/Microsoft/TypeScript/blob/master/lib/lib.es2015.promise.d.ts#L41-L113
        return (
            params?: T,
            notifyOnWarning: boolean = notifyOnWarningDefault,
            notifyOnError: boolean = notifyOnErrorDefault,
        ) => {
            const requestParams = api(params);
            return firstValueFrom(this.post<R>(
                requestParams,
                () => MockProvider.getMock(requestParams, params, className),
                notifyOnWarning,
                notifyOnError,
            ), { defaultValue: {} as R  });
        };
    }

    /**
     * converts items in AgentResponse from list of P to list of itemClass
     */
    wrap<E, P, R>(serviceMethod: (params: E) => Observable<AgentResponse<ListResponseData<P>>>,
                  itemClass: {new(data: P): R}):
        (params: E) => Observable<AgentResponse<ListResponseData<R>>> {
        return (params: E) => {
            return serviceMethod(params).pipe(
                map(({data, result, messages}) => ({
                        data: {
                            items: data.items.map(item => new itemClass(item)),
                            version: data.version,
                            strategy: data.strategy,
                            license: data.license,
                            eula: data.eula,
                            max_count: 'max_count' in data ? data.max_count : data.items.length,
                        },
                        result,
                        messages,
                    }),
                ));
        };
    }
}

export interface ExtendedAgentResponse<R> extends NormalizedAgentResponse<R> {
    noSentry?: boolean;
}
