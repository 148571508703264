import { IPList } from '../lists';
import { Mode } from '../proactive-defense';
import { DEFAULT_DATA } from './misc';

export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            max_count: 10,
            items: [
                {
                    id: 20,
                    timestamp: 1525759128,
                    rule_id: null,
                    rule_name: 'PHP.Inject',
                    path: '/home/customer/public_html/index.php',
                    count: 3,
                    host: 'customer.com',
                    ip: '10.10.0.1',
                    listname: IPList.white,
                    purpose: IPList.white,
                    action: Mode.log,
                },
                {
                    id: 21,
                    timestamp: 1525759321,
                    rule_id: 1,
                    rule_name: 'PHP.Inject',
                    path: '/home/example/public_html/index-zero.php',
                    count: 5,
                    host: 'example.com',
                    ip: '192.168.1.3',
                    purpose: null,
                    listname: null,
                    action: Mode.kill,
                },
                ...Array(...Array(10)).map((_, i) => ({
                    id: 100 + i,
                    timestamp: 1525759321 + i * 10000,
                    rule_id: 2,
                    rule_name: 'FS.Deploy',
                    path: `/home/example/public_html/index-${i}.php`,
                    count: i % 5 + 1,
                    host: 'example.com',
                    ip: '192.168.1.3',
                    purpose: null,
                    listname: null,
                    action: Mode.kill,
                })),
                {
                    id: 42,
                    timestamp: 43,
                    rule_id: 44,
                    rule_name: '45',
                    path: '46',
                    count: 47,
                    host: null,
                    ip: null,
                    purpose: null,
                    listname: null,
                    action: Mode.log,
                },
            ],
        },
    },
    details: {
        data: {
            ...DEFAULT_DATA,
            items: {
                id: 20,
                timestamp: 1525759128,
                rule_id: 1,
                rule_name: `PHP.${'InjectPHP'.repeat(10)}`,
                description: 'Long description on what exactly that recognizer.'.repeat(15),
                path: `/var/www/vhosts/auser1.com/${'long'.repeat(100)}/hack1526573364.php`,
                url: '/index.php',
                ip: '10.10.0.1',
                country: 'CN',
                count: 3,
                action: 'LOG',
                purpose: 'white',
                listname: 'gray',
                env: {
                    HTTP_HOST: 'example.com',
                    REMOTE_ADDR2: '10.10.0.1',
                    HTTP_HOST2: 'example.com',
                    REM23OTE_ADDR: '10.10.0.1',
                    HTTP_swHOST: 'example.com',
                    REMOTEs_ADDR: '10.10.0.1',
                    HTTP_HdsOST: 'example.com',
                    REMOTE_sADDR: '10.10.0.1',
                    HTTP_HOSdT: 'example.com',
                    REMOTE_AwDDR: '10.10.0.1',
                    ['LONG_REMOTE_ADDR'.repeat(10)]: `10.10.0.1${'REMOTE_ADDR'.repeat(10)}`,
                },
            },
        },
    },
    detailsShort: {
        data: {
            ...DEFAULT_DATA,
            items: {
                id: 20,
                timestamp: 1525759128,
                rule_id: 12,
                rule_name: `PHP.InjectPHP`,
                description: 'some description',
                path: `/var/www/vhosts/auser1.com/hack1526573364.php`,
                url: '/index.php',
                ip: '10.10.0.1',
                country: 'CN',
                count: 4,
                action: 'KILL',
                purpose: 'gray',
                listname: 'gray',
                env: {
                    HTTP_HOST: 'example.com',
                    REMOTE_ADDR2: '10.10.0.1',
                    HTTP_HOST2: 'example.com',
                    REM23OTE_ADDR: '10.10.0.1',
                    HTTP_swHOST: 'example.com',
                    REMOTEs_ADDR: '10.10.0.1',
                    HTTP_HdsOST: 'example.com',
                    REMOTE_sADDR: '10.10.0.1',
                    HTTP_HOSdT: 'example.com',
                    REMOTE_AwDDR: '10.10.0.1',
                },
            },
        },
    },
    detailsNoIp: {
        data: {
            ...DEFAULT_DATA,
            items: {
                id: 20,
                timestamp: 1525759128,
                rule_id: 12,
                rule_name: `PHP.InjectPHP`,
                description: 'some description',
                path: `/var/www/vhosts/auser1.com/hack1526573364.php`,
                url: '/index.php',
                ip: null,
                country: null,
                count: 4,
                action: 'KILL',
                purpose: null,
                listname: null,
                env: {
                    HTTP_HOST: 'example.com',
                    REMOTE_ADDR2: '10.10.0.1',
                    HTTP_HOST2: 'example.com',
                    REM23OTE_ADDR: '10.10.0.1',
                    HTTP_swHOST: 'example.com',
                    REMOTEs_ADDR: '10.10.0.1',
                    HTTP_HdsOST: 'example.com',
                    REMOTE_sADDR: '10.10.0.1',
                    HTTP_HOSdT: 'example.com',
                    REMOTE_AwDDR: '10.10.0.1',
                },
            },
        },
    },
    ignoreList: {
        data: {
            ...DEFAULT_DATA,
            max_count: 10,
            items: [
                {
                    timestamp: 1525759128,
                    path: '/home/customer/public_html/index.php',
                    user: 'john',
                    rules: [...Array(...Array(10)).map((_, i) => ({
                        id: i,
                        name: `Rule ${i}`,
                    })), {
                        id: 10,
                        name: 'Really-really-really-really-really-really-really-really long rule',
                    }],
                },
                {
                    timestamp: 1525759321,
                    path: '/var/www/vhosts/auser1.com/httpdocs/index.html',
                    user: 'bob',
                    rules: [],
                },
            ],
        },
    },
};
