import { HashLocationStrategy, LocationChangeListener, PlatformLocation } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";


@Injectable()
export class ZonedHashLocationStrategy extends HashLocationStrategy {
    constructor(
        private ngZone: NgZone,
        platformLocation: PlatformLocation,
    ) {
        super(platformLocation);
    }
    onPopState(fn: LocationChangeListener): void {
        // Single-Spa triggers the `popstate` event on every navigation to force re-render in other
        // apps: https://single-spa.js.org/docs/api/#popstateevent
        // We must ensure that we stay in our angular zone, whenever it's triggered - otherwise,
        // Angular's change detection will break.
        return super.onPopState((...args) => this.ngZone.run(() => fn(...args)));
    }
}
