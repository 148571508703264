import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Eula, EulaAPI, EulaInBackend } from '@imunify360-api/eula';
import { testMode } from 'app/services/misc';
import { XHR } from 'app/utils/xhr/xhr';
import { LicenseState } from 'app/services/license-state';

const testData = {
    message: 'That is test mode for EULA license',
    text:    'Just test message',
    updated: '2017-11-30',
};

@Injectable({
    providedIn: 'root'
})
export class EulaService {
    accept = this.xhr.request(EulaAPI.accept);

    constructor(
        private router: Router,
        public xhr: XHR,
        private route: ActivatedRoute,
        public licenseState: LicenseState,
    ) {}

    getEula(data: EulaInBackend): Eula {
        return new Eula(testMode(this.constructor.name) ? testData : data);
    }

    checkEulaStatus(data: EulaInBackend) {
        const oldStatus = this.licenseState.eula.value.isFailed();
        const eula = this.getEula(data);
        if (oldStatus !== eula.isFailed()) {
            this.licenseState.eula.next(eula);
        }

        const eulaUrl = ['/', IMUNIFY_PACKAGE, 'admin', 'eula'];
        const eulaUrlString = `/${eulaUrl.slice(1).join('/')}`;
        if (this.licenseState.eula.value.isFailed() && this.router.url !== eulaUrlString) {
            this.router.navigate(eulaUrl, {
                relativeTo: this.route
            });
            return false;
        } else if (!this.licenseState.eula.value.isFailed() && this.router.url === eulaUrlString) {
            this.router.navigate(['/']);
        }
        return true;
    }
}
