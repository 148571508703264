import { Injectable } from '@angular/core';
import { FeaturesManagementAPI } from '@imunify360-api/features-management';
import { XHR } from 'app/utils/xhr/xhr';


@Injectable()
export class UserFeaturesStatusService {
    public getClientFeatures = this.xhr.rx(FeaturesManagementAPI.clientFeatures);

    constructor(
        public xhr: XHR,
    ) {}
}
