import { forwardRef, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { identity } from 'rxjs';

const separatedRegExp = {
    byComma: {
        joinBy: ', ',
        splitBy: /[,\n\s]/,
    },
    byEnter: {
        joinBy: '\n',
        splitBy: /[\n]/,
    },
};

enum SeparatedBy {
    byComma = 'byComma',
    byEnter = 'byEnter',
}

@Directive({
    selector: '[commaSeparatedInput][formControlName]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CommaSeparatedInputDirective),
        multi: true,
    }],
})
export class CommaSeparatedInputDirective implements ControlValueAccessor {
    @Input() splitBy: SeparatedBy = SeparatedBy.byComma;
    @Input() isFilter: boolean = false;
    private onChangeFn = identity;

    constructor(
        public element: ElementRef,
        private renderer: Renderer2,
    ) {}

    writeValue(value: string[]): void {
        if (!Array.isArray(value)) {
            if (this.isFilter) {
                value = [value];
            } else {
                value = [];
            }
        }

        this.renderer
            .setProperty(this.element.nativeElement, 'value', value.join(
                separatedRegExp[this.splitBy].joinBy,
            ));
    }

    registerOnChange(fn) {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
    }

    @HostListener('input', ['$event'])
    handleInput(event) {
        const emails = event.target.value
            .split(
                separatedRegExp[this.splitBy].splitBy,
            ).map((value) => value.trim())
            .filter(identity);

        this.onChangeFn(emails);
    }
}
