import { UntypedFormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { FormControlState, I360FormControl } from 'app/utils/validator/form-control';
import { FormGroupConfig, I360FormGroup } from 'app/utils/validator/form-group';

@Injectable()
export class I360FormBuilder extends UntypedFormBuilder {
    control<E>(state: FormControlState<E> | null, validators?, asyncValidators?) {
        return new I360FormControl(state, validators, asyncValidators);
    }
    group<E, C extends FormGroupConfig<E>>(value: C, extra?) {
        const validator = extra != null ? extra['validator'] : null;
        const asyncValidator = extra != null ? extra['asyncValidator'] : null;
        return new I360FormGroup<E, C>(value, validator, asyncValidator);
    }
}
