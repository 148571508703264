import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { I360ModalModule } from 'app/utils/modals/modal.module';
import { I360ValidationModule } from 'app/utils/validator/validation.module';
import {
    AdminContactsConfirmModalComponent,
} from './admin-contacts-confirm-modal/admin-contacts-confirm-modal.component';
import {
    AdminContactsRequestModalComponent,
} from './admin-contacts-request-modal/admin-contacts-request-modal.component';
import {
    NewsAdminContactsComponent,
} from './news-items/news-admin-contacts/news-admin-contacts.component';
import { NewsLicenseComponent } from './news-items/news-license/news-license.component';
import { NewsNotificationsComponent } from './news-notifications/news-notifications.component';
import { NewsOverlayComponent } from './news-overlay/news-overlay.component';
import { NewsMessageComponent } from './news-items/news-message/news-message.component';
import { NewsComponent } from './news-component/news.component';
import { NewsService } from './news.service';
import {
    I360CommaSeparatedInputModule,
} from 'app/utils/comma-separated-input/comma-separated-input.module';
import { AdminContactsService } from 'app/services/admin-contacts';


@NgModule({
    imports: [
        CommonModule,
        I360ValidationModule,
        I360TranslateModule,
        PortalModule,
        RouterModule,
        I360ModalModule,
        MatIconModule,
        I360CommaSeparatedInputModule,
    ],
    declarations: [
        NewsComponent,
        NewsNotificationsComponent,
        NewsOverlayComponent,
        AdminContactsRequestModalComponent,
        AdminContactsConfirmModalComponent,
        NewsLicenseComponent,
        NewsMessageComponent,
        NewsAdminContactsComponent,
    ],
    providers: [
        AdminContactsService,
        NewsService,
    ],
    exports: [
        NewsComponent,
    ]
})
export class NewsModule {}
