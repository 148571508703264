import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';


export interface PackageVersions {
    'imunify-ui': string | null;
    'imunify-antivirus': string | null;
    'imunify360-firewall': string | null;
    'imunify-core': string | null;
    [key: string]: string | null;
}

type GetPackageVersionsAgentResponse = AgentResponse<AgentResponseData<PackageVersions | null>>;


export class ImunifyPackageVersions {
    static getPackageVersion = () =>
        new RequestParams<DataFrom<GetPackageVersionsAgentResponse>>(['get-package-versions']);
}
