import { AbstractControl, UntypedFormGroup } from '@angular/forms';

interface Control<E> extends AbstractControl {
    value: E;
}

type Field<E, K extends keyof E> = E[K];

export type FormGroupConfig<E> = {
    [K in keyof E]: Control<E[K]>;
};

export type Value<E, C extends FormGroupConfig<E>> = {
    [K in keyof C]: Field<C[K], 'value'>;
};

enum MarkAsCmd {
    markAsPristine = 'markAsPristine',
    markAsDirty = 'markAsDirty',
}

export class I360FormGroup<E, C extends FormGroupConfig<E>> extends UntypedFormGroup {
    value: Value<E, C>;
    controls: C;
    constructor(controls: C, ...args) {
        super(controls, ...args);
    }
    markAs(cmd: MarkAsCmd, opts?) {
        super[cmd](opts);
        if (opts && opts.children) {
            Object.keys(this.controls).forEach(key => {
                const control = this.controls[key];
                control[cmd]({onlySelf: true, ...opts});
                // this is actually event emitter
                (<any>control.statusChanges).emit(control.value);
            });
        }
    }
    markAsDirty(opts?) {
        this.markAs(MarkAsCmd.markAsDirty, opts);
    }
    markAsPristine(opts?) {
        this.markAs(MarkAsCmd.markAsPristine, opts);
    }
    getDirtyValues(form = this) {
        let dirtyValues = {};
        Object.keys(form.controls)
            .forEach(key => {
                const currentControl = form.controls[key];
                if (currentControl.dirty) {
                    dirtyValues[key] = currentControl.controls
                        ? this.getDirtyValues(currentControl)
                        : currentControl.value;
                }
            });
        return dirtyValues;
    }
    setValue(value: Value<E, C>) {
        return super.setValue(value);
    }
    patchValue(
        value: Partial<Value<E, C>>,
        options?: {onlySelf?: boolean; emitEvent?: boolean; }): void {
        return super.patchValue(value);
    }
}
