import {
    AgentResponse, AgentResponseData, DataFrom, ListResponseData,
    NullAgentResponse, RequestParams,
} from './misc';
import { toIpListOrNull, IPList } from './lists';


export enum Mode {
    disabled = 'DISABLED',
    log = 'LOG',
    kill = 'KILL',
    block = 'BLOCK',
}


export interface ProactiveDefenseIgnoreData {  // data used to add an item to ignore list
    path: string;
    rule_id?: number;
    rule_name?: string;
}

interface ProactiveDefenseItemInBackend {
    id: number;
    timestamp: number;
    rule_id: number | null;  // tslint:disable-line:variable-name
    rule_name: string;  // tslint:disable-line:variable-name
    path: string;
    count: number;
    host: string | null;
    ip: string | null;
    // here listname is uppercase, but in incidents it is lowercase...
    listname: string | null;
    purpose?: IPList | null;
    action: Mode;
}

export class ProactiveDefenseItem {
    id: number;
    timestamp: number;
    rule_id: number | null;  // tslint:disable-line:variable-name
    rule_name: string;  // tslint:disable-line:variable-name
    path: string;
    count: number;
    host: string | null;
    ip: string | null;
    listname: IPList | null;
    purpose: IPList | null;
    action: Mode;
    constructor(data: ProactiveDefenseItemInBackend) {
        this.id = data.id;
        this.timestamp = data.timestamp;
        this.rule_id = data.rule_id;
        this.rule_name = data.rule_name;
        this.path = data.path;
        this.count = data.count;
        this.host = data.host;
        this.ip = data.ip;
        this.action = data.action;
        this.purpose = (data.purpose)? toIpListOrNull(data.purpose) : null;
        this.listname = toIpListOrNull(data.listname);
    }
}
interface DetailsInBackend {
    id: number;
    timestamp: number;
    rule_id: number | null;
    rule_name: string;
    description: string | null;
    path: string;
    url: string | null;
    ip: string | null;
    country: string | null;
    count: number;
    listname: string | null;
    purpose: string | null;
    action: string;
    env: {[k: string]: string};
}
export class ProactiveDefenseDetails {
    id: number;
    timestamp: number;
    rule_id: number | null;  // tslint:disable-line:variable-name
    rule_name: string;  // tslint:disable-line:variable-name
    description: string | null;
    path: string;
    url: string | null;
    ip: string | null;
    country: string | null;
    count: number;
    listname: IPList | null;
    purpose: IPList | null;
    action: string;
    env: Array<{
        name: string,
        value: string,
    }>;
    constructor({id, timestamp, rule_id, rule_name, description, path, url, ip, country, count, env,
                    action, listname, purpose}
    : DetailsInBackend) {
        this.id = id;
        this.timestamp = timestamp;
        this.rule_id = rule_id;
        this.rule_name = rule_name;
        this.description = description;
        this.path = path;
        this.url = url;
        this.ip = ip;
        this.country = country;
        this.count = count;
        this.listname = toIpListOrNull(listname);
        this.purpose = (purpose)? toIpListOrNull(purpose) : null;
        this.action = action;
        this.env = Object.entries(env).map(([name, value]) => ({name, value}));
    }
}

interface ProactiveDefenseIgnoreItemInBackend {
    path: string;
    timestamp: number;
    rules: Array<{
        id: number,
        name: string,
    }>;
}

export class ProactiveDefenseIgnoreItem {
    path: string;
    timestamp: number;
    rules: Array<{
        id: number,
        name: string,
    }>;
    constructor(data: ProactiveDefenseIgnoreItemInBackend) {
        this.path = data.path;
        this.timestamp = data.timestamp;
        this.rules = data.rules;
    }
}


export class ProactiveDefenseAPI {
    static list = (params = {}) =>
        new RequestParams<DataFrom<ProactiveDefenseListAgentResponse>>
        (['proactive', 'list'], params)
    static details = (id: any) =>
        new RequestParams<DataFrom<ProactiveDefenseDetailsAgentResponse>>
        (['proactive', 'details'], {id})

    static ignoreList(params = {}) {
        return new RequestParams<DataFrom<ProactiveDefenseIgnoreAgentResponse>>(
            ['proactive', 'ignore', 'list'],
            params);
    }
    static ignoreAdd = (
        params: {items: ProactiveDefenseIgnoreData[]} = {items: []},
    ) => new RequestParams<DataFrom<NullAgentResponse>>(['proactive', 'ignore', 'addmany'], params)
    static ignoreDeletePaths = (params: {paths: string[]}) =>
        new RequestParams<DataFrom<NullAgentResponse>>
        (['proactive', 'ignore', 'delete', 'path'], params)
    static ignoreDeleteRule = (params = {}) => new RequestParams<DataFrom<NullAgentResponse>>
        (['proactive', 'ignore', 'delete', 'rule'], params)
}

type ProactiveDefenseListAgentResponse
    = AgentResponse<AgentResponseData<ProactiveDefenseItemInBackend[]>>;

type ProactiveDefenseDetailsAgentResponse = AgentResponse<AgentResponseData<DetailsInBackend>>;

type ProactiveDefenseIgnoreAgentResponse
    = AgentResponse<ListResponseData<ProactiveDefenseIgnoreItemInBackend>>;
