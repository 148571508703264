import { Injectable, OnDestroy } from '@angular/core';
import { XHR } from 'app/utils/xhr/xhr';
import {
    Observable, noop, timer, of,
    switchMap, map, take, catchError, takeUntil, BehaviorSubject,
} from 'rxjs';
import {
    MyImunifyAPI,
    MyImunifyResult,
} from '@imunify360-api/myimunify';
import { AuthState } from 'app/services/auth-state';
import { MyImunifyState, IMyImunifyState } from 'app/services/my-imunify-state';
import { NotificationsService } from 'app/services/notifications';
import { Package } from '@imunify360-api/license';

export const MYIMUNIFY_UPDATE_INTERVAL = 10000;

@Injectable()
export class MyImunifyService implements OnDestroy {
    status = this.xhr.rx(MyImunifyAPI.status);
    update = this.xhr.rx(MyImunifyAPI.update);
    enableProtectionForAll = this.xhr.rx(MyImunifyAPI.enableAll);

    // This behaviour subject is used to mark when the myimunify state loaded
    myImunifyStateLoaded$ = new BehaviorSubject<boolean>(false);

    // Store the opened purchase window
    purchaseWindow: Window | null;

    constructor(
        private xhr: XHR,
        private authState: AuthState,
        private state: MyImunifyState,
        private notifications: NotificationsService,
    ) {}

    ngOnDestroy(): void {
        this.state.myImunifyStateSubscription?.unsubscribe();
        this.state.myImunifyStateSubscription = undefined;
    }

    openUpgradePage(): void {
        this.getMyImunifyState().pipe(take(1)).subscribe(state => {
            if (!state.purchase_page_url) {
                this.notifications.error('notifications.myImunifyPageNotSpecified');
            } else {
                if (this.purchaseWindow) return;
                // Open the purchaseWindow in new window and check until closed
                this.purchaseWindow = window.open(this.getFixedPurchaseUrl(state.purchase_page_url),
                    '', 'toolbar=0,status=0,width=1100,height=640');
                const checkIfPurchaseWindowClosed = () => {
                    if (this.purchaseWindow?.closed) {
                        this.purchaseWindow = null;
                        clearInterval(checkWindowClosedInterval);
                    }
                };
                const checkWindowClosedInterval = setInterval(checkIfPurchaseWindowClosed.bind(this), 500);
            }
        });
    }

    getMyImunifyState(): Observable<IMyImunifyState> {
        if (!this.state.myImunifyStateSubscription && !window['MYIMUNIFY_DISABLED']) {
            this.state.myImunifyStateSubscription = this.authState.isClient
                .pipe(
                    switchMap(isClient => {
                        if (isClient && IMUNIFY_PACKAGE === Package.imunify360) {
                            return this.poolMyImunifyStatus();
                        } else {
                            this.state.myImunifyStateSubscription?.unsubscribe();
                            this.state.myImunifyStateSubscription = undefined;
                            this.myImunifyStateLoaded$.next(true);
                            return of(null);
                        }
                    }),
                )
                .subscribe({ next: noop, error: noop });
        }

        return this.state.changes$.asObservable();
    }

    getStatus(): Observable<MyImunifyResult> {
        const params = {items: []};
        return this.status(params).pipe(map(result => result?.data));
    }

    private poolMyImunifyStatus(): Observable<void> {
        return timer(0, MYIMUNIFY_UPDATE_INTERVAL).pipe(
            switchMap(() => this.getStatus()),
            catchError(() => of(undefined)),
            map(result => this.handleMyImunifyStatusResult(result)),
            takeUntil(this.state.isEnabledWithProtection$),
        );
    }

    private handleMyImunifyStatusResult(result: MyImunifyResult | undefined): void {
        if (!result) return;

        const state = this.state.changes$.getValue();
        const item = result.items.length ? result.items[0] : null;

        this.myImunifyStateLoaded$.next(true);

        if (state.enabled !== result.myimunify_enabled ||
            state.protection !== item?.protection ||
            state.purchase_page_url !== result.purchase_page_url) {

            this.state.changes$.next({
                enabled: result.myimunify_enabled,
                protection: !!item?.protection,
                purchase_page_url: result.purchase_page_url,
                is_freemium: result.is_freemium,
            });
            if (result.myimunify_enabled && !!item?.protection) {
                this.state.isEnabledWithProtection$.next();
            }
        }
    }

    public getFixedPurchaseUrl(urlToFix: string): string {
        if (urlToFix.startsWith('http')) {
            return urlToFix;
        }

        return 'https://' + urlToFix;
    }
}
