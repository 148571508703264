import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalEntry } from 'app/utils/modals/entry';

export type FileViewerOptions = {
    chunk: string,
    size: number,
    limit: number,
    path: string,
};

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent extends ModalEntry<FileViewerOptions, false> {
    constructor(@Inject(MAT_DIALOG_DATA) public data: FileViewerOptions) {
        super();
    }
}
