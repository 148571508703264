import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { postOnly } from 'app/interceptors/utils';

@Injectable()
export class JsonHeaderInterceptor implements HttpInterceptor {
    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers;
        if (!(req.body instanceof FormData)) {
            headers = req.headers
                .set('Content-type', 'application/json; charset=utf-8');
        }
        return next.handle(req.clone({headers}));
    }
}
