import { AgentResponse, DataFrom, ExtendedRequestParams } from './misc';
import { BlockedPort } from './blocked-ports';


interface InstallationStatus {
    log: string;
    offset: number;
}

type InstallationStatusAgentResponse = AgentResponse<{items: InstallationStatus}>;

export class InstallationAPI {

    static start = () => <ExtendedRequestParams<undefined>> {
        command: 'installation',
        method: ['start'],
        params: {},
    }

    static status = ({offset, limit}: {offset: number, limit: number}) => <ExtendedRequestParams<
        DataFrom<InstallationStatusAgentResponse>>> {
        command: 'installation',
        method: ['status'],
        params: { offset, limit },
    }
}
