import { AgentResponse, AgentResponseData, DataFrom, RequestParams } from './misc';


export class NotificationSettingsAPI {

    static show() {
        return new RequestParams<DataFrom<NotificationConfigAgentResponse>>(
            ['notifications-config', 'show']);
    }

    static save(params: Object) {
        return new RequestParams<DataFrom<NotificationConfigAgentResponse>>(
            ['notifications-config', 'patch'], { data: params });
    }
}

type AdminType = {
    ADMIN?: {
        admin_emails: string[]
        enabled: boolean,
        period?: number,
    },
};

type ScriptType = {
    SCRIPT: {
        scripts: string[]
        enabled: boolean,
        period?: number,
    },
};

type AdminAndScriptTypes = AdminType & ScriptType;

export type NotificationRulesType = {
    REALTIME_MALWARE_FOUND?: AdminAndScriptTypes,
    USER_SCAN_STARTED: ScriptType,
    CUSTOM_SCAN_STARTED: ScriptType,
    USER_SCAN_FINISHED: ScriptType,
    CUSTOM_SCAN_FINISHED: ScriptType,
    CUSTOM_SCAN_MALWARE_FOUND: AdminAndScriptTypes,
    USER_SCAN_MALWARE_FOUND: AdminAndScriptTypes,
    SCRIPT_BLOCKED?: AdminAndScriptTypes,
};

export type NotificationConfigType = {
    admin?: {
        default_emails: string[],
        notify_from_email: string | null,
        locale?: string,
        [k: string]: any,
    },
    rules: NotificationRulesType;
};

type NotificationConfigAgentResponse = AgentResponse<AgentResponseData<NotificationConfigType>>;

