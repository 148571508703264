import { ErrorHandler, Injectable } from '@angular/core';
import * as TraceKit from 'raven-js/vendor/TraceKit/tracekit';
import { noSentryErrorMessage } from '../errors';
import { Panel } from 'app/utils/panel';
import { AuthState } from 'app/services/auth-state';
import { TranslateService } from 'app/utils/translate/translate.service';
import { ConfigRef } from 'app/core/config-ref';

declare const I360_HEADLESS: any;

import * as Raven from 'raven-js';

@Injectable()
export class RootErrorHandler implements ErrorHandler {

    active;

    public static fixPaths(text: string): string {
        // Bundles and sourcemaps are not retrievable for sentry due to cpanel auth and IP
        // restrictions, so we replace paths with fixed value and upload those files to the
        // sentry for each release. See also upload_release_to_sentry.sh
        return text.replace(
            /https?:\/\/.*\/imunify(?:360)?\/assets\/static\//g,
            'https://non-existing-domain-for-sentry.cloudlinux.com/',
        ).replace(  // remove hashes to allow building sourcemaps separately, with slight variations
            /\.[\w\d]+\.js\b/,
            '.js',
        );
    }
    constructor(public configRef: ConfigRef,
                private panel: Panel,
                private authState: AuthState,
                private translateService: TranslateService) {
        configRef.configChange.subscribe(config => {
            this.tryInitSentry(config);
        });
    }

    log(msg: string) {
        // do not log in karma
        if (typeof I360_HEADLESS === 'undefined') {
            console.log(msg);
        }
    }

    /**
     * method needed to allow track its call in tests
     * but ravenjs breaks tests down
     */
    tryInitSentry(config) {
        try {
            if (config.ERROR_REPORTING.enable) {
                this.initSentry();
            } else {
                this.terminateSentry();
            }
        } catch (e) {
            // ERROR_REPORTING section is not present
        }
    }
    initSentry() {
        if (TEST) {
            return;
        }

        if (Raven.isSetup()) {
            if (!this.active) {
                this.active = true;
                this.log('SENTRY resumed');
            }
            return;
        }
        Raven.config('https://6369d23c9e9b48a2af3f56c2bd639c24@im360.sentry.cloudlinux.com/18', {
            instrument: {
                tryCatch: false,
            },
            captureUnhandledRejections: false,
            tags: {
                env: 'prod',
                role: this.authState.role.value,
                panel: this.panel.name,
                lang: this.translateService.currentLang,
                panelLang: this.translateService.panelLang,
            },
            ignoreErrors: [noSentryErrorMessage],
            dataCallback: data => {
                if (data.culprit) {
                    data.culprit = RootErrorHandler.fixPaths(data.culprit);
                }
                let stacktrace = data.stacktrace || data.exception
                    && data.exception.values[0].stacktrace;
                if (stacktrace) {
                    stacktrace.frames.forEach((frame) => {
                        frame.filename = RootErrorHandler.fixPaths(frame.filename);
                    });
                }
            },
            maxBreadcrumbs: 50,
            sanitizeKeys: ['password', 'chunk'],
            autoBreadcrumbs: {
                xhr: false,
            },
        }).install();
        TraceKit.report.uninstall();
        Raven.setRelease(VERSION);
        console.assert(Raven.isSetup() === true, 'Raven did not setup');
        this.log('SENTRY initialized');
        this.active = true;
    }

    terminateSentry() {
        if (Raven.isSetup()) {
            this.log('SENTRY terminated');
            this.active = false;
        }
    }

    public handleError(error: any): void {
        this.log('handle error');
        if (error.noSentry || (error.rejection && error.rejection.noSentry)) {
            return;
        }

        if (TEST || !this.active) {
            throw error;
        }

        let originalError = error.originalError;
        if (!originalError) {
            if (error.originalStack) {
                // Sometimes "originalError" is missing,
                // but we still have "originalStack" and "message"
                originalError = new Error(error.message);
                originalError.name = error.name || originalError.name;
                originalError.stack = error.originalStack;
            } else {
                originalError = error;
            }
        }
        Raven.captureException(originalError);
    }

}
