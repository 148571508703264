import { DEFAULT_DATA } from './misc';
import { FeatureStatus } from '../features';

export const responses = {
    kernelCareUnsupported: {
        result: 'error',
        data: {
            ...DEFAULT_DATA,
            items: '',
        },
        messages: [
            'Kernel is unsupported!',
        ],
    },
    kernelCareInstalled: {
        data: {
            ...DEFAULT_DATA,
            items: {
                status: FeatureStatus.installed as FeatureStatus.installed,
                message: 'KernelCare is installed',
            },
        },
    },
    kcarectl: {
        data: {
            items: {
                autoUpdate: true,
                license: 1,
                uptime: '181820',
                realKernel: '3.10.0-957.1.3.el7.x86_64',
                loadedPatchLevel: 7,
                effectiveKernel: '3.10.0-957.1.3.el7',
                updateCode: 0,
            },
        },
    },
};
