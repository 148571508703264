import { DatepickerComponent } from './datepicker.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { I360TranslateModule } from 'app/utils/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        I360TranslateModule
    ],
    declarations: [
        DatepickerComponent
    ],
    exports: [
        DatepickerComponent
    ]
})
export class DatepickerModule {}
