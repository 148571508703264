import { AgentResponse, DataFrom, ListResponseData, RequestParams } from './misc';



export class ReputationAPI {
    static checkDomains = (params = {}) => new RequestParams(['check-domains'], params);
    static infectedDomains = (params = {}) => {
        return new RequestParams<DataFrom<ReputationAgentResponse>>
            (['infected-domains'], params);
    }
}

type ReputationAgentResponse = AgentResponse<ListResponseData<ReputationBackendItem>>;

export enum Vendor {
    google = 'google-safe-browsing',
    spamhaus = 'spamhaus',
    yandex = 'yandex-safe-browsing',
    phishtank = 'phishtank',
    openphish = 'openphish',
    mitchellkrogza = 'mitchellkrogza',
}

const vendorToLinkMapping = {
    [Vendor.google]: 'https://developers.google.com/safe-browsing/',
    [Vendor.yandex]: 'https://tech.yandex.ru/safebrowsing/',
    [Vendor.spamhaus]: 'https://www.spamhaus.org/',
    [Vendor.phishtank]: 'https://www.phishtank.com/',
    [Vendor.openphish]: 'https://openphish.com/',
    [Vendor.mitchellkrogza]: 'https://github.com/mitchellkrogza/Phishing.Database/',
};

const vendorToReadableTextMapping = {
    [Vendor.google]: 'Google',
    [Vendor.yandex]: 'Yandex',
    [Vendor.spamhaus]: 'Spamhaus',
    [Vendor.phishtank]: 'PhishTank',
    [Vendor.openphish]: 'OpenPhish',
    [Vendor.mitchellkrogza]: 'Mitchell Krog',
};

export interface ReputationBackendItem {
    username: string;
    domain: string;
    threats: Array<{
        type: string | null,
        vendor: Vendor,
        timestamp: number, // seconds
    }>;
}

export class ReputationItem {
    username: string;
    domain: string;
    threats: Array<{
        type: string | null,
        vendor: string,
        timestamp: number, // milliseconds
        link: string;
    }>;
    public constructor(data: ReputationBackendItem) {
        this.username = data.username;
        this.domain = data.domain;
        this.threats = data.threats.map(threat => ({
            vendor: vendorToReadableTextMapping[threat.vendor] || threat.vendor,
            timestamp: threat.timestamp * 1000,
            type: threat.type || 'THREAT_TYPE_UNSPECIFIED',
            link: vendorToLinkMapping[threat.vendor],
        }));
    }
}
