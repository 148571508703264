import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalEntry } from 'app/utils/modals/entry';
import { TranslateService } from '@ngx-translate/core';

export type ConfirmOptions = {
    action: string,
    descriptionArg?: {
        [k: string]: string,
    },
    description?: string,
    items: string[],
};

@Component({
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmComponent extends ModalEntry<ConfirmOptions, true> {
    items: string[];
    action: string;
    description?: string;
    descriptionArg?: {
        [k: string]: string
    };
    constructor(
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) {
            action,
            items,
            description,
            descriptionArg,
        }: ConfirmOptions,
    ) {
        super();
        this.items = [...items];
        this.action = action;
        this.description = description;
        this.descriptionArg = descriptionArg;
    }
}
