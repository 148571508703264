import { DEFAULT_DATA } from './misc';

export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            items: [
                {
                    port: 5431,
                    proto: 'tcp',
                    comment: 'Some description',
                    ips: [
                        {
                            ip: '2.3.2.1',
                            comment: 'asdasd',
                        },
                        {
                            ip: '1234:5678:9abc:def0::',
                            comment: 'ipv6',
                        },
                        {
                            ip: '1234:5678:9abc::',
                            comment: 'ipv6',
                        },
                    ],
                },
                {
                    port: 5432,
                    proto: 'tcp',
                    comment: 'Some description2',
                    ips: [
                        {
                            ip: '222.222.222.222',
                            comment: 'asdasd2',
                        },
                    ],
                },
                {
                    port: 5435,
                    proto: 'udp',
                    comment: 'Some other description',
                    ips: Array(...Array(101)).map((_, i) => {
                        return {
                            ip: `10.9.27.${i}`,
                            comment: 'wwwwwwww wwwwwwwwwwwwwwwwwwww wwwwwwwww '.repeat(2),
                        };
                    }),
                },
            ],
            max_count: 100,
        },
    },
};
