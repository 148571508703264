import '../styles/global/app.style.scss';

import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { LicenseState } from 'app/services/license-state';
import { RequestsState } from 'app/services/requests-state';
import { getSharedInjector } from 'app/core/shared';

import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from 'app/utils/translate/TranslateHttpLoader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { AuthService } from 'app/services/auth';
import { getWindow, WindowToken } from 'app/core/window';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RootErrorHandler } from 'app/services/sentry';
import { I360OverlayContainer } from 'app/utils/overlay-container';
import { UrlInterceptor } from 'app/interceptors/url.interceptor';
import { JsonHeaderInterceptor } from 'app/interceptors/json-header.interceptor';
import { Panel } from 'app/utils/panel';
import { I360NoContentModule } from 'app/components/no-content/no-content.module';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { applyCsrfStrategy, enableSvgIcons } from 'app/core/misc';
import { SentryInterceptor } from 'app/interceptors/sentry.interceptor';
import { ImunifyStatusInterceptor } from 'app/interceptors/imunify-status.interceptor';
import { ConfigRef } from 'app/core/config-ref';
import { NavRootComponent } from "app/components/nav-root/nav-root.component";
import { NewsModule } from 'app/components/news/news.module';
import {I360MenuProfileModule} from "../components/menu-profile/menu-profile.module";
import { I360NavigationModule } from 'app/utils/navigation/navigation.module';
import {I360NotificationsModule} from "../components/notification/notifications.module";
import { LicenseInterceptor } from 'app/interceptors/license.interceptor';
import { SettingsService } from "../services/settings";
import { LicenseService } from "../services/license";
import { FileService } from "../services/file";
import { MaliciousCountInterceptor } from "../interceptors/malicious-count.interceptor";
import { GetConfigSchema } from "../utils/config/config";
import { getSchema } from 'app/utils/config/user-config';
import { HistoryService } from "../services/history";
import { ReputationService } from '../services/reputation';
import { APP_BASE_HREF } from '@angular/common';
import { UserFeaturesStatusService } from 'app/services/user-features-status';
import { NavRootRoutingModule } from './nav-root-routing.module';
import { DirectivesModule } from 'app/directives/directives.module';
import { I360ButtonModule } from 'app/utils/button/button.module';
import { I360TemplateModule } from 'app/utils/table/template.module';
import { EulaInterceptor } from 'app/components/eula/eula.interceptor';
import { MyImunifyService } from 'app/services/my-imunify';
import { MyImunifyState } from 'app/services/my-imunify-state';
import { FirewallState } from 'app/components/lists/firewall-state.service';
import { DatepickerModule } from '../utils/datepicker/datepicker.module';
import { AuthCloudService } from 'app/services/auth-cloud';


@NgModule({
    bootstrap: [ NavRootComponent ],
    declarations: [
        NavRootComponent,
    ],
    exports: [  // for uniq/app tests
        NavRootComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateHttpLoader,
            },
        }),
        NavRootRoutingModule,
        DirectivesModule,
        I360TranslateModule.forRoot(),
        HttpClientModule,
        I360NoContentModule,
        MatIconModule,
        NewsModule,
        I360MenuProfileModule,
        I360NavigationModule,
        I360ButtonModule,
        I360NotificationsModule,
        I360TemplateModule,
        DatepickerModule, // todo temp
    ],
    providers: [
        { provide: AppState, useFactory: () => getSharedInjector().get(AppState) },
        { provide: AuthState, useFactory: () => getSharedInjector().get(AuthState) },
        { provide: LicenseState, useFactory: () => getSharedInjector().get(LicenseState) },
        { provide: RequestsState, useFactory: () => getSharedInjector().get(RequestsState) },
        { provide: MyImunifyState, useFactory: () => getSharedInjector().get(MyImunifyState) },
        { provide: FirewallState, useFactory: () => getSharedInjector().get(FirewallState) },

        AuthService,
        UserFeaturesStatusService,
        ConfigRef,
        // fixme: both nav-root and content SPAs need to request settings (and other data too, in general),
        //  but we don't want to duplicate requests.
        //  Make config cache shared?
        SettingsService,
        LicenseService,
        MaliciousCountInterceptor,
        FileService,
        Panel,
        HistoryService,
        ReputationService,
        MyImunifyService,
        AuthCloudService,
        // fixme: admin/user/av have different config schemas
        //  instead of resolving it via DI, resolve it in runtime?
        { provide: GetConfigSchema, useValue: getSchema},
        { provide: WindowToken, useFactory: getWindow },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never' } },
        { provide: OverlayContainer, useClass: I360OverlayContainer},
        { provide: HTTP_INTERCEPTORS, useExisting: AuthService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ImunifyStatusInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LicenseInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: EulaInterceptor, multi: true},
        RootErrorHandler,
        { provide: ErrorHandler, useExisting: RootErrorHandler},
        { provide: APP_BASE_HREF, useValue: '/' },
    ],
})
export class NavRootModule {
    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        requestsState: RequestsState,
        injector: Injector,
        panel: Panel
    ) {
        if (panel.isPlesk) {
            applyCsrfStrategy();
        }
        enableSvgIcons(iconRegistry, sanitizer);
        requestsState.navRootInjector.next(injector);
    }
}
