import {
    Directive, EventEmitter, HostBinding, Input, NgZone, OnInit, Optional, Output,
} from '@angular/core';
import { DefaultValueAccessor, FormGroupDirective } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatSlider } from '@angular/material/slider';

import { CheckboxGroupDirective } from 'app/utils/checkbox-group.directive';
import { DatepickerComponent } from 'app/utils/datepicker/datepicker.component';
import { ModalContentComponent } from 'app/utils/modals/content/content.component';
import {
    I360NotificationEmailsComponent,
} from 'app/components/settings/notifications/notification-emails.component';
import {
    CommaSeparatedInputDirective,
} from 'app/utils/comma-separated-input/comma-separated-input.directive';
import { I360FormControl } from './form-control';

/**
 * disable form submitting on Enter by default
 * focus on next input element on enter key pressed, like tab
 * submit if this is last input
 * focus on invalid input on submit
 */
@Directive({
    selector: 'form[formGroup]',
})
export class ExtendFormGroupDirective implements OnInit {
    @HostBinding('style.display') styleDisplay = '';
    @Output() i360Submit = new EventEmitter<ExtendFormGroupDirective>();
    @Input() allowSubmitWhenSubmitting = false;
    constructor(public formGroup: FormGroupDirective,
                @Optional() private modalContent: ModalContentComponent,
                private ngZone: NgZone) {}

    ngOnInit(): void {
        if (this.modalContent) {
            this.formGroup.statusChanges!.subscribe(() => {
                this.modalContent.confirmDisabled = !!this.formGroup.invalid;
            });
            this.formGroup.valueChanges!.subscribe(value => {
                this.modalContent.dialogResult = value;
            });
        }
        this.formGroup.ngSubmit.subscribe(() => {
            this.formGroup.form.updateValueAndValidity();
            if (this.formGroup.invalid) {
                this.focusOnInvalidDirective(this.formGroup);
            } else {
                this.i360Submit.emit(this);
                if (this.modalContent) {
                    this.modalContent.close();
                }
            }
        });
        // allow use ngIf with formControlName and do not loose order of focusing on keypress.enter
        const oldPush = this.formGroup.directives.push;
        const ngZone = this.ngZone;
        this.formGroup.directives.push = function (...args) {
            const retVal = oldPush.apply(this, args);
            ngZone.runOutsideAngular(() => {
                setTimeout(() => this.sort((a, b) => (a.control as I360FormControl<any>).i360Id
                    - (b.control as I360FormControl<any>).i360Id));
            });
            return retVal;
        };
    }

    focusOnInvalidDirective(formGroup: FormGroupDirective) {
        formGroup.form.markAsDirty({children: true} as any);
        // focus on first invalid directive
        return formGroup.directives.some((directive): boolean => {
            const found = directive.invalid && this.focusOnDirective(directive);
            if (found) {
                directive.control.markAsDirty();
                directive.control.statusChanges['emit']();
            } else {
                return false;
            }
            return found;
        });
    }

    focusOnDirective(directive = this.formGroup.directives[0]): boolean|void {
        const valueAccessor = directive.valueAccessor;
        if (valueAccessor instanceof DefaultValueAccessor) {
            const nativeElement = valueAccessor['_elementRef'].nativeElement;
            nativeElement.focus();
            nativeElement.select();
            return true;
        }
        if (valueAccessor instanceof MatCheckbox) {
            if (!valueAccessor.disabled) { // skip disabled checkboxes
                valueAccessor.focus(); // will be good to have focus() on each valueAccessor
                return true;
            }
        }
        if (valueAccessor instanceof MatSlider) {
            valueAccessor['_elementRef'].nativeElement.focus();
            return true;
        }
        if (valueAccessor instanceof CheckboxGroupDirective) {
            valueAccessor.checkboxes.first.focus();
            return true;
        }
        if (valueAccessor instanceof MatSelect) {
            valueAccessor.focus();
            return true;
        }

        if (valueAccessor instanceof DatepickerComponent) {
            valueAccessor.input.nativeElement.focus();
            return true;
        }

        if (valueAccessor instanceof CommaSeparatedInputDirective) {
            valueAccessor.element.nativeElement.focus();
        }

        if (valueAccessor instanceof I360NotificationEmailsComponent) {
            return this.focusOnInvalidDirective(valueAccessor.formGroup);
        }
    }
    saveLastInputValuesToBrowser() {
        this.styleDisplay = 'none';
        setTimeout(() => {
            history.replaceState(null, '', location.href);
            this.styleDisplay = '';
        });
    }
}
