import { DEFAULT_DATA } from './misc';
import { RulePlugin } from '../blocked-rules';
import { IPList } from '../lists';
import {
    AdvicesListsResponseInServer,
    ChartName,
    DashboardAddServerResponseInServer,
    DashboardServerIdsResponseInServer,
} from '../dashboard';
import { CountryService } from '../../src/app/services/country';

const rand = (max: number) => Math.floor(Math.random() * max + 1);

const getHistogramForMonth = (max: number, groupBy: string = 'day') => {
    const periods = {hour: 3_600, '6h': 21_600, day: 86_400}[groupBy] || 86_400;
    const start = Math.round(Date.now() / 1000);
    return [
        ...Array(...Array(groupBy === 'hour' ? 25 : 31))
            .map((_, i) => ([start - i * periods, rand(max)]))
    ].reverse();
};

const getHeatmapForMonth = (max: number) => {
    const quantity = rand(30);
    let codes: [string, number][] = [];
    for(let i = 0; i < quantity; i++) {
        codes.push([CountryService.codes[rand(246)], rand(max)]);
    }
    return codes;
};

export const responses = {
    // FIXME: implement stubs validation
    get errorAddServerId(): DashboardAddServerResponseInServer {
        return {
            ...this.serverIds,
            errors: [{
                server_id: 'SSqqqqqqqqqqqqqq',
                msg: 'qwerty',
            }],
        };
    },
    settingsAdvices: <AdvicesListsResponseInServer>{
        result: [{
            type: 'the_type',
            snoozed_until: 0,
            popup: true,
            dashboard: false,
            description: '',
            severity: 0,
            link_text: '',
            link: '',
            popup_title: 'Turn on "Rapid Scan"',
            popup_description: 'Improves the speed of repeated scans by up to 20x times',
            config_action: {
                MALWARE_SCANNING: {
                    rapid_scan: true,
                },
            },
            ignore: {
                MALWARE_SCANNING: {
                    rapid_scan: [true],
                },
            },
        }],
    },
    noAdvices: <AdvicesListsResponseInServer>{
        result: [],
    },
    advices: <AdvicesListsResponseInServer>{
        result: [
            {
                type: 'type1',
                snoozed_until: 1594326014,
                dashboard: true,
                popup: true,
                popup_title: '"Background Malware Scan" to "Once a week"',
                popup_description: 'Minimize the time of new malicious...',
                config_action: {
                    MALWARE_SCAN_SCHEDULE: {
                        interval: 'week',
                        day_of_month: 0,
                    },
                },
                ignore: {
                    WEBSHIELD: {
                        enable: [false],
                    },
                },
            },
            {
                type: 'type2',
                snoozed_until: 1594326014,
                dashboard: true,
                popup: true,
                popup_title: 'Malware Scan 2',
                popup_description: 'description 2',
                config_action: {
                    MALWARE_SCAN_SCHEDULE: {
                        hour: 3,
                    },
                },
                ignore: {
                    MALWARE_SCAN_SCHEDULE: {
                        hour: [2],
                    },
                },
            },
            {
                type: 'type3',
                snoozed_until: 1594326014,
                dashboard: true,
                popup: true,
                popup_title: 'Malware Scan 3',
                popup_description: 'description 3',
                config_action: {
                    MALWARE_SCAN_SCHEDULE: {
                        hour: 3,
                    },
                },
                ignore: {
                    MALWARE_SCAN_SCHEDULE: {
                        hour: [2],
                    },
                },
                description: 'It is recommended to set a background scan to weekly or daily.',
                severity: 2,
                link_text: 'Check Imunify360 settings.',
                link: 'https://docs.imunify360.com/dashboard/#background-scanning',
            },
        ],
    },
    serverIds: {
        result: [{
            server_id: '123',
            ip: 'ip',
            nodename: 'domain1',
        }, {
            server_id: 'server_id2',
            ip: 'ip',
            nodename: 'domain2',
        }, {
            server_id: 'server_id3',
            ip: 'ip',
            nodename: 'domain3',
        }, {
            server_id: 'SSqqqqqqqqqqqqq2',
            ip: 'ip',
            nodename: null,
        }, {
            server_id: 'server_id5',
            ip: null,
            nodename: null,
        }],
        error: [],
    } as DashboardServerIdsResponseInServer,
    serverIdsWithIdenticalDomains: {
        result: [{
            server_id: 'id1',
            ip: 'ip',
            nodename: 'domain1',
        }, {
            server_id: 'id2',
            ip: 'ip',
            nodename: 'domain1',
        }],
    } as DashboardServerIdsResponseInServer,
    get histogram() {
        return this.histogramGrouped('day');
    },
    histogramGrouped (groupBy: string) {
        return {
            ossec_alerts: getHistogramForMonth(100, groupBy),
            waf_alerts: getHistogramForMonth(300, groupBy),
            bruteforce_attacks: getHistogramForMonth(600, groupBy),
            captcha_blocked: getHistogramForMonth(1000, groupBy),
            num_of_alerts: getHistogramForMonth(800, groupBy),
            denied_bot_attacks: getHistogramForMonth(300, groupBy),
            num_of_mrs: getHistogramForMonth(50, groupBy),
            num_of_cleanups: getHistogramForMonth(50, groupBy),
            ham: getHistogramForMonth(50, groupBy),
            spam: getHistogramForMonth(50, groupBy),
        };
    },
    get noDataFound() {
        // trapped on circular dependency
        return Object.keys(ChartName).reduce((res, key) => {
            res[key] = <string[]>[];
            return res;
        }, {});
    },
    heatmap: {
        ossec_alerts: getHeatmapForMonth(100),
        waf_alerts: getHeatmapForMonth(300),
        bruteforce_attacks: getHeatmapForMonth(600),
        captcha_blocked: getHeatmapForMonth(1000),
        num_of_alerts: getHeatmapForMonth(800),
        denied_bot_attacks: getHeatmapForMonth(300),
        num_of_mrs: getHeatmapForMonth(50),
        num_of_cleanups: getHeatmapForMonth(50),
    },
    incidents: {
        data: {
            ...DEFAULT_DATA,
            items: [
                {
                    severity: 5,
                    id: 68,
                    name: 'Possible attack on the cpanel services',
                    rule: '11001',
                    description: '[2016-12-05 08:21:10 -0500] info [cpsrvd] 192.168.123.123 - root "POST /cgi/imunify360/handlers/sendRequest.cgi HTTP/1.1" FAILED LOGIN whostmgrd: cookie ip check: IP address has changed', // tslint:disable-line:max-line-length
                    times: 2,
                    abuser: null,
                    timestamp: 1480944074.4472556,
                    listname: null,
                    purpose: null,
                    plugin: RulePlugin.ossec,
                    country: null,
                    domain: null,
                },
                {
                    severity: 5,
                    id: 68,
                    name: 'Possible attack on the cpanel services',
                    rule: '11001',
                    description: '[2016-12-05 08:21:10 -0500] info [cpsrvd] 192.168.123.123 - root "POST /cgi/imunify360/handlers/sendRequest.cgi HTTP/1.1" FAILED LOGIN whostmgrd: cookie ip check: IP address has changed', // tslint:disable-line:max-line-length
                    times: 2,
                    abuser: '1234:5678:9abc:def0::',
                    timestamp: 1480944074.4472556,
                    purpose: null,
                    listname: null,
                    domain: 'test2.com',
                    plugin: RulePlugin.modsec,
                    country: {
                        name: 'United States of America',
                        code: 'US',
                    },
                },
                {
                    severity: 4,
                    id: 14,
                    name: 'First time user executed sudo.',
                    rule: '5403',
                    description: 'Dec  5 07:46:40 centos7-x64 sudo:    root : TTY=pts/1 ; PWD=/root ; USER=root ; COMMAND=/bin/bash -l', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '1234:5678:9abc::',
                    timestamp: 1480942002.3256857,
                    purpose: IPList.captcha,
                    listname: IPList.captcha,
                    plugin: RulePlugin.ossec,
                    country: {
                        name: 'Albania',
                        code: 'AL',
                    },
                    domain: null,
                },
                {
                    severity: 4,
                    id: 14,
                    name: 'First time user executed sudo.',
                    rule: '5403',
                    description: 'Dec  5 07:46:40 centos7-x64 sudo:    root : TTY=pts/1 ; PWD=/root ; USER=root ; COMMAND=/bin/bash -l', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '1::',
                    timestamp: 1480942002.3256857,
                    purpose: IPList.captcha,
                    listname: IPList.captcha,
                    plugin: RulePlugin.ossec,
                    country: {
                        name: 'United States',
                        code: 'US',
                    },
                    domain: null,
                },
                {
                    severity: 7,
                    id: 11,
                    name: 'New Yum package installed.',
                    rule: '2932',
                    description: 'Dec  5 07:33:16 centos7-x64 yum[6291]: Installed: 2:nodejs-6.9.1-1nodesource.el7.centos.x86_64', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '101.1.1.3',
                    timestamp: 1480941200.7609231,
                    purpose: IPList.drop,
                    listname: IPList.drop,
                    plugin: RulePlugin.ossec,
                    country: {
                        name: 'Brazil',
                        code: 'BR',
                    },
                    domain: null,
                },
                {
                    severity: 4,
                    id: 1,
                    name: 'First time user logged in.',
                    rule: '10100',
                    description: 'Dec  5 07:20:47 centos7-x64 sshd[4834]: Accepted publickey for root from', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '10.101.1.5',
                    timestamp: 1480940450.2248483,
                    purpose: IPList.white,
                    listname: IPList.white,
                    plugin: RulePlugin.ossec,
                    country: {
                        name: 'Ukraine',
                        code: 'UA',
                    },
                    domain: null,
                },
                {
                    severity: 4,
                    id: 1,
                    name: 'First time user logged in.',
                    rule: '10100',
                    description: 'Dec  5 07:20:47 centos7-x64 sshd[4834]: Accepted publickey for root from', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '10.101.1.6',
                    timestamp: 1480940450.2248483,
                    purpose: null,
                    listname: null,
                    plugin: RulePlugin.ossec,
                    country: {
                        name: 'Ukraine',
                        code: 'UA',
                    },
                    domain: null,
                },
                {
                    severity: 4,
                    id: 1,
                    name: 'First time user logged in.',
                    rule: '10100',
                    description: 'Dec  5 07:20:47 centos7-x64 sshd[4834]: Accepted publickey for root from', // tslint:disable-line:max-line-length
                    times: 1,
                    abuser: '10.101.1.6',
                    timestamp: 1480940450.2248483,
                    purpose: null,
                    listname: null,
                    plugin: RulePlugin.ossec,
                    country: {},
                    domain: null,
                },
                {
                    severity: null,
                    id: 1,
                    name: 'name',
                    rule: null,
                    description: 'desc',
                    times: 1,
                    abuser: null,
                    timestamp: 123,
                    purpose: null,
                    listname: null,
                    plugin: RulePlugin.cl_dos,
                    domain: null,
                    country: null,
                },
                {
                    severity: 3,
                    id: 1,
                    name: 'enhanced_dos incident',
                    rule: '10100',
                    description: 'description for enhanced_dos',
                    times: 1,
                    abuser: '10.111.21.6',
                    timestamp: 123,
                    purpose: null,
                    listname: null,
                    plugin: RulePlugin.enhanced_dos,
                    country: {},
                    domain: null,
                },
            ],
            max_count: 26,
        },
    },
    noIncidents: {
        data: {
            ...DEFAULT_DATA,
            items: [],
            max_count: 26,
        }
    },
    get recommendations() {
        // tslint:disable:max-line-length
        return {
            result: [
                {link: 'link', link_text: 'str2', description: 'description', parameters: [{1: 20}, {2: 30}], severity: 3, date: 1556280915, type: 20, id: 10, server_id: 'server_id5', ts: 1556280915.8048308},
                {link: 'link', link_text: 'domain2link1', description: 'domain2descr1', parameters: [{1: 20}, {2: 30}], severity: 2, date: 1556280915, type: 20, id: 10, server_id: 'server_id2', ts: 1556280915.3432958},
                {link: 'link', link_text: 'str2', description: 'description', parameters: [{1: 20}, {2: 30}], severity: 2, date: 1556280914, type: 20, id: 10, server_id: 'SSqqqqqqqqqqqqq2', ts: 1556280914.9061892},
                {link: 'link', link_text: 'str2', description: 'description', parameters: [{1: 20}, {2: 30}], severity: 1, date: 1556280914, type: 20, id: 10, server_id: 'server_id3', ts: 1556280914.4573584},
                {link: 'link', link_text: 'str2', description: 'description', parameters: [{1: 20}, {2: 30}], severity: 1, date: 1556280708, type: 20, id: 10, server_id: 'server_id3', ts: 1556280708.8022974},
                {link: 'link', link_text: 'domain2link2', description: 'domain2descr2', parameters: [{1: 20}, {2: 30}], severity: 2, date: 1556280708, type: 20, id: 10, server_id: 'server_id2', ts: 1556280708.3060849},
                {link: 'link', link_text: 'str3', description: 'description3', parameters: [{1: 20}, {2: 30}], severity: 1, date: 1556280707, type: 20, id: 10, server_id: '123', ts: 1556280707.727882},
                {link: null, link_text: null, description: 'description2', parameters: [{1: 20}, {2: 30}], severity: 2, date: 1556380706, type: 20, id: 10, server_id: '123', ts: 1556280707.1226282},
                {link: 'link', link_text: 'str1', description: 'description1', parameters: [{1: 20}, {2: 30}], severity: 3, date: 1556180706, type: 20, id: 10, server_id: '123', ts: 1556280707.1226282},
            ],
        };
        // tslint:enable:max-line-length
    },
};
