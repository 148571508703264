import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsItem } from '@imunify360-api/news';
import { of, BehaviorSubject } from 'rxjs';
import { NewsService } from '../../news.service';
import { NewsItemDirective } from '../news-item.directive';


@Component({
    selector: 'i360-news-message',
    template: `
        <ng-template #tpl>
            <div class="event-item i360-message">
                <i class="material-icons feed">rss_feed</i>
                <div class="news-message_content">
                    <div class="news-message_content_time">{{ message.pubDate | date }}</div>
                    <div class="attention news-message_content_title">{{ message.title }}</div>
                    <a [href]="message.link" target="_blank" translate>readMore.readMore</a>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./news-message.component.scss'],
    encapsulation: ViewEncapsulation.None,  // because we use ngTemplateOutlet in news-notifications
    providers: [
        { provide: NewsItemDirective, useExisting: NewsMessageComponent },
    ],
})
export class NewsMessageComponent extends NewsItemDirective implements OnInit {
    @Input() message: NewsItem;
    isCountable = of(true);
    isUnread: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    openOverlay = this.isUnread;

    constructor(private newsService: NewsService) {
        super();
    }

    ngOnInit(): void {
        this.isUnread.next(this._isUnread());
    }

    markAsRead(): void {
        if (this._isUnread()) {
            this.newsService.saveAsRead(this.message.guid);
        }

        this.isUnread.next(this._isUnread());
    }

    _isUnread() {
        return !this.newsService.isRead(this.message.guid);
    }
}
