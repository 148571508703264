import { DataFrom, NullAgentResponse, RequestParams } from './misc';


export class EulaAPI {
    static accept = () => new RequestParams<DataFrom<NullAgentResponse>>(['eula', 'accept']);
}

export type EulaInBackend = null | {
    message: string;
    text: string;
    updated: string;
};

export class Eula {
    message: string;
    text: string;
    public constructor(data: EulaInBackend) {
        if (!data) {
            this.message = '';
        } else {
            this.message = data.message;
            this.text = data.text;
        }
    }

    public isFailed(): boolean {
        return !!this.message;
    }
}
