import { DEFAULT_DATA } from './misc';
import { MalwareScanStatus } from '../user-list';
import { ResourceType, MalwareScanType, ScanState } from '../malware';
import { Result } from '../misc';


const list = [
    {
        error: null,
        quarantined: 0,
        resource_type: ResourceType.file,
        scan_type: MalwareScanType.ondemand,
        scan_status: MalwareScanStatus.running,
        created: 1553111711,
        started: 1553111711,
        path: '/home/user1',
        scanid: '08796906dace42de8d251d167ab4eefc',
        malicious: 0,
        total: 6,
        total_resources: 6,
        total_malicious: 0,
    },
    {
        error: null,
        quarantined: 0,
        resource_type: ResourceType.file,
        scan_type: MalwareScanType.background,
        scan_status: MalwareScanStatus.running,
        created: 1553111711,
        started: 1553111711,
        path: '/home/user6',
        scanid: '688b88d41cb144f3b977595e848ed440',
        malicious: 0,
        total: 4,
        total_resources: 4,
        total_malicious: 0,
    },
    {
        error: null,
        quarantined: 0,
        resource_type: ResourceType.db,
        scan_type: MalwareScanType.ondemand,
        scan_status: MalwareScanStatus.queued,
        created: 1553111711,
        started: 1553111711,
        path: '/home/user2',
        scanid: '08796906dace42de8d251d167ab4eefc',
        malicious: 0,
        total: 6,
        total_resources: 6,
        total_malicious: 0,
    },
    {
        error: null,
        quarantined: 0,
        resource_type: ResourceType.file,
        scan_type: MalwareScanType.background,
        scan_status: MalwareScanStatus.queued,
        created: 1553111711,
        started: 1553111711,
        path: '/home/user5',
        scanid: '688b88d41cb144f3b977595e848ed440',
        malicious: 0,
        total: 0,
        total_resources: 0,
        total_malicious: 0,
    },
    {
        error: null,
        scan_status: MalwareScanStatus.stopped,
        resource_type: ResourceType.file,
        created: 1553112483,
        started: 1553112483,
        path: '/home/user4',
        scanid: '688b88d41cb144f3b977595e848ed440',
        malicious: 0,
        total: 12,
        total_resources: 12,
        total_malicious: 8,
    },
    {
        error: null,
        scan_status: MalwareScanStatus.stopped,
        resource_type: ResourceType.file,
        created: 1553112483,
        started: 1553112483,
        path: '/home/user7',
        scanid: 'e957a5358051497da0dbf3603977bd25',
        total: 0,
        total_resources: 0,
        total_malicious: 0,
    },
    {
        error: null,
        scan_status: MalwareScanStatus.stopped,
        resource_type: ResourceType.db,
        created: 1553112483,
        started: 1553112483,
        path: '/home/user3',
        scanid: 'e957a5358051497da0dbf3603977bd25',
        total: 5,
        total_resources: 5,
        total_malicious: 9,
    },
];

const status = {
    created: 1526376334.3316534,
    eula: null,
    path: '/var/www/vhosts/*',
    status: ScanState.Running as ScanState.Running,
    scan_type: MalwareScanType.ondemand,
    progress: 50,
    phase: 'aibolit scanning',
    file_patterns: null,
    exclude_patterns: null,
    intensity_cpu: 2,
    intensity_io: 2,
    intensity_ram: 1024,
    follow_symlinks: false,
    queued: 0,
    scanid: '123',
    started: 0,
};

export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            max_count: 30,
            items: list,
        },
    },

    running: {
        data: {
            ...DEFAULT_DATA,
            items: status,
        },
    },

    runningScheduled: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...status,
                scan_type: MalwareScanType.background,
            },
        },
    },

    runningModerate: {
        data: {
            ...DEFAULT_DATA,
            items: {
                ...status,
                exclude_patterns: ['ex'],
                file_patterns: ['fi'],
                intensity_cpu: 7,
                intensity_io: 7,
                follow_symlinks: true,
                scanid: 'e83c183a743741698ce470c9c53d5817',
            },
        },
    },

    stopped: {
        data: {
            ...DEFAULT_DATA,
            items: {
                status: ScanState.Stopped as ScanState.Stopped,
                queued: 0,
            },
        },
    },
    statusUserStartRateLimit: {
        result: Result.ERROR,
        messages: ['malware_scanner.on_demand.user_scan.rate_limit_reached'],
        data: null as any,
    },
    statusUserStopped: {
        data: {
            ...DEFAULT_DATA,
            items: {
                status: MalwareScanStatus.stopped,
            },
        },
    },
    statusUserRunning: {
        data: {
            ...DEFAULT_DATA,
            items: {
                status: MalwareScanStatus.running,
            },
        },
    },
    statusUserQueued: {
        data: {
            ...DEFAULT_DATA,
            items: {
                status: MalwareScanStatus.queued,
            },
        },
    },
    start: {
        data: {
            ...DEFAULT_DATA,
            items: null,
        }
    }
};
