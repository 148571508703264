import { ResponseDataExceptItems } from '../misc';
import { LicenseType, LicenseInBackend } from '../license';

export const license: LicenseInBackend = {
    id: '123',
    license_type: LicenseType.imunify360,
    user_count: 5,
    message: '',
    expiration: null,
    pricing: {
        im_av: {standard: '$6'},
        im_360_1: {standard: '$1'},
        im_360_30: {standard: '$30'},
        im_360_250: {standard: '$250'},
        im_360_unlimited: {standard: '$1000'}
    },
    user_limit: 2147483647,
    status: true,
    redirect_url: 'https://cln.cloudlinux.com/clweb/public/imconv.xhtml?id=qwertyuiop',
    upgrade_url_360: 'upgrade_url_360_url',
};

export const DEFAULT_DATA: ResponseDataExceptItems = {
    strategy: 'PRIMARY_IDS',
    version: 'testing_ver',
    warnings: [],
    eula: null,
    license,
};

export const DEFAULT_DATA_FREE_LICENSE: ResponseDataExceptItems = {
    ...DEFAULT_DATA,
    license: {
        ...license,
        license_type: LicenseType.imunifyAV,
    },
};

export const noItemsResponse = {data: DEFAULT_DATA};
export const emptyListResponse = {data: {...DEFAULT_DATA, items: []}};
export const emptyResponse = {data: {...DEFAULT_DATA, items: null}};
