import { Injectable } from '@angular/core';
import { License, Product } from '@imunify360-api/license';
import { AuthState } from 'app/services/auth-state';
import { testMode } from 'app/services/misc';
import { ZonedBehaviorSubject } from 'app/utils/rxjs-zoned';
import { Eula } from '@imunify360-api/eula';
import { map } from 'rxjs';


const SERVER_ID = "I360_SERVER_ID";

const day = 24 * 60 * 60 * 1000;
const getTestData = () => ({
    status: !window['i360LicenseFail'],
    ...(window['i360LicenseFail'] ? {} : {id: "some_valid_id"}),
    license_type: 'imunify360',
    expiration: (Date.now() + 2 * day) / 1000,
    user_limit: 10,
    user_count: 11,
    redirect_url: 'https://cln.cloudlinux.com',

    // message: 'test message from CLN',
    upgrade_url: 'https://ui-test-server:55555/test/test.html',
    upgrade_url_360: 'https://ui-test-server:55555/test/test.html',
    ip_license: false,
});

@Injectable()
export class LicenseState {
    license: ZonedBehaviorSubject<License> = new ZonedBehaviorSubject(new License({status: true}));
    Product = Product;

    eula: ZonedBehaviorSubject<Eula> = new ZonedBehaviorSubject(new Eula(null));
    eulaFailed = this.eula.pipe(map((eula: Eula) =>  eula.isFailed()));

    constructor(
        private authState: AuthState,
    ) {}

    getLicense(data: {}): License {
        return new License(testMode(this.constructor.name) ? getTestData() : data);
    }

    getServerIdForTestMode() {
        return localStorage.getItem(SERVER_ID)
    }
}
