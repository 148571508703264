<i360-modal-content [confirmText]="'utils.modals.confirm.confirms.save'"
                    [cancelText]="'utils.modals.content.skip'">
    <div class="contacts-request-modal_container" modal-body>
        <div>
            <mat-icon class="events-notifications-icon" svgIcon="events-notifications"></mat-icon>
        </div>

        <div class="contacts-request-modal_content">
            <h2 translate>news.adminContacts.request.title</h2>
            <p class="contacts-request-modal_content_description"
               translate>news.adminContacts.request.line1</p>
            <h3 class="contacts-request-modal_content_emails-heading"
                translate>news.adminContacts.request.specifyEmail</h3>
            <form [formGroup]="contactsForm">
                <input type="text"
                       autocomplete="email"
                       commaSeparatedInput
                       class="underline-input"
                       cdkFocusInitial
                       placeholder="{{ 'news.adminContacts.request.emailPlaceholder' | translate }}"
                       formControlName="emails">
            </form>
            <p class="contacts-request-modal_content_hint"
               *ngIf="contactsForm.pristine || contactsForm.valid"
               translate>news.adminContacts.request.specifyEmailHint</p>
            <p class="contacts-request-modal_content_note"
               translate>news.adminContacts.request.note</p>
        </div>
    </div>
</i360-modal-content>
