import * as deepEqual from 'deep-equal';

export function configsEqual(original = {}, currentConf): boolean {
    const originalConf = JSON.parse(JSON.stringify(original));
    for (const section of Object.keys(originalConf)) {
        if (!(section in currentConf)) {
            delete originalConf[section];
        } else {
            for (const field of Object.keys(originalConf[section])) {
                if (!(field in currentConf[section])) {
                    delete originalConf[section][field];
                }
            }
        }

    }
    return deepEqual(originalConf, currentConf);
}
