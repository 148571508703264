<i360-modal-content
    confirmText="utils.modals.confirm.confirms.{{action}}">
    <ng-container modal-title>{{ 'utils.modals.confirm.titles.' + action | translate }}</ng-container>
    <ng-container modal-body>
        <div *ngIf="description">
            {{ 'utils.modals.confirm.descriptions.' + action | translate : descriptionArg}}
        </div>
        <i360-modal-items [items]="items"></i360-modal-items>
    </ng-container>
</i360-modal-content>
