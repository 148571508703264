import { Component, OnInit } from '@angular/core';
import { AdminContactsService } from 'app/services/admin-contacts';
import { ModalEntry } from 'app/utils/modals/entry';
import { I360FormBuilder } from 'app/utils/validator/form-builder';
import { I360Validators } from 'app/utils/validator/validators';


@Component({
    selector: 'admin-contacts-request-modal',
    templateUrl: './admin-contacts-request-modal.component.html',
    styleUrls: ['./admin-contacts-request-modal.component.scss'],
})
export class AdminContactsRequestModalComponent
    extends ModalEntry<any, { emails: string[] }>
    implements OnInit {
    contactsForm = this.formBuilder.group({
        emails: this.formBuilder.control<string[]>([], [
            I360Validators.required('emails'),
            I360Validators.emails,
        ]),
    });

    constructor(
        private formBuilder: I360FormBuilder,
        private adminContactsService: AdminContactsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.adminContactsService.getEmails().subscribe((value) => {
            this.contactsForm.controls.emails.reset(value.data.items);
        });
    }
}
