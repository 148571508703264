import { RequestParams } from './misc';


export interface AdminEmailResponse {
    items: string[];
}

export class AdminContactsAPI {
    static getEmails = () => new RequestParams<AdminEmailResponse>(['admin-emails']);
}
