import { Component, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';


@Component({
    selector: 'i360-error',
    template: `
        {{control.errors|i360Error|async}}
    `,
    styleUrls: ['./error.component.scss'],
    inputs: ['control'],  // eslint-disable-line
})
export class ErrorComponent {
    @HostBinding('class') hostClass = 'error-message'; // for tests

    control: NgControl
}
