import { Component, ViewEncapsulation } from '@angular/core';
import { License } from '@imunify360-api/license';
import { LicenseState } from 'app/services/license-state';
import { identity, first, map } from 'rxjs';
import { NewsItemDirective } from '../news-item.directive';

@Component({
    selector: 'i360-news-license',
    template: `
        <ng-template #tpl>
            <div *ngIf="isCountable | async" class="event-item i360-license">
                <i class="material-icons feed">rss_feed</i>
                <div>
                    <p class="attention" translate>news.attention</p>
                    <p class="i360-license_message">{{ (licenseState.license|async).message }}</p>
                    <p class="i360-license_link" *ngIf="!!(licenseState.license|async).redirect_url">
                        <a class="i360-license_redirect-link"
                           [href]="(licenseState.license|async).redirect_url"
                           target="_blank" translate>news.fixLicense</a>
                    </p>
                    <p>
                        {{ 'news.supportEmail' | translate }}
                        <a href="mailto:helpdesk@imunify360.com">helpdesk@imunify360.com</a>
                    </p>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./news-license.component.scss'],
    encapsulation: ViewEncapsulation.None,  // because we use ngTemplateOutlet in news-notifications
    providers: [
        { provide: NewsItemDirective, useExisting: NewsLicenseComponent },
    ],
})
export class NewsLicenseComponent extends NewsItemDirective {
    isCountable = this.licenseState.license.pipe(
        map((license: License) => license.hasMessage()),
    );
    isUnread = this.isCountable;
    openOverlay = this.isCountable.pipe(first(identity));

    constructor(public licenseState: LicenseState) {
        super();
    }

    markAsRead(): void {}
}
