import { NgModule } from '@angular/core';
import {
    NavigationComponent,
} from 'app/utils/navigation/navigation.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { EulaService } from 'app/components/eula/eula.service';
import { PackageVersionsComponent } from 'app/utils/navigation/package-versions/package-versions.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        I360TranslateModule,
    ],
    providers: [
        EulaService,
    ],
    declarations: [
        NavigationComponent,
        PackageVersionsComponent
    ],
    exports: [
        NavigationComponent,
        PackageVersionsComponent
    ],
})
export class I360NavigationModule {}
