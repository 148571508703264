import { Directive, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Directive({})
export abstract class NewsItemDirective {
    isUnread: Observable<boolean>;
    isCountable: Observable<boolean>;
    openOverlay: Observable<boolean>;
    blockOverlayAutoOpen = new BehaviorSubject(false);
    @ViewChild('tpl', {static: true}) template: TemplateRef<any>;

    abstract markAsRead(): void;
}
