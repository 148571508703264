import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Package } from '@imunify360-api/license';
import { ModalEntry } from 'app/utils/modals/entry';


@Component({
    selector: 'admin-contacts-confirm-modal',
    template: `
        <i360-modal-content [confirmText]="'utils.modals.confirm.confirms.done'"
                            [showCancel]="false">
            <div class="contacts-confirm-modal_container" modal-body>
                <div>
                    <mat-icon class="check-circle-icon" svgIcon="check-circle"></mat-icon>
                </div>

                <div class="contacts-confirm-modal_content">
                    <h2 translate>news.adminContacts.confirmation.title</h2>
                    <p class="contacts-confirm-modal_content_description">
                        <span translate>news.adminContacts.confirmation.description</span>
                        <a class="i360-dashboard-link"
                           [routerLink]="['/', imunify360Package, 'admin', 'settings', 'general']"
                           (click)="handleLinkClick()"
                           translate>news.adminContacts.confirmation.descriptionLink</a>.
                    </p>
                </div>
            </div>
        </i360-modal-content>
    `,
    styleUrls: ['./admin-contacts-confirm-modal.component.scss'],
})
export class AdminContactsConfirmModalComponent extends ModalEntry<any, boolean> {
    imunify360Package = Package.imunify360;

    constructor(private dialogRef: MatDialogRef<AdminContactsConfirmModalComponent>) {
        super();
    }

    handleLinkClick() {
        this.dialogRef.close();
    }
}
