import { Injectable } from '@angular/core';
import { SettingsAPI } from '@imunify360-api/settings';
import { NotificationSettingsAPI } from '@imunify360-api/notification-settings';
import { XHR } from 'app/utils/xhr/xhr';
import { ImunifyPackageVersions } from '@imunify360-api/package-versions';
import { catchError, map, Observable, of, take } from 'rxjs';
import { cache } from 'app/utils/cache';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class SettingsService {
    constructor(public xhr: XHR) {}

    show = this.xhr.rx(SettingsAPI.show);
    saveSettings = this.xhr.rx(SettingsAPI.save);
    notificationShow = this.xhr.rx(NotificationSettingsAPI.show);
    saveNotificationSettings = this.xhr.rx(NotificationSettingsAPI.save);
    conflicts = this.xhr.rx(SettingsAPI.conflicts);
    packageVersions: Observable<{
        packages: string,
        count: number;
    } | null> = this.xhr.rx(ImunifyPackageVersions.getPackageVersion, false, false)()
        .pipe(
            map(result => {
                let components: string[] = [];
                let count = 0;
                if (result.data.items) {
                    const packages = result.data.items;
                    components = Object.keys(packages).reduce((acc, key) => {
                        if (packages[key] !== null) {
                            acc.push(key + ' - ' + packages[key]);
                        }
                        count++;
                        return acc;
                    }, [] as string[]);
                }
                return {
                    packages: components.join('\r\n'),
                    count
                };
            }),
            take(1),
            cache(300),
            catchError((e: HttpErrorResponse) => {
                console.error('Caught error:', e);
                return  of(null);
            }),
        );
}
