import { Injectable } from '@angular/core';
import { SettingsService } from 'app/services/settings';
import { I360Config } from 'app/utils/config/config';
import { ModalService } from 'app/utils/modals/modal.service';
import { filter, take, tap } from 'rxjs';
import {
    AdminContactsConfirmModalComponent,
} from '../../admin-contacts-confirm-modal/admin-contacts-confirm-modal.component';
import {
    AdminContactsRequestModalComponent,
} from '../../admin-contacts-request-modal/admin-contacts-request-modal.component';
import { AdminContactsHistoryService } from './admin-contacts-history.service';
import { AdminContactsOpenerService } from './admin-contacts-opener.service';


@Injectable()
export class AdminContactsOpenerInitialService extends AdminContactsOpenerService {
    constructor(
        private modalService: ModalService,
        private settingsService: SettingsService,
        private i360Config: I360Config,
        protected adminContactsHistoryService: AdminContactsHistoryService,
    ) {
        super(adminContactsHistoryService);
    }

    get blockOverlayAutoOpen() {
        return true;
    }

    openIfNeeded(): void {
        this.newsAdminContactsComponent.isCountable.pipe(
            take(1),
            filter(value => !!value),
        ).subscribe(() => {
            this.modalService.open(AdminContactsRequestModalComponent, {
                viewContainerRef: this.newsAdminContactsComponent.viewContainerRef,
                width: '890px',
            }).then((result) => {
                if (result) {
                    this.saveAdminContacts(result.emails);
                }
            });
            this.adminContactsHistoryService.addHistoryRecord({ timestamp: Date.now() });
        });
    }

    private saveAdminContacts(emails: string[]) {
        this.settingsService
            .saveSettings({
                ADMIN_CONTACTS: {
                    emails,
                },
            })
            .pipe(tap((result) => {
                this.i360Config.requestsState.config.push.next(result.data.items as any);
            }))
            .subscribe(() => {
                this.modalService.open(
                    AdminContactsConfirmModalComponent,
                    {
                        viewContainerRef: this.newsAdminContactsComponent.viewContainerRef,
                        width: '677px',
                    },
                ).then(() => {
                    this.newsAdminContactsComponent.blockOverlayAutoOpen.next(false);
                });
            });
    }
}
