import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { of,  EMPTY,  Observable, switchMap } from 'rxjs';
import { LicenseService } from 'app/services/license';
import { postOnly } from './utils';
import { NotificationsService } from 'app/services/notifications';
import { UntranslatableString } from 'app/utils/translate/translate.service';
import { AppState } from 'app/core/app.service';


@Injectable()
export class LicenseInterceptor implements HttpInterceptor {
    constructor(
        private notifications: NotificationsService,
        private appState: AppState,
        private licenseService: LicenseService,
    ) {}

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            switchMap(event => {
                if (event instanceof HttpResponse) {
                    const version = event?.body?.data?.version;
                    if (!!version && this.appState.version !== version) {
                        this.appState.version = version;
                    }
                    const license = event.body.data ? event.body.data.license : event.body.license;
                    if (license) {
                        if (!this.licenseService.checkLicense(license)) {
                            const messages = event.body.messages instanceof Array
                                ? event.body.messages.join()
                                : event.body.messages;
                            if (messages) {
                                this.notifications.error(UntranslatableString.for(messages));
                            }
                            return EMPTY;
                        }
                    }
                }
                return of(event);
            }),
        );
    }
}
